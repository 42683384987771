import { Dispatch } from 'redux';
import { AuthConstants } from './auth.constants';
import { notification } from "antd";

import { UserService } from '../../api/';
import { RegisterInterface, RegisterResponseInterface, LoginInterface, LoginResponseInterface } from '../../interfaces';
import { i18n, NavigationService } from '../../services';

export function registerAction(data: RegisterInterface) {
  return (dispatch: Dispatch) => {
    return UserService.register(data).subscribe(
      (response: RegisterResponseInterface) => {
        dispatch({
          type: AuthConstants.REGISTER_SUCCESS,
          payload: response
        });
        notification["success"]({ message: i18n.translate("register.success") });
        let loginData: LoginInterface = {
          emailOrNickname: data.email,
          password: data.password,
        } 
        dispatch(loginAction(loginData) as any);
      },
      (error: Error) => {
        dispatch({
          type: AuthConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function loginAction(data: LoginInterface) {
  return (dispatch: Dispatch) => {
    return UserService.login(data).subscribe(
      (response: LoginResponseInterface) => {
        dispatch({
          type: AuthConstants.LOGIN_SUCCESS,
          payload: response
        });
        notification["success"]({ message: i18n.translate("login.success") });
        NavigationService.navigate("/reservations");
      },
      (error) => {
        if(error.status === 401) {
          notification["error"]({ message: i18n.translate("errorCodes.401") });
        }
        dispatch({
          type: AuthConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function logoutAction() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AuthConstants.LOGOUT
    });
    notification["success"]({ message: i18n.translate("login.logoutMsg") });
    NavigationService.navigate("/auth/login");
  };
}

export function setLangAction(lang: 'hr' | 'en') {
  return (dispatch: Dispatch) => {
    dispatch({ 
      type: AuthConstants.SET_LANG, 
      payload: lang 
    });
  };
}