import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { UserOutlined } from '@ant-design/icons';
import LogoComponent from "../../logo/logo.component";
import HeaderMenuComponent from "../menu/headerMenu.component";
import Hamburger from "../../hamburger/hamburger.component";
import { StoreStateInterface, i18n } from "../../..";
import { ProfileRoutes } from "../../../../features/profile/_router/profile.routes";
import { AuthRoutes } from "../../../../features/auth/_router/auth.routes";

export function PrimaryHeaderComponent() {

  const isAuthenticated = useSelector((state: StoreStateInterface) => state.auth.isAuthenticated);

  const leftMenuItems = [
    {
      title: i18n.translate("header.arena"),
      href: `${process.env.REACT_APP_WP_URL}/ironbull-arena-zagreb/`
    },
    {
      title: i18n.translate("header.tanks"),
      href: `${process.env.REACT_APP_WP_URL}/tenkovi/`
    },
    {
      title: i18n.translate("header.rules"),
      href: `${process.env.REACT_APP_WP_URL}/pravila-igre/`
    },
  ];

  const rightMenuItems = [
    {
      title: i18n.translate("header.franchise"),
      href: `${process.env.REACT_APP_WP_URL}/fransiza/`
    },
    {
      title: i18n.translate("header.shop"),
      href: `${process.env.REACT_APP_WP_URL}/proizvod/`
    },
    {
      title: i18n.translate("header.blog"),
      href: `${process.env.REACT_APP_WP_URL}/blog/`
    },
    {
      title: "",
      icon: <UserOutlined />,
      href: isAuthenticated ? ProfileRoutes.STATISTICS.fullPath : AuthRoutes.LOGIN.fullPath
    }
  ]
  
  useLayoutEffect(() => {
    let headerSelector = '.header';
    let headerElement  = document.querySelector<HTMLElement>(headerSelector);
    // let headerWrapperSelector = '.header__wrapper';
    // let headerWrapperElement  = document.querySelectorAll(headerWrapperSelector);
    let headerPosition = 0;
    let headerHeight = headerElement && headerElement.offsetHeight;
    const init = () => {
      headerPosition = 0;
    }
    const onWindowScroll = () => {
      let sticky = headerElement!.offsetTop;
      // let styles = window.getComputedStyle(headerElement!);
      // let margin = parseFloat(styles['marginTop']);
      
      let drawerElement = document.querySelector('.menu-is-open');
      if(drawerElement){
          headerElement!.classList.add("header--show");
          headerElement!.classList.add("header--sticky");
        }else{
          if ( (window.pageYOffset - headerHeight!) > sticky) {
              headerElement!.classList.add("header--sticky");
              if(headerPosition > window.pageYOffset){
                  headerElement!.classList.add("header--show");
                }else{
                  headerElement!.classList.remove("header--show");
                }
          } else {
            
              headerElement!.classList.remove("header--sticky");
              headerElement!.classList.remove("header--show");
          }
      }
      headerPosition = window.pageYOffset;	
    }
    init();
    window.onscroll = () => { onWindowScroll() };
  })
  // const show = true;

  return (
    <header className="header__navigation">
      <div className="header">
        <div className="header__wrapper">
          <div className="header__nav header__nav--left js-header__nav" id="header-nav">
            <HeaderMenuComponent
               links={leftMenuItems}
            />
          </div>
          <div className="header__mobile-nav">
            <Hamburger />
          </div>
          <div className="header__logo">
            <LogoComponent href={process.env.REACT_APP_WP_URL} logoSrc={`${process.env.REACT_APP_WP_URL}/wp-content/themes/b4b/public/logo.svg`} selectorClass="header" logoTitle="IronBull" logoAlt="Ironbull" />
          </div>
          <div className="header__mobile-nav">	
            <a 
              href={isAuthenticated ? ProfileRoutes.STATISTICS.fullPath : AuthRoutes.LOGIN.fullPath} 
              className="header-menu__link"
            >
              <UserOutlined />
            </a>
		      </div>
          <div className="header__nav header__nav--right js-header__nav" id="header-nav">
            <HeaderMenuComponent
                links={rightMenuItems}
              />
          </div>
        </div>
      </div> 
    </header>   
  );
}