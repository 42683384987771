import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Tabs, Typography } from "antd";
import moment from "moment";

// import './profileReservationsPage.style.scss';
import { i18n, StoreStateInterface, ReservationInterface, DisplayDateFormat, TimeNoSecFormat } from "../../../common";
import { getUserReservationsCurrentAction, getUserReservationsPlayedAction } from "../../../common/redux";

function ProfileReservationsPageComponent() {

  const { TabPane } = Tabs;

  const { Text } = Typography;

  const userId = useSelector((state: StoreStateInterface) => state.auth.user?.id);

  const currentReservations = useSelector((state: StoreStateInterface) => state.user.userReservationsCurrent);

  const playedReservations = useSelector((state: StoreStateInterface) => state.user.userReservationsPlayed);

  const dispatch = useDispatch();

  useEffect(() => {
    if(userId) {
      dispatch(getUserReservationsCurrentAction(userId));
      dispatch(getUserReservationsPlayedAction(userId));
    } 
  }, [userId]);

  const getActiveTableColumns: any = [
    {
      title: i18n.translate("reservations.date"),
      key: 'date',
      render: (reservation: ReservationInterface) => <Text>{moment(reservation.game[0].from).format(DisplayDateFormat)}</Text>
    },
    {
      title: i18n.translate("reservations.time"),
      key: 'time',
      render: (reservation: ReservationInterface) => <Text>{moment(reservation.game[0].from).format(TimeNoSecFormat)}</Text>
    },
    {
      title: i18n.translate("reservations.numOfTanksOccupied"),
      key: 'numOfTanksOccupied',
      render: (reservation: ReservationInterface) => <Text>{reservation.tanksReservedByUser}</Text>
    },
    // {
    //   title: i18n.translate("reservations.players"),
    //   key: 'players',
    //   render: (reservation: any) => reservation.players.map((item:any) => <div>{item}</div>)
    // },
    // {
    //   title: i18n.translate("reservations.tank"),
    //   key: 'tank',
    //   dataIndex: 'tank'
    // },
    {
      title: i18n.translate("reservations.reservationNumber"),
      key: 'number',
      render: (reservation: ReservationInterface) => <Text strong>{reservation.number}</Text>
    },
  ];

  const getFinishedTableColumns: any = [
    {
      title: i18n.translate("reservations.date"),
      key: 'date',
      render: (reservation: ReservationInterface) => <Text>{moment(reservation.game[0].from).format(DisplayDateFormat)}</Text>
    },
    {
      title: i18n.translate("reservations.time"),
      key: 'time',
      render: (reservation: ReservationInterface) => <Text>{moment(reservation.game[0].from).format(TimeNoSecFormat)}</Text>
    },
    {
      title: i18n.translate("reservations.numOfTanksOccupied"),
      key: 'numOfTanksOccupied',
      render: (reservation: ReservationInterface) => <Text>{reservation.tanksReservedByUser}</Text>
    },
    // {
    //   title: i18n.translate("reservations.players"),
    //   key: 'players',
    //   render: (reservation: any) => reservation.players.map((item:any) => <div>{item}</div>)
    // },
    // {
    //   title: i18n.translate("reservations.tank"),
    //   key: 'tank',
    //   dataIndex: 'tank'
    // },
    // {
    //   title: '',
    //   key: 'action',
    //   render: (reservation: any) => <Button type="link">{i18n.translate("reservations.seeStats")}</Button>
    // },
  ];

  const renderActiveReservationsTable = (): React.ReactNode => {
    return <Table columns={getActiveTableColumns} dataSource={currentReservations.filter(currentReservation => currentReservation.game[0])} />
  }  

  const renderFinishedReservationsTable = (): React.ReactNode => {
    return <Table columns={getFinishedTableColumns} dataSource={playedReservations} />
  }

  return (
    <React.Fragment>
      <div className="reservationsPage">
        <Tabs defaultActiveKey="active" size="large">
          <TabPane tab={i18n.translate("reservations.activeReservations")} key="active">
            {renderActiveReservationsTable()}
          </TabPane>
          <TabPane tab={i18n.translate("reservations.finishedReservations")} key="finished">
            {renderFinishedReservationsTable()}
          </TabPane>
        </Tabs>
      </div>
    </React.Fragment>
  );

}

export default ProfileReservationsPageComponent;

