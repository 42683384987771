import ProfileStatisticsPage from './profileStatisticsPage/profileStatisticsPage.component';
import ProfileReservationsPage from './profileReservationsPage/profileReservationsPage.component';
import ProfileFriendsPage from './profileFriendsPage/profileFriendsPage.component';
import ProfileLastGamesPage from './profileLastGamesPage/profileLastGamesPage.component';
import ProfilePersonalDataPage from './profilePersonalDataPage/profilePersonalDataPage.component';
import ProfileRankingPage from './profileRankingPage/profileRankingPage.component';
import ProfileNotificationsPage from './profileNotificationsPage/profileNotificationsPage.component';


export default {
    ProfileStatisticsPage,
    ProfileReservationsPage,
    ProfileFriendsPage,
    ProfileLastGamesPage,
    ProfilePersonalDataPage,
    ProfileRankingPage,
    ProfileNotificationsPage
}