import React from 'react';
import { Modal, Button, Form } from 'antd';
import { i18n } from "../../services";
import { changeUserPasswordByAdminAction } from "../../redux";
import { useDispatch } from 'react-redux';
import { InputPinComponent } from '../auth';
import { useParams } from 'react-router-dom';

export default function NewPinModalComponent(props: {visible: boolean, closeHandler: any}) {

    const params = useParams<{userId?: string}>();
    const userId = parseInt(params.userId!);

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    function onFormFinish(values: any): void {
        dispatch(changeUserPasswordByAdminAction(userId, values.newPassword));
        form.resetFields();
        props.closeHandler();
    }

    function onClose(): void {
        form.resetFields();
        props.closeHandler();
    }

    const checkInputPin = (_: any, value: string) => {
        if (value === "init") {
            return Promise.reject(new Error(i18n.translate("validation.required.password")));
        }   
        if (value.length !== 4 || isNaN(+value)) {
            return Promise.reject(new Error(i18n.translate("validation.pin")));
        }
        return Promise.resolve();
    };
    
    return (
        <Modal 
          title={i18n.translate("changePin.title")} 
          onCancel={() => onClose()} 
          visible={props.visible} 
          footer={null} 
          width="120"
          centered
          >
            <Form
                layout="vertical"
                form={form}
                name="pinChange"
                onFinish={(values: any) => onFormFinish(values)}
                >
                <Form.Item
                    label={i18n.translate("changePin.newPassword")}
                    name="newPassword"
                    rules={[{ validator: checkInputPin }]}
                    initialValue="init"
                >
                    {/* @ts-ignore */}
                    <InputPinComponent />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {i18n.translate("buttons.save").toUpperCase()}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}
