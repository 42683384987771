import React from "react";
import './profileFriendsPage.style.scss';

export default function component() {
  return (
    <div className="profile-friends-page">
      <div className="title">PRIJATELJI</div>
    </div>
  );
}
