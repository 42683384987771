import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColorConstant, i18n, StoreStateInterface, TankInterface, getTanksAction, EditTanksModalComponent, syncTanksAction } from "../../../common";
import TankLogo from "../../../assets/images/logo_tank.svg";
import { Badge, Button, Table } from "antd";
import Title from "antd/lib/typography/Title";
import { getTankStatusLabel, TankStatusConstant } from "../../../common/constants/tankStatus.constant";

function TanksPageComponent() {

    const tanks = useSelector((state: StoreStateInterface) => state.tank.tanks);

    const [isEditTanksModalVisible, setIsEditTanksModalVisible] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTanksAction());
    }, []);

    useEffect(() => {
        let interval = setInterval(() => dispatch(getTanksAction()), 10 * 1000);

        return () => {
          clearInterval(interval);
        };
    }, [])

    const getTableColumns: any = [
        {
            title: i18n.translate("admin.tank.position"),
            key: 'tank.position',
            dataIndex: 'position',
            render: (dataIndex: string, tank: TankInterface) => tank.position && `#${tank.position}`
        },
        {
            title: <img src={TankLogo} alt="tank" />,
            key: 'tank.name',
            dataIndex: 'name',
            render: (dataIndex: string, tank: TankInterface) => `${tank.name}`
        },
        {
            title: i18n.translate("admin.tank.battery"),
            key: 'tank.battery',
            dataIndex: 'battery',
            // render: (dataIndex: string, tank: TankInterface) => `${tank.battery}`
            render: (dataIndex: string, tank: TankInterface) => calculateBatteryPercentage(tank.battery) + ` | ${tank.battery}v`
        },
        {
            title: i18n.translate("admin.tank.state"),
            key: 'tank.isConnected',
            dataIndex: 'isConnected',
            render: (dataIndex: string, tank: TankInterface) => tank.isConnected ? <Badge className="statusBadge" color={ColorConstant.GREEN.hash} /> : <Badge className="statusBadge" color={ColorConstant.RED.hash} />
        },
        {
            title: i18n.translate("admin.tank.status"),
            key: 'tank.status',
            dataIndex: 'status',
            render: (dataIndex: string, tank: TankInterface) => `${tank.status === TankStatusConstant().ACTIVE.name ? getTankStatusLabel(1) : getTankStatusLabel(0)}`
        }
    ];

    function calculateBatteryPercentage(tankBattery: number): String {
        const emptyBattery = 22.4;
        const fullBattery = 24.8;
        let currentBattery = (((tankBattery - emptyBattery) / (fullBattery - emptyBattery)) * 100);
        if(currentBattery > 100) {
            return "100.00%";
        } else if(currentBattery < 0) {
            return "0.00%";
        } else {
            return `${currentBattery.toFixed(2)}%`;
        }
    }

    const renderTitleAndActions = (): React.ReactNode => {

        const renderTitle = (): React.ReactNode => {
            return (
                <div className="flex-row">
                    <Title level={2} className="heading__h2 heading--no-margin"> {i18n.translate("admin.tank.title")}</Title>
                </div>
            );
        };

        const renderActions = (): React.ReactNode => {
            return (
                <div className="flex-row">
                    <Button type="primary" className="item" onClick={() => setIsEditTanksModalVisible(true)}>{i18n.translate("admin.tank.edit")}</Button>
                    <Button type="primary" className="item" onClick={() => dispatch(syncTanksAction())} >{i18n.translate("admin.tank.sync")}</Button>
                    <Button type="primary" className="item" onClick={() => dispatch(getTanksAction())} >{i18n.translate("admin.tank.refreshTanks")}</Button>
                </div>
            );
        };

        return (
            <div className="flex-row space-between mb25">
                {renderTitle()}
                {renderActions()}
            </div>
        );
    };

    const renderTankTable = (): React.ReactNode => {
        let tableTanks = tanks.sort((t1: TankInterface, t2: TankInterface) => {
            return (t1.position === null ? 1 : 0) - (t2.position === null ? 1 : 0) || +(t1.position > t2.position) || -(t1.position < t2.position);
        });
        return <Table rowKey={(tank) => tank.position} columns={getTableColumns} dataSource={tableTanks} pagination={false} scroll={{ x: 240 }} />
    };

    const renderEditTanksModal = (): React.ReactNode => { 
        return (
            <EditTanksModalComponent visible={isEditTanksModalVisible} closeHandler={() => setIsEditTanksModalVisible(false)}></EditTanksModalComponent>
        );
    };

    return (
        <React.Fragment>
            <div className="tanksPage">
                {renderTitleAndActions()}
                {renderTankTable()}
                {renderEditTanksModal()}
            </div>
        </React.Fragment>
    );
}

export default TanksPageComponent;
