import React, { useEffect, useState } from "react";
import moment from "moment";

import { GameStatusStateEnum, LiveUpdateInterface, TankResultInterface } from "../../../common/interfaces/tv";
import { i18n, StoreStateInterface, TeamIdEnum, TeamIdStringToEnum } from "../../../common";
import { useSelector } from "react-redux";

function MatchGamePageComponent() {

  const [displayTimer, setDisplayTimer] = useState<string>('--:--');
  const [counter, setCounter] = useState(0);
  const currentSystemState = useSelector((state: StoreStateInterface) => state.system.currentSystemState);
  const me = useSelector((state: StoreStateInterface) => state.system.me);
  const tvSettings = useSelector((state: StoreStateInterface) => state.tv.tvSettings);

  useEffect(() => {
    const timeout = setTimeout(() => {
      let delta = 0;

      if (currentSystemState && currentSystemState.gameStatus) {

        if (currentSystemState.gameStatus.state === GameStatusStateEnum.RoundTimeOutState) return;
        
        let nowTimestamp = moment().valueOf();
        let newtStateTime = currentSystemState.gameStatus.next.ts * 1000;
        delta = (newtStateTime - nowTimestamp) / 1000;
        if (delta <= 0) delta = 0;
      }

      setDisplayTimer(moment().startOf('day').add(delta, 'seconds').format('mm:ss'));

      if (delta > 0) setCounter(counter + 1);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [counter, currentSystemState]);

  const renderLiveFeed = (): React.ReactNode => {
    if (!currentSystemState || !currentSystemState.liveUpdates) return null;

    let getTankById = (tankId: number): TankResultInterface => {
      return currentSystemState!.currentGame!.tanks[tankId];
    };

    let getStatusColor = (item: LiveUpdateInterface): string => {
      let value = '';
      // if (me!.type === 'INFO') {
      //   value = 'orange';
      // } else {
      //   if (getTankById(item.source.id).teamId === TeamIdStringToEnum(me!.type)) value = 'green';
      //   else value = 'red';
      // }
      if (getTankById(item.source.id).teamId === TeamIdEnum.ALLIES) value = 'blue';
      else value = 'red';
      return value;
    };

    let getStatusText = (item: LiveUpdateInterface): string => {
      let text: string = item.type;

      if (me!.type === 'INFO') {
        switch (item.subtype) {
          case 'hit': text = 'POGODAK'; break;
          case 'destroy': text = 'UNIŠTIO'; break;
        }
      } else {
        if (getTankById(item.source.id).teamId === TeamIdStringToEnum(me!.type)) {
          switch (item.subtype) {
            case 'hit': text = 'POGODAK'; break;
            case 'destroy': text = 'UNIŠTIO'; break;
          }
        } else {
          switch (item.subtype) {
            case 'hit': text = 'POGOĐEN'; break;
            case 'destroy': text = 'UNIŠTEN'; break;
          }
        }
      }

      return text.toUpperCase();
    };

    const renderOneRow = (row: LiveUpdateInterface, index: number) => {
      switch (row.subtype) {
        case 'hit':
        case 'destroy':
          return (
            <div key={index} className="live-feed__box">
              <div>{getTankById(row.source.id).name}</div>
              <div className={`center-center ${getStatusColor(row)}`}>{getStatusText(row)}</div>
              <div>{getTankById(row.destination.id).name}</div>
              <div></div>
            </div>
          );
        case 'custom':
          return (
            <div key={index} className="live-feed__box">
              <div className="live-feed__box--custom">{row.message}</div>
            </div>
          );
      }
      return null;
    }

    return (
      <div className="live-feed">
        <div className="live-feed__title">BORBA UŽIVO</div>
        {currentSystemState!.liveUpdates.reverse().map((lf, i) => renderOneRow(lf, i))}
      </div>
    );
  }

  const renderTable = (type: TeamIdEnum, data: Array<TankResultInterface>): React.ReactNode => {
    let renderHpColumn = (): boolean => {
      let meType = TeamIdStringToEnum(me!.type);
      if (me!.type.toUpperCase() === 'INFO') return true; //TODO
      return meType === type;
    };

    return (
      <div className={`statistic-table ${type === TeamIdEnum.AXIS ? 'left' : 'right'}`}>
        <div className="statistic-table__row statistic-table__row--header">
          <div className="statistic-table__cell"><img src={'./static/images/logo_tank.svg'} alt="" /></div>
          <div className="statistic-table__cell"><img src={'./static/images/logo_user.svg'} alt="" /></div>
          {renderHpColumn() && <div className="statistic-table__cell statistic-table__cell--80">HP</div>}
          <div className="statistic-table__cell statistic-table__cell--80">KILOVI</div>
        </div>
        {data.map((li, i) => (
          <div key={i} className="statistic-table__row">
            <div className="statistic-table__cell">{li.name}</div>
            <div className="statistic-table__cell">
              <div>{li.playerOne}</div>
              <div>{li.playerTwo}</div>
            </div>
            {renderHpColumn() && <div className="statistic-table__cell statistic-table__cell--80">{li.hp}</div>}
            <div className="statistic-table__cell statistic-table__cell--80">{li.kills}</div>
          </div>
        ))}
      </div>
    );
  }

  const renderGameStatus = (): React.ReactFragment => {
    let getStatusColor = (type: TeamIdEnum): string => {
      let meType = TeamIdStringToEnum(me!.type);
      // if (me!.type.toUpperCase() === 'INFO') return 'orange'; //TODO
      // return meType === type ? 'green' : 'red';
      if (type === TeamIdEnum.ALLIES) return 'blue';
      else return 'red';
    };

    let getStatusText = (type: TeamIdEnum): string => {
      return currentSystemState!.currentGame!.currentRound.attackerTeamId === type ? 'ATTACKER' : 'DEFENDER';
    };

    let currentRoundNumber = currentSystemState!.currentGame!.currentRound.number;

    // === TIMELINE ===
    let timeline: Array<{ roundNumber: number, result?: string; timer?: string; winnerLogo?: string}> = [];
    currentSystemState!.rounds?.forEach((round, index) => {
      let timelineItem: any = {
        roundNumber: index + 1,
        result: undefined,
        timer: undefined,
        winnerLogo: undefined,
      };

      switch (round.status) {
        case 'PREPARED':
          if (tvSettings.maxRoundDuration > 0) {
            timelineItem.timer = moment().startOf("day").add(tvSettings.maxRoundDuration, "seconds").format("mm:ss");
          } else {
            timelineItem.timer = '11:00';
          }
          break;
        case 'IN PROGRESS':
          
          break;
        case 'ENDED':
          let roundResult = currentSystemState!.currentGame!.roundResults[index];
          let result: [number, number] = [0, 0];
          if (roundResult) {
            Object.keys(roundResult.tankResults).forEach((tankKey: any) => {
              let tankResult: TankResultInterface = roundResult.tankResults[tankKey];
              let tank = currentSystemState!.currentGame!.tanks[tankKey];
              if (tank.teamId === TeamIdEnum.AXIS) {
                result[1] = result[1] + tankResult.deaths;
              } else {
                result[0] = result[0] + tankResult.deaths;
              }
            });
          }
          timelineItem.result = `${result[0]}:${result[1]}`;
          if (round.victoryTeamId) timelineItem.winnerLogo = round.victoryTeamId === TeamIdEnum.AXIS ? <img src={'./static/images/logo_axis_red.svg'} alt="" /> : <img src={'./static/images/logo_allies_blue.svg'} alt="" />;
          
          break;
      }

      timeline.push(timelineItem);
    });

    // === GAME TABLE ===
    let result: [number, number] = [0, 0];
    let axisTableData: Array<TankResultInterface> = [];
    let alliesTableData: Array<TankResultInterface> = [];

    Object.values(currentSystemState!.currentGame!.tanks).forEach((tank: TankResultInterface) => {
      if (tank.teamId === TeamIdEnum.AXIS) {
        axisTableData.push(tank);
        result[1] = result[1] + tank.deaths;
      } else {
        alliesTableData.push(tank);
        result[0] = result[0] + tank.deaths;
      }
    });

    return (
      <div className="game-status">

        <div className="game-status-top">
          <div className="game-status-top__left title-subtitle">
            <div className="s40 g">Runda {currentRoundNumber}</div>
            <div className="s80 o">{displayTimer}</div>
          </div>
          <div className="game-status-top__right">
            {timeline.map((tl, i) => (
              <div key={i} className={`box title-subtitle ${tl.timer ? 'blur' : ''}`}>
                <div className="s20 g">Runda {tl.roundNumber}</div>
                {tl.result && (<div className="s40 o">{tl.result}{tl.winnerLogo && (<span className="additional">{tl.winnerLogo}</span>)}</div>)}
                {tl.timer && (<div className="s40 o">{tl.timer} <span className="additional">min</span></div>)}
                {!tl.result && !tl.timer && (<div className="s40 o"><img src={'./static/images/logo_tank_yellow.svg'} alt="" /></div>)}
              </div>
            ))}
          </div>
        </div>

        <div className="game-status-bottom">
          <div className="spliter spliter--end">
            <div className={`title-box`}>
              <span>{getStatusText(TeamIdEnum.AXIS)}</span>
              <div className={getStatusColor(TeamIdEnum.AXIS)}><img src={'./static/images/logo_axis_red.svg'} alt="" /> {i18n.translate("team.axis")}</div>
            </div>
            <div className="title-subtitle">
              <div className="s24 g">REZULTAT</div>
              <div className="s60 o">{`${result[0]}:${result[1]}`}</div>
            </div>
            <div className={`title-box`}>
              <span>{getStatusText(TeamIdEnum.ALLIES)}</span>
              <div className={getStatusColor(TeamIdEnum.ALLIES)}><img src={'./static/images/logo_allies_blue.svg'} alt="" /> {i18n.translate("team.allies")}</div>
            </div>
          </div>
          <div className="spliter">
            {renderTable(TeamIdEnum.AXIS, axisTableData)}
            {renderTable(TeamIdEnum.ALLIES, alliesTableData)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="match-game-page">
      {renderLiveFeed()}
      {renderGameStatus()}
    </div>
  );
}

export default MatchGamePageComponent;
