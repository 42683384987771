import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Input, Select, Descriptions } from 'antd';
import moment from "moment";
import { useLocation } from "react-router-dom";

import {
  changeSystemStateAction,
  StoreStateInterface,
  GameStatusStateEnum,
  ClientInterface,
  WebsocketService,
  SocketEventRenameInterface,
  getGamesSystemProperties,
  GamesSystemPropertiesType,
  setGamesSystemProperties,
  getGamesScript,
  setGamesScript,
  startTestGameAction
} from "../../../common";

const { Option } = Select;
const { TextArea } = Input;

function SystemStatus(showTest: boolean) {

  const dispatch = useDispatch();
  const currentSystemState = useSelector((state: StoreStateInterface) => state.system.currentSystemState);
  const me = useSelector((state: StoreStateInterface) => state.system.me);
  const lastSettingsChangeTime = useSelector((state: StoreStateInterface) => state.system.lastSettingsChangeTime);

  useEffect(() => {
    let name = 'UPRAVLJACKA KONZOLA';
    if (me && me.name !== name) {
      let payload: SocketEventRenameInterface = {
        type: 'rename',
        data: {
          client: {
            ...me,
            name
          }
        }
      };
      WebsocketService.send(payload);
    }
  }, [me]);

  useEffect(() => {
    if (!lastSettingsChangeTime) return;
    console.log('=== lastSettingsChangeTime');
    WebsocketService.send({ type: 'refresh' });
  }, [lastSettingsChangeTime]);

  function statusBanner() {
    let state: string = 'NO STATE';
    if (currentSystemState && currentSystemState.gameStatus) {
      let gameStatus = currentSystemState.gameStatus;
      switch (gameStatus.state) {
        case GameStatusStateEnum.ErrorState:
          state = gameStatus.exceptionMessage!;
          break;

        default:
          state = gameStatus.state;
          break;
      }
    }
    return (
      <div className="banner">{state}</div>
    );
  }

  function gameStatus() {
    if (!currentSystemState || !currentSystemState.gameStatus || !currentSystemState.gameStatus.game) return null;
    let gameStatus = currentSystemState.gameStatus;

    let dateToString = (ts: number) => {
      return moment(ts).format('YYYY-MM-DD HH:mm:ss');
    };

    return (
      <div className="actions">
        <div>
          Now: {dateToString(new Date().valueOf())} <br />
        </div>
        <div>
          From: {dateToString(gameStatus.game.from.ts * 1000)} <br />
          To:   {dateToString(gameStatus.game.to.ts * 1000)} <br />
        </div>
        <div>
          Next from: {dateToString(gameStatus.next.ts * 1000)} <br />
          Next status: {gameStatus.next.state}
        </div>
      </div>
    );
  }

  function actions() {
    return (
      <div className="actions">
        {showTest && <Button onClick={() => dispatch(startTestGameAction())} type="primary">START_TEST_GAME</Button>}
        <Button onClick={() => dispatch(changeSystemStateAction('START'))} type="primary">POKRENI IGRAONICU</Button>
        {/* <Button onClick={() => dispatch(changeSystemStateAction('STOP'))} type="primary">STOP</Button> */}
        {showTest && <Button onClick={() => dispatch(changeSystemStateAction('END_GAME'))} type="primary">ZAVRŠI I PONIŠTI TRENUTNI GAME</Button>}
        <Button onClick={() => dispatch(changeSystemStateAction('STOP_PREPARING_START_ROUND'))} type="primary">SKRATI PRIPREMU RUNDE</Button>
      </div>
    );
  }

  return (
    <>
      <h2>Status sustava</h2>
      {statusBanner()}
      {gameStatus()}
      {actions()}
    </>
  );
}

function ConnectionsTable() {
  const [editClient, setEditClient] = useState<ClientInterface>();
  const clients: Array<ClientInterface> = useSelector((state: StoreStateInterface) => state.system.clients);
  const dataSource = clients.map((item, i) => ({ ...item, key: i }));

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: ClientInterface) => <a href="# " onClick={() => cellBtnClick(record)}>{text}</a>
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      render: (text: any, record: ClientInterface) => <a href="# " onClick={() => cellBtnClick(record)}>{text}</a>
    },
  ];

  function cellBtnClick(client: ClientInterface): any {
    setEditClient(client);
  }

  const handleRenameFieldChange = (value: any, fieldId: any) => {
    let client: any = {
      ...editClient
    };
    client[fieldId] = value;
    setEditClient(client);
  }

  const onSaveRenameFormBtnClick = () => {
    let payload: SocketEventRenameInterface = {
      type: 'rename',
      data: {
        client: editClient!
      }
    };
    WebsocketService.send(payload);
    setEditClient(undefined);
  }

  const renderRenameForm = () => {
    if (!editClient) return <div></div>;

    return (
      <div className="mb16">
        <form>
          <label className="ml16 mr16">Name: </label>
          <Input value={editClient.name} onChange={e => handleRenameFieldChange(e.target.value, 'name')} style={{ width: 300 }} />

          <label className="ml16 mr16">Tip: </label>
          <Select defaultValue={editClient.type} onChange={e => handleRenameFieldChange(e, 'type')} style={{ width: 300 }}>
            <Option value="INFO">INFO</Option>
            <Option value="AXIS">AXIS</Option>
            <Option value="ALLIES">ALLIES</Option>
          </Select>

          <Button onClick={onSaveRenameFormBtnClick} type="primary" className="ml16">Spremi</Button>
        </form>
      </div>
    );
  }

  return (
    <>
      <h2>Spojeni klijenti</h2>
      {renderRenameForm()}
      <Table dataSource={dataSource} columns={columns} bordered={true} pagination={false} scroll={{ x: 240 }} />
      {/* <Button onClick={() => WebsocketService.send({ type: 'refresh' })} type="primary" className="mt16">REFRESH</Button> */}
    </>
  );

}

function SystemSettings() {

  const dispatch = useDispatch();
  const [editSettings, setEditSettings] = useState<{ key: string; value: number } | undefined>(undefined);
  const systemProperties: any = useSelector((state: StoreStateInterface) => state.system.systemProperties);

  const productionSystemProperties: any = [
    { key: 'maxRoundDuration', value: 660 },
    { key: 'gameService.minAllowedGameDurationMinutes', value: 60 },
    { key: 'waitBeforePrepareTanks', value: 510 },
    { key: 'waitAfterTanksPrepared', value: 30 },
    { key: 'endGameState.secondsBeforeNextStarts', value: 120 },
    { key: 'waitAfterErrorInSeconds', value: 30 },
    { key: 'debug.fakeGame.rounds', value: 3 },
    { key: 'debug.fakeGame.startsInSeconds', value: 60 },
    { key: 'minAvailableTimeForRound', value: 900 },
  ];

  const testSystemProperties: any = [
    { key: 'maxRoundDuration', value: 300 },
    { key: 'gameService.minAllowedGameDurationMinutes', value: 5 },
    { key: 'waitBeforePrepareTanks', value: 60 },
    { key: 'waitAfterTanksPrepared', value: 30 },
    { key: 'endGameState.secondsBeforeNextStarts', value: 180 },
    { key: 'waitAfterErrorInSeconds', value: 30 },
    { key: 'debug.fakeGame.rounds', value: 3 },
    { key: 'debug.fakeGame.startsInSeconds', value: 60 },
    { key: 'minAvailableTimeForRound', value: 390 },
  ];

  useEffect(() => {
    dispatch(getGamesSystemProperties());
  }, []);

  const getLabelForProp = (_key: any) => {
    let key: GamesSystemPropertiesType = _key;
    switch (key) {
      case 'maxRoundDuration': return 'Trajanje runde';
      case 'gameService.minAllowedGameDurationMinutes': return 'Minimalno vrijeme potrebno za novu igru';
      case 'waitBeforePrepareTanks': return 'Čekanje prije pripreme tenkova';
      case 'waitAfterTanksPrepared': return 'Čekanje nakon pripreme tenkova ';
      case 'endGameState.secondsBeforeNextStarts': return 'Čekanje prije sljedećeg početka (sek)';
      case 'waitAfterErrorInSeconds': return 'Čekanje nakon greške';
      case 'debug.fakeGame.rounds': return 'Broj rundi u testnoj igri';
      case 'debug.fakeGame.startsInSeconds': return 'Čekanje za početak testne igre';
      case 'minAvailableTimeForRound': return 'Minimalno vrijeme potrebno za dodavanje runde';
    }
  };

  const getValueForProp = (_key: any) => {
    let key: GamesSystemPropertiesType = _key;
    let value: number = systemProperties[key];
    switch (key) {
      case 'maxRoundDuration': return moment().startOf('day').add('seconds', value).format('HH:mm:ss');
      case 'gameService.minAllowedGameDurationMinutes': return moment().startOf('day').add('minutes', value).format('HH:mm:ss');
      case 'waitBeforePrepareTanks': return moment().startOf('day').add('seconds', value).format('HH:mm:ss');
      case 'waitAfterTanksPrepared': return moment().startOf('day').add('seconds', value).format('HH:mm:ss');
      case 'endGameState.secondsBeforeNextStarts': return moment().startOf('day').add('seconds', value).format('HH:mm:ss');
      case 'waitAfterErrorInSeconds': return moment().startOf('day').add('seconds', value).format('HH:mm:ss');
      case 'debug.fakeGame.rounds': return moment().startOf('day').add('seconds', value).format('HH:mm:ss');
      case 'debug.fakeGame.startsInSeconds': return moment().startOf('day').add('seconds', value).format('HH:mm:ss');
      case 'minAvailableTimeForRound': return moment().startOf('day').add('seconds', value).format('HH:mm:ss');
    }
  };

  const handleFieldChange = (key: string, value: number) => {
    setEditSettings({ key, value: value || 0 });
  }

  const onSaveFormBtnClick = () => {
    if (!editSettings) return;
    dispatch(setGamesSystemProperties(editSettings.key, editSettings.value));
    setEditSettings(undefined);
  }

  const setProductionSettingsClick = () => {
    productionSystemProperties.forEach((prop: any) => {
      dispatch(setGamesSystemProperties(prop.key, prop.value));
    });
  }

  const setTestSettingsClick = () => {
    testSystemProperties.forEach((prop: any) => {
      dispatch(setGamesSystemProperties(prop.key, prop.value));
    });
  }

  const renderForm = () => {
    if (!editSettings) return <div></div>;

    return (
      <div className="mb16">
        <form>
          <label className="ml16 mr16">{getLabelForProp(editSettings.key)}: </label>
          <Input type='number' value={editSettings.value} onChange={e => handleFieldChange(editSettings.key, parseInt(e.target.value))} style={{ width: 300 }} />
          <Button onClick={onSaveFormBtnClick} type="primary" className="ml16">Spremi</Button>
        </form>
      </div>
    );
  }

  const renderDefaultButtons = () => {
    return (
      <div className="mb16 mt16">
        <form>
          <Button onClick={setProductionSettingsClick} type="primary" className="ml16">Postavi produkcijske vrijednosti</Button>
          <Button onClick={setTestSettingsClick} type="primary" className="ml16">Postavi testne vrijednosti</Button>
        </form>
      </div>
    );
  }

  // 'gameService.minAllowedGameDurationMinutes', 'waitAfterErrorInSeconds'
  return (
    <>
      <h2>Postavke sustava</h2>
      {renderForm()}
      <Descriptions bordered column={2}>
        {['maxRoundDuration',  'waitBeforePrepareTanks', 'waitAfterTanksPrepared'].map(key => (
          <Descriptions.Item key={key} label={getLabelForProp(key)}>
            <a href="# " onClick={() => { setEditSettings({ key, value: systemProperties[key] }) }}>
              {getValueForProp(key)}
            </a>
          </Descriptions.Item>
        ))}
      </Descriptions>
      {renderDefaultButtons()}
    </>
  );

}

function Script() {

  const dispatch = useDispatch();
  const [editSettings, setEditSettings] = useState<string | undefined>(undefined);
  const [isFirstSet, setIsFirstSet] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);
  const script: any = useSelector((state: StoreStateInterface) => state.system.script);

  useEffect(() => {
    dispatch(getGamesScript());
  }, []);

  useEffect(() => {
    setEditSettings(script);
  }, [script]);

  const handleFieldChange = (value: string) => {
    if (!isFirstSet) setCanSave(true);
    else setIsFirstSet(true);
    setEditSettings(value);
  }

  const onSaveFormBtnClick = () => {
    if (!editSettings) return;
    dispatch(setGamesScript(editSettings));
    setCanSave(false);
  }

  const renderForm = () => {
    if (!editSettings) return <div></div>;

    return (
      <div className="mb16">
        <form>
          {canSave && <Button onClick={onSaveFormBtnClick} type="primary" className="mb8">Spremi</Button>}
          <TextArea onChange={e => handleFieldChange(e.target.value)} value={editSettings} style={{ width: '100%', height: '800px' }} />
        </form>
      </div>
    );
  }

  return (
    <>
      <h2>Skripta</h2>
      {renderForm()}
    </>
  );

}

function SystemPageComponent() {

  useEffect(() => {
    WebsocketService.connect();
    return () => {
      WebsocketService.disconnect();
    };
  }, []);

  const queryParams = useLocation().search;
  const showTest = new URLSearchParams(queryParams).get('showTest') == 'true';
  const showSettings = new URLSearchParams(queryParams).get('showSettings') == 'true';
  const showScript = new URLSearchParams(queryParams).get('showScript') == 'true';

  return (
    <React.Fragment>
      <div className="systemPage">
        <h1>Upravljačka konzola</h1>
        {SystemStatus(showTest)}
        {ConnectionsTable()}
        {showSettings && SystemSettings()}
        {showScript && Script()}
      </div>
    </React.Fragment>
  );

}

export default SystemPageComponent;
