import { Dispatch } from 'redux';
import { GameService } from '../../api';
import { GameInterface, GameTankInterface, OccupationInterface, ReservationInterface } from '../../interfaces';
import { refreshGame } from '../game';
import { ReservationConstants } from './reservation.constants';
import { store } from '../store';
import { i18n, NavigationService } from '../../services';
import { ReservationsRoutes } from '../../../features/reservations/_router/reservations.routes';
import { notification } from 'antd';

export function createAdminOccupationAction(gameTank: GameTankInterface, game: GameInterface) {
  return (dispatch: Dispatch) => {
    let reservationId: number | undefined = store.getState().reservation.reservationId;
    return GameService.createOccupation(gameTank.gameTankId, true, reservationId).subscribe(
      (response: OccupationInterface) => {
        return GameService.finishReservation(response.webReservationId).subscribe(
          (response: ReservationInterface) => {
            dispatch(refreshGame(game.gameId) as any);
          }
        );
      },
      (error) => {
        if(error.status === 403) {
          notification["error"]({ message: i18n.translate("errorCodes.403") });
        }
        if(error.status === 409) {
          notification["error"]({ message: i18n.translate("errorCodes.409") });
        }
        dispatch({
          type: ReservationConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function createUserOccupationAction(gameTank: GameTankInterface, game: GameInterface) {
  return (dispatch: Dispatch) => {
    let reservationId: number | undefined = store.getState().reservation.reservationId;
    return GameService.createOccupation(gameTank.gameTankId, false, reservationId).subscribe(
      (response: OccupationInterface) => {
        dispatch({
          type: ReservationConstants.CREATE_OCCUPATION,
          payload: {
            ...response,
            gameTank: {...gameTank, game: game},
          }
        });
        dispatch(refreshGame(game.gameId) as any);
      },
      (error) => {
        if(error.status === 403) {
          notification["error"]({ message: i18n.translate("errorCodes.403") });
        }
        if(error.status === 406) {
          notification["error"]({ message: i18n.translate("errorCodes.406") });
        }
        if(error.status === 409) {
          notification["error"]({ message: i18n.translate("errorCodes.409") });
        }
        dispatch(refreshGame(game.gameId) as any);
        dispatch({
          type: ReservationConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function transferOccupationAction(occupationFromId: number, gameTankToId: number, gameId: number) {
  return(dispatch: Dispatch) => {
    return GameService.transferUserOccupation(occupationFromId, gameTankToId).subscribe(
      (response: OccupationInterface) => {
        dispatch(refreshGame(gameId) as any);
      },
      (error: Error) => {
        dispatch(refreshGame(gameId) as any);
        dispatch({
          type: ReservationConstants.ERROR,
          payload: error
        });
      }
    );
  }
}

export function removeUserFromOccupationAction(occupationId: number, playerNumber: number, gameId: number, reservationNumber?: number) {
  return(dispatch: Dispatch) => {
    return GameService.removeUserFromOccupation(occupationId, playerNumber).subscribe(
      (response: OccupationInterface) => {
        dispatch(refreshGame(gameId) as any);
        if(reservationNumber) dispatch(getReservationByNumberAction(reservationNumber) as any);
      },
      (error: Error) => {
        dispatch(refreshGame(gameId) as any);
        dispatch({
          type: ReservationConstants.ERROR,
          payload: error
        });
      }
    );
  }
}

export function deactivateOccupationAction(occupationId: number, game: GameInterface) {
  return (dispatch: Dispatch) => {
    return GameService.deactivateOccupation(occupationId).subscribe(
      (response: void) => {
        dispatch(refreshGame(game.gameId) as any);
      },
      (error: Error) => {
        dispatch({
          type: ReservationConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function deleteOccupationAction(occupationId: number, game?: GameInterface) {
  return (dispatch: Dispatch) => {
    return GameService.deleteOccupation(occupationId).subscribe(
      (response: void) => {
        dispatch({
          type: ReservationConstants.DELETE_OCCUPATION,
          payload: occupationId
        });
        if (game) dispatch(refreshGame(game.gameId) as any);
      },
      (error: Error) => {
        dispatch({
          type: ReservationConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function checkOccupationValidationAction() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ReservationConstants.CHECK_OCCUPATION_VALIDATION
    });
  };
}

export function finishReservationAction(reservationId: number) {
  return (dispatch: Dispatch) => {
    return GameService.finishReservation(reservationId).subscribe(
      (response: ReservationInterface) => {
        dispatch({
          type: ReservationConstants.FINISH_RESERVATION,
          payload: response
        });
        NavigationService.navigate(ReservationsRoutes.RESERVATION_CONFIRMATION.fullPath)
      },
      (error: Error) => {
        dispatch({
          type: ReservationConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function getReservationByNumberAction(reservationNumber: number) {
  return (dispatch: Dispatch) => {
    return GameService.getReservationByNumber(reservationNumber).subscribe(
      (response: ReservationInterface) => {
        dispatch({
          type: ReservationConstants.GET_RESERVATION,
          payload: response
        });
      },
      (error: Error) => {
        dispatch({
          type: ReservationConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function removeAdminReservationAction() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ReservationConstants.REMOVE_ADMIN_RESERVATION,
    });
  };
}

export function markOccupationAsBilledAction(occupationId: number, reservationNumber: number, gameId: number) {
  return (dispatch: Dispatch) => {
    return GameService.markOccupationAsBilled(occupationId).subscribe(
      (response: ReservationInterface) => {
        dispatch(getReservationByNumberAction(reservationNumber) as any);
        dispatch(refreshGame(gameId) as any);
      },
      (error: Error) => {
        dispatch(getReservationByNumberAction(reservationNumber) as any);
      }
    );
  };
}

