import React from "react";

import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";

import TvPageComponent from "../tvPage";
import { TvRoutes } from "./tv.routes";

function TvRouter() {

  const { path } = useRouteMatch();

  let routes = [
    {
      path: TvRoutes.ROOT.path,
      component: TvPageComponent,
      roles: [],
      exact: true,
    },
  ];

  return (
    <Switch>
      {routes.map((route, index) => (<Route exact={route.exact} path={`${path}/${route.path}`} key={index}> <route.component /> </Route>))}
      <Redirect to={TvRoutes.ROOT.path} />
    </Switch>
  );
}

export default TvRouter;