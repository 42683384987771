import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import * as serviceWorker from './serviceWorker';
import * as momentTimezone from "moment-timezone";
import './assets/scss/_index.scss';
import './assets/images/index';
import { Main } from './main';
//import ReactGA from 'react-ga';

// postaviti tracking id za google analytics
//const trackingId = "UA-159071453-1"; // Replace with your Google Analytics tracking ID
//ReactGA.initialize(trackingId);

// Sentry.init({
//     dsn: "https://dac3831741a64c38984815b106d9c851@o890543.ingest.sentry.io/5839592",
//     integrations: [new Integrations.BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });

momentTimezone.locale("en");
momentTimezone.tz.setDefault("Europe/Zagreb");


ReactDOM.render(<Main />, document.getElementById('root'));

serviceWorker.unregister();