import React from "react";
import { RegisterComponent } from "../../../common";

function RegisterPageComponent() {

  return (
    <div className="center-center w100-h100">
        <RegisterComponent></RegisterComponent>
    </div>
  );


}

export default RegisterPageComponent;
