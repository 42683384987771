import { Dispatch } from 'redux';
import { GameService } from '../../api';
import { GameResponseInterface, GamesResponseInterface } from '../../interfaces';
import { getReservationByNumberAction } from '../reservation';
import { GameConstants } from './game.constants';

export function getGamesByDayAction(day: string) {
  return (dispatch: Dispatch) => {
    return GameService.getGamesByDay(day).subscribe(
      (response: GamesResponseInterface) => {
        dispatch({
          type: GameConstants.GET_GAMES_SUCCESS,
          payload: response.data
        });
      },
      (error: Error) => {
        dispatch({
          type: GameConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

// TODO razmisliti o ovom rješenju
export function getGameByIdAction(gameId: number) {
  return (dispatch: Dispatch) => {
    return GameService.getGameById(gameId).subscribe(
      (response: GameResponseInterface) => {
        dispatch({
          type: GameConstants.GET_GAMES_SUCCESS,
          payload: [response.data]
        });
      },
      (error: Error) => {
        dispatch({
          type: GameConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function refreshGame(gameId: number) {
  return (dispatch: Dispatch) => {
    return GameService.getGameById(gameId).subscribe(
      (response: GameResponseInterface) => {
        dispatch({
          type: GameConstants.REFRESH_GAME,
          payload: response.data
        });
      },
      (error: Error) => {
        dispatch({
          type: GameConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function addUserToOccupationAction(occupationId: number, email: string, playerNumber: number, gameId: number, reservationNumber?: number) {
  return (dispatch: Dispatch) => {
    return GameService.addUserToOccupation(occupationId, email, playerNumber).subscribe(
      (response: any) => {
        dispatch(refreshGame(gameId) as any);
        if(reservationNumber) dispatch(getReservationByNumberAction(reservationNumber) as any);
      },
      (error: Error) => {
        dispatch(refreshGame(gameId) as any);
        dispatch({
          type: GameConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function setGameTankStatusAction(gameTankId: number, gameId: number, status: string) {
  return (dispatch: Dispatch) => {
    return GameService.setGameTankStatus(gameTankId, status).subscribe(
      (response: void) => {
        dispatch(refreshGame(gameId) as any);
      },
      (error: Error) => {
        dispatch({
          type: GameConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function setGameStatusAction(status: number, gameId: number) {
  return (dispatch: Dispatch) => {
    return GameService.setGameStatus(status, gameId).subscribe(
      (response: any) => {
        dispatch(refreshGame(gameId) as any);
      },
      (error: Error) => {
        dispatch(refreshGame(gameId) as any);
        dispatch({
          type: GameConstants.ERROR,
          payload: error
        });
      }
    );
  };
}


