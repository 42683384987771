import { Observable } from "rxjs";

import { TanksResponseInterface } from "../interfaces";
import HttpFactory from "./abstract/abstract.api.service";
import { apiRoutes } from "./routes";

export class TankService {
    static getTanks(): Observable<TanksResponseInterface> {
        return HttpFactory.GET(apiRoutes.tank);
    }

    static getTankById(tankId: number): Observable<TanksResponseInterface> {
        return HttpFactory.GET([apiRoutes.tank, tankId].join('/'));
    }

    static setTankStatus(tankId: number, status: string): Observable<void> {
        return HttpFactory.POST([apiRoutes.tank, tankId, "status"].join('/'), {tankStatus: status});
    } 

    static changeTankPosition(tankFromId: number, tankToId: number): Observable<void> {
        return HttpFactory.POST([apiRoutes.tank, "position"].join('/'), {firstTankId: tankFromId, secondTankId: tankToId});
    }

    static changeRemoteState(tankId: number, remoteState: string): Observable<void> {
        return HttpFactory.POST([apiRoutes.tank, tankId, remoteState].join('/'), {});
    }

    static syncTanks(): Observable<void> {
        return HttpFactory.POST([apiRoutes.tank, "sync_tanks"].join('/'), {});
    }
}