import { UserConstants } from "./user.constants";
import { UserInterface, ReducerActionInterface, UserStatsInterface, ReservationInterface, GamePlayedInterface } from "../../interfaces";

// ============ INTERFACE ============

export interface UserStateInterface {
  users: Array<UserInterface>;
  user?: UserInterface;
  usersByRank: Array<UserInterface>;
  userStats?: UserStatsInterface;
  userReservationsCurrent: Array<ReservationInterface>;
  userReservationsPlayed: Array<ReservationInterface>;
  userGamesPlayed: Array<GamePlayedInterface>;
}

// ============ INIT STATE ============

const initialState: UserStateInterface = {
  users: [],
  usersByRank: [],
  userReservationsCurrent: [],
  userReservationsPlayed: [],
  userGamesPlayed: []
};

// ============ REDUCERS ============

const getUsersSuccess = (state: UserStateInterface, payload: Array<UserInterface>): UserStateInterface => {
  return {
    ...state,
    users: payload
  };
};

const getUserSuccess = (state: UserStateInterface, payload: UserInterface): UserStateInterface => {
  return {
    ...state,
    user: payload
  };
};

const getUsersByRankSuccess = (state: UserStateInterface, payload: Array<UserInterface>): UserStateInterface => {
  return {
    ...state,
    usersByRank: payload
  };
};

const getUserStatsSuccess = (state: UserStateInterface, payload: UserStatsInterface): UserStateInterface => {
  return {
    ...state,
    userStats: payload
  };
};

const getUserReservationsCurrentSuccess = (state: UserStateInterface, payload: Array<ReservationInterface>): UserStateInterface => {
  return {
    ...state,
    userReservationsCurrent: payload
  };
};

const getUserReservationsPlayedSuccess = (state: UserStateInterface, payload: Array<ReservationInterface>): UserStateInterface => {
  return {
    ...state,
    userReservationsPlayed: payload
  };
};

const getUserGamesPlayedSuccess = (state: UserStateInterface, payload: Array<GamePlayedInterface>): UserStateInterface => {
  return {
    ...state,
    userGamesPlayed: payload
  };
};

const error = (state: UserStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};


// ============ EXPORTS ============

export const UserReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case UserConstants.GET_USERS_SUCCESS: return getUsersSuccess(state, action.payload);
    case UserConstants.GET_USER_SUCCESS: return getUserSuccess(state, action.payload);
    case UserConstants.GET_USERS_BY_RANK_SUCCESS: return getUsersByRankSuccess(state, action.payload);
    case UserConstants.GET_USER_STATS_SUCCESS: return getUserStatsSuccess(state, action.payload);
    case UserConstants.GET_USER_RESERVATIONS_CURRENT_SUCCESS: return getUserReservationsCurrentSuccess(state, action.payload);
    case UserConstants.GET_USER_RESERVATIONS_PLAYED_SUCCESS: return getUserReservationsPlayedSuccess(state, action.payload);
    case UserConstants.GET_USER_GAMES_PLAYED_SUCCESS: return getUserGamesPlayedSuccess(state, action.payload);
    case UserConstants.UPDATE_USER_SUCCESS: return getUserSuccess(state, action.payload);
    case UserConstants.ERROR: return error(state, action.payload);
    default:
      return state;
  }
};
