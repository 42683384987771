import { Dispatch } from 'redux';
import { EventsService } from '../../api';
import { EventConstants } from './event.constants';

export function getLastEventsAction(numberOfEvents: number) {
  return(dispatch: Dispatch) => {
    return EventsService.getLastEvents(numberOfEvents).subscribe(
      (response: any) => {
        dispatch({
          type: EventConstants.GET_LAST_EVENTS,
          payload: response.data
        });
      },
      (error: Error) => {
        dispatch({
          type: EventConstants.ERROR,
          payload: error
        });
      }
    )
  }
}


