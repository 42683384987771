import React from "react";
import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";
import { Layout } from "antd";

import { ContentComponent, DrawerComponent, FooterComponent, PageOverlayComponent, PrimaryHeaderComponent } from "../../../common";

import { ReservationsRoutes } from "./reservations.routes";
import ReservationsPageComponent from "../reservationsPage/reservationsPage.component";
import ReservationReviewPageComponent from "../reservationReviewPage/reservationReviewPage.component";
import ReservationConfirmationPageComponent from "../reservationConfirmationPage/reservationConfirmationPage.component";

function ReservationsRouter() {

  const { path } = useRouteMatch();

  let routes = [
    {
      path: ReservationsRoutes.RESERVATIONS.path,
      component: ReservationsPageComponent,
      roles: [],
      exact: true,
    },
    {
      path: ReservationsRoutes.RESERVATION_REVIEW.path,
      component: ReservationReviewPageComponent,
      roles: [],
      exact: true,
    },
    {
      path: ReservationsRoutes.RESERVATION_CONFIRMATION.path,
      component: ReservationConfirmationPageComponent,
      roles: [],
      exact: true,
    },
  ];

  return (
    <Layout className="layout">
      <PrimaryHeaderComponent />
      <DrawerComponent />
      <PageOverlayComponent />
      <ContentComponent>
        <Switch>
          {routes.map((route, index) => (<Route exact={route.exact} path={`${path}/${route.path}`} key={index}> <route.component /> </Route>))}
          <Redirect to={ReservationsRoutes.RESERVATIONS.fullPath} />
        </Switch>
      </ContentComponent>
      <FooterComponent />
    </Layout>
  );
}

export default ReservationsRouter;
