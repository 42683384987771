import { Button, DatePicker } from "antd";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight, FaExclamationCircle, FaSortAmountUpAlt } from 'react-icons/fa';

import './gamesPage.style.scss';
import { useDispatch, useSelector } from "react-redux";
import { IoTime } from "react-icons/io5";
import TankLogo from "../../../../assets/images/logo_tank.svg";
import TankLogoBlack from "../../../../assets/images/logo_tank_black.svg";
import TankLogoDisabled from "../../../../assets/images/logo_tank_disabled.svg";
import { DefaultDateFormat, DisplayDateFormat, GameInterface, GameStatusConstant, getGamesByDayAction, getGameStatusTextById, i18n, NavigationService, StoreStateInterface, TimeNoSecFormat } from "../../../../common";
import { AdminRoutes } from "../../_router/admin.routes";
import { useLocation } from "react-router-dom";

function GamesPageComponent() {

  const [date, setDate] = useState<Moment>(moment());

  const games = useSelector((state: StoreStateInterface) => state.game.games);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGamesByDayAction(date.format(DefaultDateFormat)));
  }, [date]);

  const dateParam = new URLSearchParams(useLocation().search).get('date');

  useEffect(() => {
    if (dateParam) setDate(moment(dateParam));
  }, [dateParam]);

  function selectGame(gameId: number): void {
    NavigationService.navigate(`${AdminRoutes.GAMES.fullPath}/${gameId}`)
  }

  // const getTableColumns: any = [
  //     {
  //       title: 'Termin',
  //       key: 'gameId',
  //       dataIndex: 'from',
  //       render: (dataIndex: string, game: GameInterface) => <Button type="link" onClick={() => NavigationService.navigate(`${AdminRoutes.GAMES.fullPath}/${game.gameId}`)}>{moment(game.from).format(TimeNoSecFormat)}</Button>
  //     },
  //     {
  //       title: 'Zauzeti tenkovi',
  //       key: 'tanks',
  //       dataIndex: 'numOfTanksOccupied',
  //       render: (dataIndex: string, game: GameInterface) => `${game.numOfTanksOccupied}/${game.numOfGameTanks}`
  //     },
  //     {
  //       title: 'Status',
  //       key: 'status',
  //       dataIndex: 'status',
  //       render: (dataIndex: string, game: GameInterface) => `Aktivan`
  //     }
  //   ];
  

  const renderDatePicker = (): React.ReactNode => {
    return (
      <div className="picker">
        <Button type="link" icon={<FaChevronLeft size={'30px'} color={'#acacac'} />}  onClick={() => setDate(moment(date).subtract(1, 'days'))} />
        <DatePicker
          bordered={false}
          suffixIcon={''}
          value={date}
          format={DisplayDateFormat}
          allowClear={false}
          className="ant-picker-largest"
          size='large'
          onChange={(value: Moment | null) => {if (value) setDate(value)}}
        ></DatePicker>
        <Button type="link" icon={<FaChevronRight size={'30px'} color={'#acacac'} />} onClick={() => setDate(moment(date).add(1, 'days'))} />
      </div>
    )
  }

  // const renderGamesTable = (): React.ReactNode => {
  //   return <Table columns={getTableColumns} dataSource={games} />
  // }

  const renderGamesGrid = (): React.ReactNode => {
    return (
      <div className="games-grid">
        {games && games.map((game: GameInterface, index: number) => {
          return renderAdminGameCard(game, index);
        })}
      </div>
    )
  }  

  const renderAdminGameCard = (game: GameInterface, index: number): React.ReactNode => {
    let adminGameCardObject: {class: string, status: React.ReactNode} = {
      class: 'active',
      status: <><img src={TankLogo} alt="tank" className="mr8" /> {game.numOfTanksOccupied} / {game.numOfGameTanks}</>
    }
  
    if (game.status === GameStatusConstant().NOT_ACTIVE.id) {
      adminGameCardObject = {
        class: 'inactive',
        status: <>{<FaExclamationCircle className="mr8" size={'16px'} color={'#141414'}/>}{getGameStatusTextById(game.status)}</>
      }
    } else if (moment(game.from).isBefore(moment())) {
      if (game.status === GameStatusConstant().FINISHED.id) {
        adminGameCardObject = {
          class: 'finished',
          status: <>{i18n.translate("admin.game.playedGame")} {<FaSortAmountUpAlt className="mr8" size={'16px'} color={'#F2994A'}/>}</>}
      } else {
        adminGameCardObject = {
          class: 'finished',
          status: <>{i18n.translate("admin.game.finishedGame")}</>}
      }
    } else if (game.status === GameStatusConstant().RESERVED_BY_ADMIN.id) {
      adminGameCardObject = {
        class: 'reserved',
        status: <>{getGameStatusTextById(game.status)} <div><img src={TankLogoDisabled} alt="tank" className="mr8" /> {game.numOfTanksOccupied} / {game.numOfGameTanks}</div></> 
      }
    }  else if (game.numOfTanksOccupied === 12) {
      adminGameCardObject = {
        class: 'filled',
        status: <><p className="no-padding no-margin">{i18n.translate("admin.game.filledGame")}</p><div><img src={TankLogoDisabled} alt="tank" className="mr8" />{game.numOfTanksOccupied} / {game.numOfGameTanks}</div></>
      }  
    } else if (game.numOfTanksOccupied > 0) {
      adminGameCardObject = {
        class: 'occupied',
        status: <><img src={TankLogoBlack} alt="tank" className="mr8" />{game.numOfTanksOccupied} / {game.numOfGameTanks}</>
      }  
    }
    return (
      <div className={`adminGameCard ${adminGameCardObject.class}`} key={index} onClick={() => selectGame(game.gameId)}>
        <div className="flex-row time mt32 mb32">
          <IoTime />
          <div className="ml8">{moment(game.from).format(TimeNoSecFormat)}</div>
        </div>
        <div className="status">{adminGameCardObject.status}</div>
      </div>
    )
  }


  return (
    <React.Fragment>
      <div className="gamesPage">
        {renderDatePicker()}
        {renderGamesGrid()}
      </div>
    </React.Fragment>
  );

}

export default GamesPageComponent;
