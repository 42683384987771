import React from "react";
import classnames from 'classnames';
import { Link } from "react-router-dom";


export default function MenuComponent(props:any) {
  const {
      componentClass = "header-menu",
      selectorClass = "",
      links,
  } = props;

  const listItems = links.map((link:any) =>
    <li key={link.title} className={`${componentClass}__item`}>
      <Link to={link.href} className={`${componentClass}__link`}>
        <span className={`${componentClass}__link-text`}>{link.title}</span>
      </Link>
    </li>
  );

  const menuClass = classnames([
      componentClass,
      selectorClass ? selectorClass + '__' + componentClass : ''
  ]);

  return (
    <div className={menuClass}>
      <ul className={`${componentClass}__items`}>
        {listItems}
      </ul>
    </div>   
  );
}