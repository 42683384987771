import { Dispatch } from 'redux';
import { SystemService } from '../../api';
import { TvConstants } from './tv.constants';

export function getTvSettings() {
  return (dispatch: Dispatch) => {
    return SystemService.getTvSettings().subscribe(
      resp => {
        dispatch({ type: TvConstants.GET_TV_SETTINGS, payload: resp.data });
      },
      (error: Error) => {
        console.log(error);
      }
    );
  };
}
