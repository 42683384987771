import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import { NavigationService, setLangAction, StoreStateInterface } from "../../common";

import { AuthRouter, ROOT_PATH_AUTH } from "../auth";
import { AdminRouter, ROOT_PATH_ADMIN } from "../admin";
import { ReservationsRouter, ReservationsRoutes, ROOT_PATH_RESERVATIONS } from "../reservations";
import { ProfileRouter, ROOT_PATH_PROFILE } from "../profile";
import { ROOT_PATH_TV, TvRouter } from "../tv";
import { ConfigProvider } from "antd";
import hr_HR from 'antd/lib/locale-provider/hr_HR';
import en_GB from 'antd/lib/locale-provider/en_GB';
import { Locale } from "antd/lib/locale-provider";
import 'moment/locale/hr'  // without this line it didn't work
import { setLang } from "../../common/services/translate.service";
import { useDispatch, useSelector } from "react-redux";
import UnavailablePageComponent from "../unavailable/unavailablePage.component";
import VersionComponent from "../../common/components/version/version.component";

function MainRouter() {
  const [providerLang, setProviderLang] = useState<Locale>(hr_HR);

  const userLang = useSelector((state: StoreStateInterface) => state.auth.lang);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLangAction(userLang));
    setLang(userLang);
    setProviderLang(userLang === "en" ? en_GB : hr_HR);
  }, [userLang]);

  let routes = [
    {
      path: ROOT_PATH_AUTH,
      component: AuthRouter,
      roles: [],
      exact: false,
    },
    {
      path: ROOT_PATH_RESERVATIONS,
      component: ReservationsRouter,
      roles: [],
      exact: false,
    },
    {
      path: ROOT_PATH_ADMIN,
      component: AdminRouter,
      roles: [],
      exact: false,
    },
    {
      path: ROOT_PATH_PROFILE,
      component: ProfileRouter,
      roles: [],
      exact: false,
    },
    {
      path: ROOT_PATH_TV,
      component: TvRouter,
      roles: [],
      exact: false,
    },
    {
      path: 'unavailable',
      component: UnavailablePageComponent,
      roles: [],
      exact: true,
    }
  ];

  return (
      <ConfigProvider locale={providerLang}
            getPopupContainer={(node: any) => {
              if (node) {
              return node.parentNode;
              }
              return document.body;
              }}
          >
        <Router
          ref={(navigatorRef: any) =>
            NavigationService.setTopLevelNavigator(navigatorRef)
          }
        >
          <Switch>
            <Route exact={true} path={`/version`} key={-1}> <VersionComponent /> </Route>)
            {routes.map((route, index) => (<Route exact={route.exact} path={`/${route.path}`} key={index}> <route.component /> </Route>))}
            <Redirect to={ReservationsRoutes.RESERVATIONS.fullPath} />
          </Switch>
        </Router>
      </ConfigProvider>
  );
}

export default MainRouter;
