import { Button } from "antd";
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { StoreStateInterface} from "../../../common";
import { getLastEventsAction } from "../../../common/redux/event";

function LogPageComponent() {

  const dispatch = useDispatch();

  const events = useSelector((state: StoreStateInterface) => state.event.events);
  
  const numberOfEvents = 50;

  useEffect(() => {
    dispatch(getLastEventsAction(numberOfEvents));
  }, []);

  function getLastEventsClick() {
    dispatch(getLastEventsAction(numberOfEvents));
  }

  const renderEventsList = (): React.ReactNode => {
    return events && events.map((event: any, index: number) => {
        return (
            <div className="mb8" key={index}>
                {/* {JSON.stringify(event)} */}
                <strong>{event.id}:</strong> {event.eventJson} - {event.eventTimestamp}  <br /> {event.outgoingEventsJson}
            </div>
        )
    });
  }
  
  const renderTitleAndActions = () : React.ReactNode => {
      return (
        <div className="flex-row mb50">
          <h1 className="item heading--no-margin">Log: Posljednjih {numberOfEvents} eventova.</h1>
          <Button type="primary" className="item" onClick={() => getLastEventsClick()}>REFRESH</Button>
        </div>
      );
  }

  return (
    <React.Fragment>
      <div className="logPage">
        {renderTitleAndActions()}
        {renderEventsList()}
      </div>
    </React.Fragment>
  );

}

export default LogPageComponent;
