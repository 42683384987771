import React, { useEffect, useState } from 'react';
import { Modal, Button, Table, Select, Checkbox, Badge } from 'antd';
import Title from "antd/lib/typography/Title";
import { i18n } from "../../services";
import { addUserToOccupationAction, getUsersAction, markOccupationAsBilledAction, removeUserFromOccupationAction, StoreStateInterface } from "../../redux";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ColorConstant, DisplayDateFormat, getOccupationStatusBadgeAndLabel, OccupationStatusConstant, ReservationStatusConstant, TimeNoSecFormat } from '../../constants';
import { GameInterface, GameTankInterface } from '../../interfaces';
import TankLogo from "../../../assets/images/logo_tank.svg";
import { FaPen } from 'react-icons/fa';
import { Subject, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

export default function ReservationModalComponent(props: {visible: boolean, closeHandler: any}) {

    const adminReservation = useSelector((state: StoreStateInterface) => state.reservation.adminReservation);

    const users = useSelector((state: StoreStateInterface) => state.user.users);

    const [occupationsBulk, setOccupationsBulk] = useState<Array<GameOccupationInterface>>([]);

    const debouncer: Subject<string> = new Subject<string>();

    useEffect(() => {
        const debounced = debouncer.pipe(debounce(() => interval(500)));
        const subscription = debounced.subscribe((value) => getUsers(value));
        return () => subscription.unsubscribe();
    }, [debouncer]); 

    const dispatch = useDispatch();

    interface TableTankInterface {
        tank: GameTankInterface;
        game: GameInterface;
    }
    
    const tanks: Array<TableTankInterface> = [];

    interface GameOccupationInterface {
        occupationId: number,
        gameId: number
    }
    
    adminReservation && adminReservation.game.forEach((game: GameInterface) => {
        game.tanks.forEach((tank: GameTankInterface) => {
            tanks.push({tank, game});
            tanks.sort(function(t1: TableTankInterface, t2: TableTankInterface) {
                if(t1.game.from === t2.game.from) {
                    return t1.tank.position - t2.tank.position;
                } 
                return moment(t1.game.from).isBefore(moment(t2.game.from)) ? -1 : 1; 
            });
        })
    })

    function addUserToOccupation(occupationId: number, email: string, playerNumber: number, gameId: number, reservationNumber: number) {
        dispatch(addUserToOccupationAction(occupationId, email, playerNumber, gameId, reservationNumber));
    }

    function onCheckboxChange(occupationId: number, gameId: number, checked: boolean) {
        let occupations: Array<GameOccupationInterface> = [];
        if (checked) {
            occupations = occupationsBulk;
            occupations.push({occupationId, gameId});
        } else {
            occupations = occupationsBulk.filter((o: GameOccupationInterface) => o.occupationId !== occupationId);
        }
        setOccupationsBulk(occupations);
    }

    function removeUserFromOccupationClick(occupationId: number, playerNumber: number, gameId: number, reservationNumber: number) {
        dispatch(removeUserFromOccupationAction(occupationId, playerNumber, gameId, reservationNumber));
    }

    function onSelectInputChanged(value: string): void {
        debouncer.next(value);
    }

    function getUsers(nicknamePart: string) {
        if(nicknamePart.length > 0) dispatch(getUsersAction(nicknamePart));
    }

    const getTableColumns: any = [
        {
            title: '',
            key: 'tank.occupation.occupationId',
            dataIndex: 'tank.occupation.occupationId',
            render: (dataIndex: string, t: TableTankInterface) => t.tank.occupation.status && t.tank.occupation.status !== 3 && t.tank.occupation.playerOneName && t.tank.occupation.playerTwoName && <Checkbox onChange={(checkedValue) => onCheckboxChange(t.tank.occupation.occupationId, t.game.gameId, checkedValue.target.checked)}/>
        },
        {
            title: i18n.translate("admin.reservation.time"),
            key: 'time',
            dataIndex: '',
            render: (dataIndex: string, t: TableTankInterface) => moment(t.game.from).format(TimeNoSecFormat)
        },
        {
            title: <><img src={TankLogo} alt="tank" /> {i18n.translate("admin.reservation.tank")}</>,
            key: 'tankName',
            dataIndex: 'tankName',
            render: (dataIndex: string, t: TableTankInterface) => <><div>{`${t.tank.tankName}`}</div><div>{`TENK #${t.tank.position}`}</div></>
        },
        {
            title: i18n.translate("admin.reservation.playerOne"),
            key: 'playerOneName',
            dataIndex: 'playerOneName',
            render: (dataIndex: string, t: TableTankInterface) => renderPlayerOne(t.tank, t.game.gameId)
        },
        {
            title: i18n.translate("admin.reservation.playerTwo"),
            key: 'playerTwoName',
            dataIndex: 'playerTwoName',
            render: (dataIndex: string, t: TableTankInterface) => renderPlayerTwo(t.tank, t.game.gameId)
        },
        {
            title: i18n.translate("admin.reservation.price"),
            key: 'price',
            dataIndex: 'game.price',
            render: (dataIndex: string, t: TableTankInterface) =>  <div>{`${t.game.price} kn`}</div>
        },
        {
            title: i18n.translate("admin.reservation.status"),
            key: 'status',
            dataIndex: 'tank.occupation.status',
            render: (dataIndex: string, t: TableTankInterface) => {
                if(t.tank.occupation.status === OccupationStatusConstant().RESERVED.id && t.tank.occupation.webReservation!.status === ReservationStatusConstant().IN_PROGRESS.id) {
                    return <div><Badge className="statusBadge" color={ColorConstant.BLUE.hash} /> {i18n.translate("admin.game.inProgress")}</div>;
                }
                return t.tank.occupation.status && getOccupationStatusBadgeAndLabel(t.tank.occupation.status);
            }
        },

    ];

    const { Option } = Select;

    const players = users.map((user) => {
        return <Option value={user.email} key={user.id}>{user.nickname}</Option>
    });


    const renderReservationTable = (): React.ReactNode => {
        return <Table rowKey={(tank) => tank.tank.gameTankId} columns={getTableColumns} dataSource={tanks} pagination={false}/>
    }

    const renderPlayerOne = (tank: GameTankInterface, gameId: number): React.ReactNode => {
        if (tank.occupied && tank.occupation) {
            if (tank.occupation.playerOneName) {
                return <>{tank.occupation.playerOneName} {<FaPen style={{ cursor: 'pointer' }} className="ml8" size={'12px'} color={'#acacac'} onClick={() => removeUserFromOccupationClick(tank.occupation.occupationId, 1, gameId, adminReservation!.number)} />}</>; 
            } else {
                return (
                <Select 
                    showSearch
                    style={{ width: 200 }}
                    placeholder={i18n.translate("occupation.playerOnePlaceholder")}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value: string) => addUserToOccupation(tank.occupation.occupationId, value, 1, gameId, adminReservation!.number)}
                    onSearch={(value: string) => onSelectInputChanged(value)}	
                >
                    {players}
                </Select>
              )
            }
        } else {
            return '-';
        }
    }

    const renderPlayerTwo = (tank: GameTankInterface, gameId: number): React.ReactNode => {
        if (tank.occupied && tank.occupation) {
            if (tank.occupation.playerTwoName) { 
                return <>{tank.occupation.playerTwoName} {<FaPen style={{ cursor: 'pointer' }} className="ml8" size={'12px'} color={'#acacac'} onClick={() => removeUserFromOccupationClick(tank.occupation.occupationId, 2, gameId, adminReservation!.number)} />}</>; 
            } else {
                return (
                    <Select 
                        showSearch
                        style={{ width: 200 }}
                        placeholder={i18n.translate("occupation.playerTwoPlaceholder")}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value: string) => addUserToOccupation(tank.occupation.occupationId, value, 2, gameId, adminReservation!.number)}
                        onSearch={(value: string) => getUsers(value)}	
                    >
                        {players}
                    </Select>
                    )
            }
        } else {
            return '-';
        }
    }

    function onPayClick() {
        occupationsBulk.forEach((gameOccupation: GameOccupationInterface) => {
            dispatch(markOccupationAsBilledAction(gameOccupation.occupationId, adminReservation!.number, gameOccupation.gameId));
        })
    }

    const renderSubmitButton = (): React.ReactNode => {
        return <Button type="primary" className="mt16" onClick={() => onPayClick()}>{i18n.translate("admin.reservation.confirmPayment")}</Button>
    }
    
    if (!adminReservation) return null;
    return (
        <Modal 
          title=" " 
          onCancel={props.closeHandler} 
          visible={props.visible} 
          footer={null} 
          width="80%"
          >
            <Title level={2} className="heading__h2 heading--primary">{`${i18n.translate("admin.reservation.reservationOverview")} #${adminReservation.number}`}</Title>
            <Title level={2} className="heading__h2">{moment(adminReservation.game[0].from).format(DisplayDateFormat)}</Title>
            {renderReservationTable()}
            {renderSubmitButton()}
        </Modal>
    );
}
