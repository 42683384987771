import React from "react";


export default function UserRankComponent(props:any) {
    
	const {
        ranking,
    } = props;
    
    const icons = {
        "rank": "<svg width='32' height='41' viewBox='0 0 32 41' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M15.9026 41L0 32.9907V0H32V32.9907L15.9026 41ZM1.55813 32.062L15.9026 39.2837L30.4419 32.0601V1.52558H1.55813V32.062ZM19.8281 16.011L18.4161 11.6249L22.1945 8.88556L17.4939 8.84456L16 4.47567L14.5071 8.84074L9.80645 8.88174L13.5849 11.6211L12.1729 16.0072L16.001 13.3374L19.8281 16.011ZM15.8179 25.7919L25.3877 21.0349V16.277L15.8306 21.0273L6.6123 16.3847V21.1522L15.8179 25.7919ZM15.8306 28.906L6.6123 24.2634V29.0309L15.8189 33.6667L25.3887 28.9098V24.1519L15.8306 28.906Z' fill='currentColor'/></svg>",
    }

    return (
        <div className="user-rank">
            <div 
                    className="user-rank__svg"
                    dangerouslySetInnerHTML={{ __html: icons['rank'] }}
            >   
            </div>
            <div className="user-rank__content">
                <span className="user-rank__label">
                    Ranking
                </span>
                <span className="user-rank__value">
                    {ranking}
                </span>
            </div>
        </div>
    );
}