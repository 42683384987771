import { Table, Typography } from "antd";
import React, { useEffect } from "react";
import { MoreOutlined } from '@ant-design/icons';

// import './profileRankingPage.style.scss';
import { useDispatch, useSelector } from "react-redux";
import { UserInterface, i18n, StoreStateInterface, getUserAction, getUsersByRankAction } from "../../../common";

function ProfileRankingPageComponent() {

  const { Summary } = Table;

  const { Title, Text } = Typography;

  const userId = useSelector((state: StoreStateInterface) => state.auth.user?.id);

  const user = useSelector((state: StoreStateInterface) => state.user.user);

  const users = useSelector((state: StoreStateInterface) => state.user.usersByRank);

  const dispatch = useDispatch();

  useEffect(() => {
    if(userId) dispatch(getUserAction(userId));
  }, [userId]);

  useEffect(() => {
    dispatch(getUsersByRankAction(5));
  }, [user]);

  const getTableColumns: any = [
    {
      title: i18n.translate("user.rank"),
      key: 'rank',
      render: (user: UserInterface) => <Text strong>{user.rank ? '#' + user.rank : '-'}</Text>
    },
    {
      title: i18n.translate("user.nickname"),
      key: 'nickname',
      render: (user: UserInterface) => <div>{user.nickname}</div>
    },    
    {
      title: i18n.translate("user.points"),
      key: 'points',
      render: (user: UserInterface) => <Text strong>{user.points ? user.points : '-'}</Text>
    }
  ];

  const renderSummaryRow = (): React.ReactNode => {    
    if(!user) return null;
    if(user.rank && user.rank <= 5) return null;
    return (
      <>
        <Summary.Row>
          <Summary.Cell index={0} colSpan={3} align="center">
            <MoreOutlined style={{fontSize: '28px'}}/>
          </Summary.Cell>
        </Summary.Row>
        <Summary.Row>
          <Summary.Cell index={0} className="bold">{user.rank ? '#' + user.rank : '-'}</Summary.Cell>
          <Summary.Cell index={1} className="bold">{user.nickname}</Summary.Cell>
          <Summary.Cell index={2} className="bold">{user.points ? user.points : '-'}</Summary.Cell>
        </Summary.Row>
      </>
    )
  } 

  

  const renderRankTable = (): React.ReactNode => {
    return <Table columns={getTableColumns} dataSource={users} pagination={false} summary={() => renderSummaryRow()} />
  }  

  return (
    <React.Fragment>
      <div className="rankPage">
        <Title level={2}>{i18n.translate("user.rankList")}</Title>
        {renderRankTable()}
      </div>
    </React.Fragment>
  );

}

export default ProfileRankingPageComponent;
