import { Badge } from "antd";
import React from "react";
import { i18n } from "../services";
import { ColorConstant } from "./color.constant";

export const OccupationStatusConstant = () => { return {
    RESERVED: { id: 1, label: i18n.translate("admin.game.reserved"), color: ColorConstant.ORANGE.hash},
    OCCUPIED: { id: 2, label: i18n.translate("admin.game.occupied"), color: ColorConstant.ORANGE.hash},
    BILLED: { id: 3, label: i18n.translate("admin.game.billed"), color: ColorConstant.GREEN.hash},
    INVALID: { id: 4, label: i18n.translate("admin.game.invalid"), color: ColorConstant.RED.hash },
}};

export function getOccupationStatusById(id: number) {
    let element = Object.entries(OccupationStatusConstant()).find((e: any) => { return e[1].id === id });
    return element;
}

export function getOccupationStatusLabel(id: number) {
    let element = getOccupationStatusById(id);
    return element ? element[1].label : null;
  }
  
  export function getOccupationStatusBadge(id: number, ) {
    let element = getOccupationStatusById(id);
    return element ? <Badge className="statusBadge" color={element[1].color} /> : null;
  }
  
  export function getOccupationStatusBadgeAndLabel(id: number) {
    return <div>{getOccupationStatusBadge(id)} {getOccupationStatusLabel(id)}</div>
  }