import moment from "moment";
import React from "react";

export default function CopyrightComponent() {
    
    return (
        <div className="copyright footer__copyright">
            <span>
	            © {moment().year()} Ironbull All rights reserved. Powered by 
                <a href="https://bit4bytes.com" target="_blank" rel="noopener noreferrer"> Bit4bytes™</a> ©
    	    </span>
            <ul className="menu-copyright">
                <li className="menu-copyright__item">
                    <a href="https://ironbull-wp.onlyoneif.com/privacy-policy/" className="menu-copyright__link ">
                        <span className="menu-copyright__link-text">Privacy Policy</span>
                    </a>
                </li>
            </ul>
        </div>
    );
}