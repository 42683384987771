import React, { useState } from 'react';
import { Modal, Button, Table, Badge } from 'antd';
import { i18n } from "../../services";
import { deactivateOccupationAction, setGameTankStatusAction, StoreStateInterface, transferOccupationAction } from "../../redux";
import { useDispatch, useSelector } from 'react-redux';
import { ColorConstant, getOccupationStatusBadgeAndLabel, OccupationStatusConstant, ReservationStatusConstant } from '../../constants';
import { GameInterface, GameTankInterface } from '../../interfaces';
import TankLogo from "../../../assets/images/logo_tank.svg";
import { GameTankStatusConstant } from '../../constants/gameTankStatus.constant';

export default function EditPositionsModalComponent(props: {visible: boolean, closeHandler: any, gameId: number}) {

    const game = useSelector((state: StoreStateInterface) => state.game.games.find((game: GameInterface) => game.gameId === props.gameId));

    const [occupationFromId, setOccupationFromId] = useState<number>();

    const [isSwitchable, setIsSwitchable] = useState<boolean>(false);
    
    const [isDeleteable, setIsDeleteable] = useState<boolean>(false);

    const [isDisableable, setIsDisableable] = useState<boolean>(false);

    const dispatch = useDispatch();

    function cancelActions() {
        setIsDeleteable(false);
        setIsSwitchable(false);
        setIsDisableable(false);
        setOccupationFromId(undefined);
    }

    function transferOccupationClick(gameTankId: number) {
        dispatch(transferOccupationAction(occupationFromId!, gameTankId, game!.gameId));
        setIsSwitchable(false);
        setOccupationFromId(undefined);
    }

    function deactivateOccupationClick(occupationId: number) {
        dispatch(deactivateOccupationAction(occupationId, game!));
    }

    function setGameTankStatusClick(tank: GameTankInterface, status:string) {
        dispatch(setGameTankStatusAction(tank.gameTankId, game!.gameId, status));
    }

    function exitModalClick() {
        cancelActions();
        props.closeHandler();
    }

    const getTableColumns: any = [
        {
            title: '',
            key: 'tank.occupation.occupationId',
            dataIndex: 'tank.occupation.occupationId',
            render: (dataIndex: string, tank: GameTankInterface) => renderButton(tank)
        },
        {
            title: i18n.translate("admin.reservation.position"),
            key: 'position',
            dataIndex: 'position',
            render: (dataIndex: string, tank: GameTankInterface) => `#${tank.position}`
        },
        {
            title: <img src={TankLogo} alt="tank" />,
            key: 'tankType',
            dataIndex: 'tankName',
            render: (dataIndex: string, tank: GameTankInterface) => `${tank.tankName}`
        },
        {
            title: i18n.translate("admin.reservation.playerOne"),
            key: 'playerOneName',
            dataIndex: 'playerOneName',
            render: (dataIndex: string, tank: GameTankInterface) => renderPlayerOne(tank)
        },
        {
            title: i18n.translate("admin.reservation.playerTwo"),
            key: 'playerTwoName',
            dataIndex: 'playerTwoName',
            render: (dataIndex: string, tank: GameTankInterface) => renderPlayerTwo(tank)
        },
        {
            title: i18n.translate("admin.reservation.status"),
            key: 'status',
            dataIndex: 'tank.occupation.status',
            render: (dataIndex: string, tank: GameTankInterface) => renderOccupationStatus(tank)
        },

    ];

    const renderButton = (tank: GameTankInterface): React.ReactNode => {
        if(isSwitchable) {
            if(occupationFromId) { 
                if(!tank.occupied && tank.status === GameTankStatusConstant().ACTIVE.id) return <Button type="link" onClick={() => transferOccupationClick(tank.gameTankId)}>{i18n.translate("admin.game.switch")}</Button>;
            } else if(tank.occupied) {
                return <Button type="link" onClick={() => setOccupationFromId(tank.occupation.occupationId)}>{i18n.translate("admin.game.choose")}</Button>;
            }
        }
        if(isDeleteable) {
            if(tank.occupied) {
                return <Button type="link" onClick={() => deactivateOccupationClick(tank.occupation.occupationId)}>{i18n.translate("admin.game.delete")}</Button>;
            }
        }
        if(isDisableable) {
            if(!tank.occupied && tank.status === GameTankStatusConstant().ACTIVE.id) {
                return <Button type="link" onClick={() => setGameTankStatusClick(tank, "DISABLED")}>{i18n.translate("admin.game.disableTank")}</Button>;
            }
            if(tank.status === GameTankStatusConstant().NOT_ACTIVE.id || tank.status === GameTankStatusConstant().DISABLED.id) {
                return <Button type="link" onClick={() => setGameTankStatusClick(tank, "ACTIVE")}>{i18n.translate("admin.game.enableTank")}</Button>;
            }
        }
    }

    const renderOccupationStatus = (tank: GameTankInterface): React.ReactNode => {
        if(tank.occupation) {
            if(tank.occupation.status === OccupationStatusConstant().RESERVED.id && tank.occupation.webReservation!.status === ReservationStatusConstant().IN_PROGRESS.id) {
                return <div><Badge className="statusBadge" color={ColorConstant.BLUE.hash} /> {i18n.translate("admin.game.inProgress")}</div>;
            } 
            return <div>{tank.occupation.status && getOccupationStatusBadgeAndLabel(tank.occupation.status)}</div>;
        }
    }

    const renderActions = (): React.ReactNode => {
        return (
            <div className="flex-row mb25">
                {!isDeleteable && !isSwitchable && !isDisableable &&
                    <>
                        <Button type="primary" className="item" onClick={() => setIsSwitchable(true)}>{i18n.translate("admin.game.switch")}</Button>
                        <Button type="primary" className="item" onClick={() => setIsDeleteable(true)}>{i18n.translate("admin.game.delete")}</Button>
                        <Button type="primary" className="item" onClick={() => setIsDisableable(true)}>{i18n.translate("admin.game.changeStatus")}</Button>
                    </>
                }
                {(isSwitchable || isDeleteable || isDisableable) && <Button danger type="primary" className="item" onClick={() => cancelActions()}>{i18n.translate("admin.game.cancel")}</Button>}
            </div>
        )
    }

    const renderGameTable = (): React.ReactNode => {
        let tanks = game!.tanks.sort((t1: GameTankInterface, t2: GameTankInterface) => {
            return t1.position > t2.position ? 1 : -1;
        })
        return <Table rowKey={(tank) => tank.position} columns={getTableColumns} dataSource={tanks} pagination={false}/>
    }

    const renderPlayerOne = (tank: GameTankInterface): React.ReactNode => {
        if (tank.occupied && tank.occupation) {
            if (tank.occupation.playerOneName) {
                return tank.occupation.playerOneName; 
            } else {
                return '-';
            }
        } else {
            return '-';
        }
    }

    const renderPlayerTwo = (tank: GameTankInterface): React.ReactNode => {
        if (tank.occupied && tank.occupation) {
            if (tank.occupation.playerTwoName) {
                return tank.occupation.playerTwoName; 
            } else {
                return '-';
            }
        } else {
            return '-';
        }
    }

    const renderExitButton = (): React.ReactNode => {
        return <Button type="primary" className="mt16" onClick={exitModalClick}>{i18n.translate("admin.game.exit")}</Button>
    }
    
    return (
        <Modal 
          title=" " 
          onCancel={exitModalClick} 
          visible={props.visible} 
          footer={null} 
          width="80%"
          >
            {renderActions()}
            {renderGameTable()}
            {renderExitButton()}
        </Modal>
    );
}
