import React from 'react';
import { Modal, Button } from 'antd';
import Title from "antd/lib/typography/Title";
import { i18n, NavigationService } from "../..";
import { AuthRoutes } from '../../../features/auth/_router/auth.routes';

export default function SignInModalComponent(props: {visible: boolean, closeHandler: any}) {

  const container = document.getElementsByClassName("drawer-container")[0] as HTMLElement;

  return (
      <Modal 
        title=" " 
        onCancel={props.closeHandler} 
        visible={props.visible} 
        footer={null} 
        centered 
        getContainer={container} 
        // wrapClassName="position-absolute"
        maskStyle={{ position: 'absolute' }}
        >
          <Title level={3} className="heading__h3 heading--primary heading--no-margin text-center">{i18n.translate('signInWarning.title1').toUpperCase()}</Title>
          <Button type="default" size="large" block onClick={() => NavigationService.navigate(AuthRoutes.LOGIN.fullPath)} className="mt32 mb16">
            {i18n.translate("signInWarning.haveAccount").toUpperCase()}
          </Button>
          <Button type="default" size="large" block onClick={() => NavigationService.navigate(AuthRoutes.REGISTER.fullPath)}>
            {i18n.translate("signInWarning.goToRegister").toUpperCase()}
          </Button>
      </Modal>
  );
}
