import { GameConstants } from "./game.constants";
import { GameInterface, ReducerActionInterface } from "../../interfaces";

// ============ INTERFACE ============

export interface GameStateInterface {
  games: Array<GameInterface>;
}

// ============ INIT STATE ============

const initialState: GameStateInterface = {
  games: []
};

// ============ REDUCERS ============

const getGamesSuccess = (state: GameStateInterface, payload: Array<GameInterface>): GameStateInterface => {
  return {
    ...state,
    games: payload
  };
};

const refreshGame = (state: GameStateInterface, payload: GameInterface): GameStateInterface => {
  // let game = state.games.find((game: GameInterface) => game.gameId === payload.gameId);
  // game = payload;
  let games = state.games.map((game: GameInterface) => {
    if (game.gameId === payload.gameId) return payload;
    else return game
  })
  return {
    ...state,
    games: games
  };
};

const error = (state: GameStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};


// ============ EXPORTS ============

export const GameReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case GameConstants.GET_GAMES_SUCCESS: return getGamesSuccess(state, action.payload);
    case GameConstants.REFRESH_GAME: return refreshGame(state, action.payload);
    case GameConstants.ERROR: return error(state, action.payload);
    default:
      return state;
  }
};
