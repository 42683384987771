import React from "react";
import FooterContactComponent from "./footerContact/footerContact.component";
import FooterLinksComponent from "./footerLinks/footerLinks.component";
import { i18n } from "../../services";
import FooterSocialIconsComponent from "./footerSocialIcons/footerSocialIcons.component";
import CopyrightComponent from "../copyright/copyright.component";

export default function FooterComponent() {
    const playroomMenuItems = [
        {
            title: i18n.translate("footer.arena"),
            href: `${process.env.REACT_APP_WP_URL}/ironbull-arena-zagreb/`
        },
        {
            title: i18n.translate("footer.rules"),
            href: `${process.env.REACT_APP_WP_URL}/pravila-igre/`
        },
        {
            title: i18n.translate("footer.tanks"),
            href: `${process.env.REACT_APP_WP_URL}/tenkovi/`
        },
        {
            title: i18n.translate("footer.shop"),
            href: `${process.env.REACT_APP_WP_URL}/shop/`
        },
    ];
    
    const informationMenuItems = [
        {
            title: i18n.translate("footer.franchise"),
            href: `${process.env.REACT_APP_WP_URL}/fransiza/`
        },
        {
            title: i18n.translate("footer.catalog"),
            href: `${process.env.REACT_APP_WP_URL}/cjenik/`
        },
        {
            title: i18n.translate("footer.contact"),
            href: `${process.env.REACT_APP_WP_URL}/kontakt/`
        },
        {
            title: i18n.translate("footer.info"),
            href: `${process.env.REACT_APP_WP_URL}/o-nama/`
        },
    ];

    return (
        <footer className="footer">
            <div className="footer__wrapper">
                <FooterContactComponent />
                <FooterLinksComponent 
                    selectorClass="footer"
                    links={playroomMenuItems} 
                    title={i18n.translate("footer.playroom").toUpperCase()} 
                />
                <FooterLinksComponent 
                    selectorClass="footer"
                    links={informationMenuItems} 
                    title={i18n.translate("footer.information").toUpperCase()} 
                />
                <FooterSocialIconsComponent />
            </div>
            <CopyrightComponent />
        </footer>
    );
}