export const apiRoutes = {
    register: `auth/register`,
    login: `auth/login`,
    logout: `auth/logout`,
    games: `api/web/game-reservation/games`,
    gamesPublic: `api/public/games`,
    occupation: `api/web/occupation`,
    occupationLoc: `api/local/occupation`,
    reservation: `api/web/reservation`,
    users: `api/local/user`,
    user: `api/local/user`,
    userUpdate: `api/web/user_update/{userId}/update`,
    userStatus: `api/web/user_update/{userId}/status`,
    changePassword: `api/web/user_update/{userId}/password`,
    adminChangePassword: `api/web/user_update/{userId}/admin_password`,
    usersRank: `api/local/user/byRank`,
    userStats: `api/local/user/{userId}/stats`,
    userReservationsCurrent: `api/web/user/{userId}/reservations/currentReservations`,
    userReservationsPlayed: `api/web/user/{userId}/reservations/playedReservations`,
    userGamesPlayed: `api/web/user/{userId}/playedGames`,
    reservationByWebNumber: `api/local/game-reservation/byWebNumber`,
    gamesLocal: `api/local/games`,
    gameTank: `api/gametank/status`,
    tank: `api/tank`,

    gamesSystemProperties: `api/system_properties`,
    gamesSystemPropertiesChange: `api/system_properties/change/{type}`,
    gamesManagerState: `api/local/games/manager/state`,
    gamesScript: `api/game_type/1`,
    gamesScriptChange: `api/game_type/1/script`,
    testGame: `api/test_game`,
    lastEvents: `api/last_events`,
    tvSettings: `api/public/tv_info`,
    
    version: `version`,
}
