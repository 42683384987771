import React from "react";
import HeadingComponent from "../../heading/heading.component";
import LanguageSwitcherComponent from "../../languageSwitcher/languageSwitcher.component";
import LogoComponent from "../../logo/logo.component";


export default function FooterContactComponent() {
    return (
        <div className="footer-contact footer__footer-contact">
            <div className="footer-contact__logo">
                <LogoComponent href={process.env.REACT_APP_WP_URL} logoSrc={`${process.env.REACT_APP_WP_URL}/wp-content/themes/b4b/public/logo.svg`} logoTitle="IronBull" logoAlt="Ironbull" />
            </div>
            <div className="footer-contact__info-wrap">
                <address className="footer-contact__address" aria-label="Company address">
                    <HeadingComponent title="IRONBULL" headingLevel='h2' selectorClass="footer-contact" size="textlarge" />
                    <div className="footer-contact__item">
                        <span className="footer-contact__item-text">
                            <a href="https://maps.google.com/?q=Remetinečka%20cesta%20125A,%20Zagreb" className="footer-contact__link" target="_blank" rel="noopener noreferrer">
				                Remetinečka cesta 125A, Zagreb<br />
				                Zagreb, Croatia			
                            </a>
                        </span>
                    </div>
                    <div className="footer-contact__item">
                        <span className="footer-contact__item-text">
                            <a href="mailto:http://info@ironbull.hr" className="footer-contact__link">
				                info@ironbull.hr			
                            </a>
                        </span> 
                    </div>
                    <div className="footer-contact__item">
                        <span className="footer-contact__item-text">
                            <a href="tel:http://+385%2099%20411%208871" className="footer-contact__link">
				                +385 99 411 8871			
                            </a>
                        </span>
                    </div>
                </address>
                <LanguageSwitcherComponent />
            </div>
        </div>
    );
}