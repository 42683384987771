import React from "react";


export default function HamburgerComponent() {
  return(
    <button className="js-hamburger hamburger">
        <span className={`hamburger__wrap`}>
            <span className={`hamburger__line hamburger__line--1`}></span>
            <span className={`hamburger__line hamburger__line--2`}></span>
            <span className={`hamburger__line hamburger__line--3`}></span>
        </span>
    </button>
)};