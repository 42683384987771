import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Button, Form, Input, Select, Space, } from "antd";
import Title from "antd/lib/typography/Title";

import { UserInterface, i18n, NavigationService, StoreStateInterface, getUserAction, DefaultDateFormat, updateUserByAdminAction, NewPinModalComponent } from "../../../../common";
import { countries } from "../../../../assets/json/countries.json";
import { AdminRoutes } from "../../_router/admin.routes";

function UserPageComponent() {

  const [date, setDate] = useState<{
    day?: number;
    month?: number;
    year?: number;
    validateStatus?: ValidateStatus;
    errorMsg?: string | null;
  }>({});

  const params = useParams<{userId?: string}>();
  const userId = parseInt(params.userId!);

  const user = useSelector((state: StoreStateInterface) => state.user.user);

  const [editPin, setEditPin] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if(userId) dispatch(getUserAction(userId));
  }, [userId]);

  const { Option } = Select;  

  const yearsOptions = new Array(100).fill('').map((year, index) => {
    let current: number = moment().year() - index;
    return <Option value={current} key={current}>{current}</Option>
  });

  const monthsOptions = new Array(12).fill('').map((month, index) => {
    let current: number = index + 1;
    return <Option value={current} key={current}>{current}</Option>
  });

  const daysOptions = new Array(31).fill('').map((day, index) => {
    let current: number = index + 1;
    return <Option value={current} key={current}>{current}</Option>
  });

  const countriesOptions = countries.map((country) => {
    return <Option value={country.code} key={country.code}>{country.name}</Option>
  });

  type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus'];

  function validateDate(day: number, month: number, year: number): { validateStatus: ValidateStatus; errorMsg: string | null } {
    if (moment([year, month - 1, day]).isValid()) 
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    else 
      return {
        validateStatus: 'error',
        errorMsg: i18n.translate('validation.date'),
      };
  }

  function onDateChange(value: number, name: string): void {
    let currentDate = { ...date, [name]: value};
    if (currentDate.day && currentDate.month && currentDate.year)
      setDate({ ...currentDate, ...validateDate(currentDate.day, currentDate.month, currentDate.year) }); 
    else 
      setDate(date => ({ ...currentDate })); 
  };

  function onFinish(values: any): void {
    let avatarJsonData = {
      initials: user!.avatarJson ? user!.avatarJson.initials ? user!.avatarJson.initials : user!.nickname.substr(0,2) : user!.nickname.substr(0,2),
      background: user!.avatarJson ? user!.avatarJson.background ? user!.avatarJson.background : '#39402B' : '#39402B',
      color: user!.avatarJson ? user!.avatarJson.color ? user!.avatarJson.color : '#f56a00' : '#f56a00'
    };

    let model: UserInterface = {
      id: user!.id,
      name: values.fullname,
      email: values.mail,
      birthday: moment([parseInt(values.dateOfBirth.year), parseInt(values.dateOfBirth.month) - 1, parseInt(values.dateOfBirth.day)]).format(DefaultDateFormat),
      nickname: values.nickname, 
      newsletter: user!.newsletter,
      countryCode: values.country,
      avatarJson: avatarJsonData
    };

    dispatch(updateUserByAdminAction(model));
  }

  function onFinishFailed(errorInfo: any): void {
    console.log('Failed:', errorInfo);
  }

  function onCancel(): void {
    NavigationService.navigate(AdminRoutes.USERS.fullPath)
  }
    
  if(!user) return null;
  if(user.id !== userId) return null;
  return (
    <React.Fragment>
      <div className="usersPage">
        <Title level={2} className="heading__h1 heading--no-margin">{i18n.translate("buttons.edit")}</Title>
        <Form
          name="registerForm"
          onFinish={(values:any) => onFinish(values)}
          onFinishFailed={(error:any) => onFinishFailed(error)}
          layout="vertical"
          initialValues={{
            nickname: user?.nickname,
            fullname: user?.name,
            mail: user?.email,
            country: user?.country?.code,
            dateOfBirth: {
              day: moment(user?.birthday).date(),
              month: moment(user?.birthday).month() + 1,
              year: moment(user?.birthday).year()
            }
          }}
          >
          <Form.Item
              label={i18n.translate("user.nickname")}
              name="nickname"
              rules={[{ required: true, message: i18n.translate("validation.required.nickname") }]}
          >
              <Input size="large" style={{ width: 350 }} />
          </Form.Item>
          <Form.Item
              label={i18n.translate("user.fullname")}
              name="fullname"
              rules={[{ required: true, message: i18n.translate("validation.required.fullname") }]}
          >
              <Input size="large" style={{ width: 350 }}/>
          </Form.Item>
          <Form.Item
              label={i18n.translate("user.mail")}
              name="mail"
              rules={[{ required: true, message: i18n.translate("validation.required.mail") }]}
          >
              <Input size="large" style={{ width: 350 }} />
          </Form.Item>
          <Form.Item 
              label={i18n.translate("user.dateOfBirth")} 
              validateStatus={date.validateStatus}
              help={date.errorMsg}
          >
              <Input.Group size="large">
                  <Space size="middle">
                      <Form.Item
                          name={['dateOfBirth', 'day']}
                          noStyle
                          rules={[{ required: true, message: 'Day is required' }]}
                      >
                          <Select placeholder={i18n.translate("date.day").toLowerCase()} size="large" style={{ width: 100 }} onChange={(x: number) => onDateChange(x, 'day')} >
                              {daysOptions}
                          </Select>
                      </Form.Item>
                      <Form.Item
                          name={['dateOfBirth', 'month']}
                          noStyle
                          rules={[{ required: true, message: 'Month is required' }]}
                      >
                          <Select placeholder={i18n.translate("date.month").toLowerCase()} size="large" style={{ width: 100 }} onChange={(x: number) => onDateChange(x, 'month')} >
                              {monthsOptions}
                          </Select>
                      </Form.Item>
                      <Form.Item
                          name={['dateOfBirth', 'year']}
                          noStyle
                          rules={[{ required: true, message: 'Year is required' }]}
                      >
                          <Select placeholder={i18n.translate("date.year").toLowerCase()} size="large" style={{ width: 100 }} onChange={(x: number) => onDateChange(x, 'year')} >
                              {yearsOptions}
                          </Select>
                      </Form.Item>
                  </Space>
              </Input.Group>
          </Form.Item>
          <Form.Item
              label={i18n.translate("user.country")}
              name="country"
              rules={[{ required: true, message: i18n.translate("validation.required.country") }]}
          >
              <Select placeholder={i18n.translate("select.country").toLowerCase()} size="large" style={{ width: 300 }} >
                  {countriesOptions}
              </Select>
          </Form.Item>

          <Form.Item>
            <Space size="middle">
              <Button type="primary" htmlType="submit" size="large">
                {i18n.translate("buttons.save")}
              </Button>
              <Button size="large" onClick={() => onCancel()}>
                {i18n.translate("buttons.cancel")}
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <Button type="primary" size="large" onClick={() => setEditPin(true)}>
          {i18n.translate("changePin.title")}
        </Button>        
        <NewPinModalComponent visible={editPin} closeHandler={() => setEditPin(false)}></NewPinModalComponent> 
      </div> 
    </React.Fragment>
  );

}

export default UserPageComponent;
