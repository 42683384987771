import React from "react";
import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout } from "antd";

import { ContentSidebarComponent, DrawerComponent, FooterComponent, HeroComponent, i18n, PageOverlayComponent, PrimaryHeaderComponent, StoreStateInterface, UserRoleEnum } from "../../../common";

import { ProfileRoutes } from "./profile.routes";
import Components from '../components';
import { AuthRoutes } from "../../auth";

function ProfileRouter() {

  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const { path } = useRouteMatch();

  let routes = [
    {
      path: ProfileRoutes.STATISTICS.path,
      component: Components.ProfileStatisticsPage,
      roles: [UserRoleEnum.ADMIN, UserRoleEnum.USER],
      exact: true,
    },
    {
      path: ProfileRoutes.RESERVATIONS.path,
      component: Components.ProfileReservationsPage,
      roles: [UserRoleEnum.ADMIN, UserRoleEnum.USER],
      exact: true,
    },
    {
      path: ProfileRoutes.FRIENDS.path,
      component: Components.ProfileFriendsPage,
      roles: [UserRoleEnum.ADMIN, UserRoleEnum.USER],
      exact: true,
    },
    {
      path: ProfileRoutes.LAST_GAMES.path,
      component: Components.ProfileLastGamesPage,
      roles: [UserRoleEnum.ADMIN, UserRoleEnum.USER],
      exact: true,
    },
    {
      path: ProfileRoutes.RANKING.path,
      component: Components.ProfileRankingPage,
      roles: [UserRoleEnum.ADMIN, UserRoleEnum.USER],
      exact: true,
    },
    {
      path: ProfileRoutes.PERSONAL_DATA.path,
      component: Components.ProfilePersonalDataPage,
      roles: [UserRoleEnum.ADMIN, UserRoleEnum.USER],
      exact: true,
    },
    {
      path: ProfileRoutes.NOTIFICATIONS.path,
      component: Components.ProfileNotificationsPage,
      roles: [UserRoleEnum.ADMIN, UserRoleEnum.USER],
      exact: true,
    },
  ];

  let sidebarComponentLinks: Array<{ title: string, href: string }> = [
    {
      title: i18n.translate("userSidebar.myStatistics").toUpperCase(),
      href: ProfileRoutes.STATISTICS.fullPath,
    },
    {
      title: i18n.translate("userSidebar.myReservations").toUpperCase(),
      href: ProfileRoutes.RESERVATIONS.fullPath,
    },
    // {
    //     title: "PRIJATELJI ",
    //     href: ProfileRoutes.FRIENDS.fullPath,
    // },
    {
      title: i18n.translate("userSidebar.lastGames").toUpperCase(),
      href: ProfileRoutes.LAST_GAMES.fullPath,
    },
    {
      title: i18n.translate("userSidebar.rankingList").toUpperCase(),
      href: ProfileRoutes.RANKING.fullPath,
    },
    {
      title: i18n.translate("userSidebar.personalData").toUpperCase(),
      href: ProfileRoutes.PERSONAL_DATA.fullPath,
    },
    // {
    //     title: "NOTIFIKACIJE",
    //     href: ProfileRoutes.NOTIFICATIONS.fullPath,
    // },
  ];

  const allowedRoutes = routes.filter(r => {
    if (r.roles.length === 0) return true;
    if (!userAuth.isAuthenticated || !userAuth.user) return false;
    if (r.roles.includes(userAuth.user!.groups![0].id)) return true;

    return false;
  });

  if (allowedRoutes.length === 0) {
    return <Redirect to={AuthRoutes.LOGIN.fullPath} />;
  }

  return (
    <Layout className="layout">
      <PrimaryHeaderComponent></PrimaryHeaderComponent>
      <DrawerComponent />
      <PageOverlayComponent />
      <HeroComponent />
      <ContentSidebarComponent links={sidebarComponentLinks}>
        <Switch>
          {allowedRoutes.map((route, index) => (<Route exact={route.exact} path={`${path}/${route.path}`} key={index}> <route.component /> </Route>))}
          <Redirect to={ProfileRoutes.PERSONAL_DATA.fullPath} />
        </Switch>
      </ContentSidebarComponent>
      <FooterComponent />
    </Layout>
  );
}

export default ProfileRouter;
