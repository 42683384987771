import React from "react";
import SocialLinksComponent from "../../socialLinks/socialLinks.component";

export default function FooterSocialIconsComponent() {
    
    return (
        <div className="footer-social-icons footer__footer-social-icons">
            <SocialLinksComponent />
        </div>
    );
}