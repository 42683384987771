import React from "react";
import MenuComponent from "../menu/Menu.component";
import { Button } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { logoutAction, i18n, StoreStateInterface, deleteOccupationAction } from "../../../common";


export default function ContentSidebarComponent(props: {children: React.ReactNode, links: Array<{title: string, href: string}>}) {
    const {
        children,
        links
    } = props;

    const dispatch = useDispatch();

    const reservation = useSelector((state: StoreStateInterface) => state.reservation);

    function logout(): void {
        reservation.occupations.forEach(occupation => {
            dispatch(deleteOccupationAction(occupation.occupationId, occupation.gameTank!.game!));
        });
        dispatch(logoutAction());
    }

    return (
        <main className="content-sidebar">
           <div className="content-sidebar__wrapper">
                <div className="content-sidebar__menu">
                    <MenuComponent 
                        componentClass="menu-sidebar"
                        links={links}
                    />
                    <Button type="link" className="logout" onClick={() => logout()}>{i18n.translate("login.logout")}</Button>
                </div>
                <div className="content-sidebar__content">
                    {children}
                </div>
           </div>
        </main>
    );
}