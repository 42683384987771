import { AuthConstants } from "./auth.constants";
import { LoginResponseInterface, ReducerActionInterface, UserInterface } from "../../interfaces";

// ============ INTERFACE ============

export interface AuthStateInterface {
  isAuthenticated: boolean;
  lang: 'hr' | 'en';
  accessToken?: string;
  refreshToken?: string;
  tokenType?: string;
  user?: UserInterface;
  error?: string;
}

// ============ INIT STATE ============

const initialState: AuthStateInterface = {
  lang: 'hr',
  isAuthenticated: false,
};

// ============ REDUCERS ============

const loginSuccess = (state: AuthStateInterface, payload: LoginResponseInterface): AuthStateInterface => {
  return {
    ...state,
    isAuthenticated: true,
    accessToken: payload.accessToken,
    refreshToken: payload.refreshToken,
    tokenType: payload.tokenType,
    user: payload.user,    
  };
};

const logout = (state: AuthStateInterface) => {
  return {
    ...state,
    isAuthenticated: false,
    accessToken: undefined,
    refreshToken: undefined,
    tokenType: undefined,
    user: undefined,
  };
};

const registerSuccess = (state: AuthStateInterface) => {
  return {
    ...state,
  };
};

const error = (state: AuthStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

const setLang = (state: AuthStateInterface, payload: 'hr' | 'en'): AuthStateInterface => {
  return {
    ...state,
    lang: payload
  };
};


// ============ EXPORTS ============

export const AuthReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case AuthConstants.REGISTER_SUCCESS: return registerSuccess(state);
    case AuthConstants.LOGIN_SUCCESS: return loginSuccess(state, action.payload);
    case AuthConstants.LOGOUT: return logout(state);
    case AuthConstants.ERROR: return error(state, action.payload);
    case AuthConstants.SET_LANG: return setLang(state, action.payload);
    default:
      return state;
  }
};
