import React from "react";
import { LoginComponent } from "../../../common";

function LoginPageComponent() {

  return (
    <div className="center-center w100-h100">
        <LoginComponent></LoginComponent>
    </div>
  );


}

export default LoginPageComponent;
