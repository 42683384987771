export enum UserConstants {
  GET_USERS_SUCCESS = "GET_USERS_SUCCESS",
  GET_USERS_BY_NICKNAME_SUCCESS = "GET_USERS_BY_NICKNAME_SUCCESS",
  GET_USER_SUCCESS = "GET_USER_SUCCESS",
  GET_USERS_BY_RANK_SUCCESS = "GET_USERS_BY_RANK_SUCCESS",
  GET_USER_STATS_SUCCESS = "GET_USER_STATS_SUCCESS",
  GET_USER_RESERVATIONS_CURRENT_SUCCESS = "GET_USER_RESERVATIONS_CURRENT_SUCCESS",
  GET_USER_RESERVATIONS_PLAYED_SUCCESS = "GET_USER_RESERVATIONS_PLAYED_SUCCESS",
  GET_USER_GAMES_PLAYED_SUCCESS = "GET_USER_GAMES_PLAYED_SUCCESS",
  UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
  CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS",
  ERROR = "ERROR",
};

