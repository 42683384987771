import React from 'react';
import { Modal, Button, Form, Input } from 'antd';
import { i18n } from "../../services";
import { StoreStateInterface, changeUserPasswordAction } from "../../redux";
import { useDispatch, useSelector } from 'react-redux';

export default function ChangePasswordModalComponent(props: {visible: boolean, closeHandler: any}) {

    const userId = useSelector((state: StoreStateInterface) => state.auth.user!.id);

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    function onFormFinish(values: any): void {
        let model: any = {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword   
        };
        dispatch(changeUserPasswordAction(userId, model));
        form.resetFields();
        props.closeHandler();
    }

    function onClose(): void {
        form.resetFields();
        props.closeHandler();
    }
    
    return (
        <Modal 
          title={i18n.translate("changePin.title")} 
          onCancel={() => onClose()} 
          visible={props.visible} 
          footer={null} 
          width="120"
          centered
          >
            <Form
                layout="vertical"
                form={form}
                name="passwordChange"
                onFinish={(values: any) => onFormFinish(values)}
                >
               <Form.Item
                    label={i18n.translate("changePin.oldPassword")}
                    name="oldPassword"
                    rules={[{ required: true, message: i18n.translate("validation.required.password") }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label={i18n.translate("changePin.newPassword")}
                    name="newPassword"
                    rules={[{ required: true, message: i18n.translate("validation.required.password") }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label={i18n.translate("changePin.confirmPassword")}
                    name="confirm"                    
                    dependencies={['newPassword']}                    
                    rules={[
                    { required: true, message: i18n.translate("validation.required.password") },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error(i18n.translate("changePin.validationNotMatch")));
                        },
                    }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {i18n.translate("buttons.save").toUpperCase()}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}
