import React, { useEffect, useState } from 'react';
import { SystemService } from '../../api/system.service';

export default function VersionComponent() {

    const [version, setVersion] = useState<any>({});
    useEffect(() => {
        SystemService.getVersion().subscribe(x => setVersion(x));
    }, []);

    return (
        <div style={{padding: "16px"}}>
            <pre>
                <b>Frontend</b> <br/>
                Version: {process.env.REACT_APP_VERSION_NO}<br/>
                Date: {process.env.REACT_APP_VERSION_DATE}<br/>
                Is DEV: {process.env.REACT_APP_VERSION_IS_DEV}
            </pre>
            <hr />
            <pre>
                <b>Backend</b> <br/>
                Version: {version.version}<br/>
                Date: {version.date}<br/>
                Is DEV: {version.is_dev}
            </pre>
            <hr />
            <pre>
                <b>User browser</b> <br/>
                Browser CodeName: {navigator && navigator.appCodeName}<br/>
                Browser Name: {navigator && navigator.appName}<br/>
                Browser Version: {navigator && navigator.appVersion}<br/>
                Cookies Enabled: {navigator && navigator.cookieEnabled ? "YES" : "NO"}<br/>
                Platform: {navigator && navigator.platform}<br/>
                User-agent header: {navigator && navigator.userAgent}<br/>
            </pre>
            <hr />
        </div>
    );
}
