import { Button, DatePicker, Divider, Drawer, Modal, notification } from "antd";
import moment, { Moment } from "moment";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { ColorConstant, DefaultDateFormat, DisplayDateFormat, GameStatusConstant, getGamesByDayAction, getTeamLabelById, i18n, NavigationService, ReservationsIntroComponent, SignInModalComponent, StoreStateInterface, TeamConstant, TimeNoSecFormat } from "../../../common";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import './reservationsPage.style.scss';
import { useDispatch, useSelector } from "react-redux";
import { IoTime } from "react-icons/io5";
import { GameInterface, GameTankInterface, OccupationInterface } from "../../../common/interfaces";
import TankLogo from "../../../assets/images/logo_tank.svg";
import TankLogoBlack from "../../../assets/images/logo_tank_black.svg";
import TankLogoDisabled from "../../../assets/images/logo_tank_disabled.svg";
import Title from "antd/lib/typography/Title";
import { checkOccupationValidationAction, createUserOccupationAction, deleteOccupationAction } from "../../../common/redux";
import { ReservationsRoutes } from "../_router/reservations.routes";

function ReservationsPageComponent() {

  const [date, setDate] = useState<Moment>(moment());

  const [selectedGame, setSelectedGame] = useState<GameInterface>();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [isReservationErrorModalVisible, setIsReservationErrorModalVisible] = useState<boolean>(false);

  const [isMaxReservationModalVisible, setIsMaxReservationModalVisible] = useState<boolean>(false);

  const [timeLeft, setTimeLeft] = useState<number>(0);

  const games = useSelector((state: StoreStateInterface) => state.game.games);
  const reservation = useSelector((state: StoreStateInterface) => state.reservation);
  const isAuthenticated = useSelector((state: StoreStateInterface) => state.auth.isAuthenticated);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedGame) setSelectedGame(games.find((g) => g.gameId === selectedGame.gameId));
  }, [games]);

  useEffect(() => {
    dispatch(getGamesByDayAction(date.format(DefaultDateFormat)));
    dispatch(checkOccupationValidationAction());
  }, [date]);

  useEffect(() => {
    setTimeLeft(calculateTimeLeft());
  }, [reservation]);

  useEffect(() => {
    if(timeLeft < 1000*60 && timeLeft > 0){
      openNotification();
    }else{
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer);
    }    
  }, [timeLeft]);

  useEffect(() => {
    if(reservation.occupations[0]) setDate(moment(reservation.occupations[0].gameTank!.game!.from));
  }, []);

  useLayoutEffect(() => {
    let drawerSelector = 'game';
    let drawerElement = document.getElementsByClassName(drawerSelector)[0] as HTMLElement;
    if (drawerElement) {
      let el = drawerElement;
      let drawerHeight = el.offsetHeight + 80;
      let drawerContainer = document.getElementsByClassName('drawer-container')[0] as HTMLElement;
      drawerContainer.style.minHeight = drawerHeight + 'px';
    }   
  });

  function calculateTimeLeft(): number {
    let timeLeft = 0;
    if(reservation.occupations.length){
      let validTo = reservation.occupations[reservation.occupations.length - 1].validTo;
      let difference = +new Date(validTo) - +new Date();

      timeLeft = difference > 0 ? difference : 0;
    }   
    return timeLeft;
  }

  function openNotification(): void {
    notification.open({
      message: i18n.translate('reservations.expirationMsg'),
      description: i18n.translate('reservations.expirationDesc'),
      duration: 10
    });
  }

  function selectGame(game: GameInterface): void {
    if (isAuthenticated) {
      if (moment(game.from).isAfter(moment()) && game.status !== GameStatusConstant().RESERVED_BY_ADMIN.id && game.status !== GameStatusConstant().NOT_ACTIVE.id) {
        setSelectedGame(games.find((g) => g.gameId === game.gameId));
      }
    }else{
      showModal();
    }    
  }

  function removeSelectedGame(): void {
    setSelectedGame(undefined);
  }

  function createOccupationClick(gameTank: GameTankInterface) {
    if(reservation.occupations.length && !moment(selectedGame!.from).isSame(reservation.occupations[0].gameTank?.game?.from, 'day')) { 
      setIsReservationErrorModalVisible(true);
    } else if(reservation.occupations.filter((o: OccupationInterface) => o.gameTank!.game!.gameId === selectedGame!.gameId).length >= reservation.TANK_LIMIT_PER_TERM) {
      setIsMaxReservationModalVisible(true);
    } else {
      dispatch(createUserOccupationAction(gameTank, selectedGame!));

    }
  }

  function deleteOccupationClick(gameTank: GameTankInterface) {
    let occupationId = reservation.occupations.find((o: OccupationInterface) => o.gameTank!.gameTankId === gameTank.gameTankId)?.occupationId;
    if (occupationId) dispatch(deleteOccupationAction(occupationId, selectedGame!));
  }

  function showModal(): void{
    setIsModalVisible(true);
  };

  function hideModal(): void{
    setIsModalVisible(false);
  };

  function dateChange(value: Moment): void {
    if(value < moment().add(29, 'days') && value > moment().startOf('day')) {
      setDate(value);
      removeSelectedGame();
    }
  }

  function deleteReservation(): void {
    reservation.occupations.forEach(occupation => {
      dispatch(deleteOccupationAction(occupation.occupationId, occupation.gameTank!.game!));
    });
    setIsReservationErrorModalVisible(false);
  }

  const renderMaxReservationModal = (): React.ReactNode => {
    const container = document.getElementsByClassName("drawer-container")[0] as HTMLElement;
    return (
      <Modal
          title=" "
          onCancel={() => setIsMaxReservationModalVisible(false)}
          visible={isMaxReservationModalVisible} 
          footer={null}
          centered
          getContainer={container}
          // wrapClassName="position-absolute"
          maskStyle={{ position: 'absolute' }}
        >
          <p className="text-center bold no-margin">{i18n.translate('reservations.maxReservationModal.title1').toUpperCase()}</p>
          <p className="text-center bold mb50">{i18n.translate('reservations.maxReservationModal.title2')}</p>
          <div className="center-center">
            <Button type="primary" block onClick={() => setIsMaxReservationModalVisible(false)}>
              {i18n.translate("reservations.maxReservationModal.confirm").toUpperCase()}
            </Button>  
          </div>
        </Modal>
    )
  }

  const renderReservationErrorModal = (): React.ReactNode => {
    const container = document.getElementsByClassName("drawer-container")[0] as HTMLElement;
    return (
      <Modal
          title=" "
          onCancel={() => setIsReservationErrorModalVisible(false)}
          visible={isReservationErrorModalVisible} 
          footer={null}
          centered
          getContainer={container}
          // wrapClassName="position-absolute"
          maskStyle={{ position: 'absolute' }}
        >
          <p className="text-center bold no-margin">{i18n.translate('reservations.reservationErrorModal.title1').toUpperCase()}</p>
          <p className="text-center bold mb50">{i18n.translate('reservations.reservationErrorModal.title2')}</p>
          <div className="reservationErrorModal__buttons">
            <Button ghost className="left-button" block onClick={() => deleteReservation()}>
              {i18n.translate("reservations.reservationErrorModal.deleteReservation").toUpperCase()}
            </Button>  
            <Button type="primary" block onClick={() => setIsReservationErrorModalVisible(false)}>
              {i18n.translate("reservations.reservationErrorModal.continueReservation").toUpperCase()}
            </Button>
          </div>
        </Modal>
    )
  }

  function disabledDate(current: Moment) {
    return current > moment().add(29, 'days') || current < moment().startOf('day');    
  }

  const renderDatePicker = (): React.ReactNode => {
    return (
      <div className="picker">
        <Button type="link" icon={<FaChevronLeft size={'30px'} color={'#acacac'}/>} onClick={() => dateChange(moment(date).subtract(1, 'days'))} />
        <DatePicker
          bordered={false}
          suffixIcon={''}
          value={date}
          format={DisplayDateFormat}
          allowClear={false}
          onChange={(value: Moment | null) => {if (value) dateChange(value)}}
          className="ant-picker-largest"
          disabledDate={disabledDate}
        ></DatePicker>
        <Button type="link" icon={<FaChevronRight size={'30px'} color={'#acacac'}/>} onClick={() => dateChange(moment(date).add(1, 'days'))} />
      </div>
    )
  }

  const renderGamesGrid = (): React.ReactNode => {
    return (
      <div className="games-grid">
        {games && games
          .sort((g1: GameInterface, g2: GameInterface) => {
            return moment(g1.from).isBefore(moment(g2.from)) ? -1 : 1;
          })
          .map((game: GameInterface, index: number) => {
            return renderGameCard(game, index);
          })}        
      </div>
    )
  }  

  const renderGameCard = (game: GameInterface, index: number): React.ReactNode => {
    let gameCardObject: {class: string, label: string, status: React.ReactNode, labelMyTanks?: string, statusMyTanks?: React.ReactNode, iconColor?: string} = {
      class: '',
      label: i18n.translate("game.occupiedTanks").toUpperCase(),
      status: <><img src={TankLogo} alt="tank" /> {(game.numOfTanksOccupied.toString() + '/' + game.numOfGameTanks.toString())} </>,
    }

    if (moment(game.from).isBefore(moment()) || game.status === GameStatusConstant().RESERVED_BY_ADMIN.id || game.status === GameStatusConstant().NOT_ACTIVE.id) {
      gameCardObject = {
        class: 'finished',
        label: '--',
        status: <img src={TankLogo} alt="tank" />,
      };
    } else if(reservation.occupations.find((o: OccupationInterface) => o.gameTank!.game!.gameId === game.gameId)) {
      gameCardObject = {
        class: 'occupied', 
        label: i18n.translate("game.occupiedTanks").toUpperCase(),
        status: <><img src={TankLogoBlack} alt="tank" /> {(game.numOfTanksOccupied.toString() + '/' + game.numOfGameTanks.toString())} </>,
        labelMyTanks: i18n.translate("game.myTanks").toUpperCase(),
        statusMyTanks: <><img src={TankLogoBlack} alt="tank" /> {reservation.occupations.filter((o: OccupationInterface) => o.gameTank!.game!.gameId === game.gameId).length} </>,
        iconColor: ColorConstant.BLACK.hash
      };
    } else if (selectedGame?.gameId === game.gameId) {
      gameCardObject = {
        class: 'selected', 
        label: i18n.translate("game.occupiedTanks").toUpperCase(),
        status: <><img src={TankLogo} alt="tank" /> {(game.numOfTanksOccupied.toString() + '/' + game.numOfGameTanks.toString())}</>,
      };
    }
  
    return (
      <div className={`gameCard ${gameCardObject.class}`} key={index} onClick={() => selectGame(game)}>
        <IoTime color={gameCardObject.iconColor} />
        <div className="time">{moment(game.from).format(TimeNoSecFormat)}</div> 
        <div className="myTanks">
          <div className="label">{gameCardObject.labelMyTanks}</div>
          <div className="status">{gameCardObject.statusMyTanks}</div>
        </div>
        <div className="occupiedTanks">
          <div className="label">{gameCardObject.label}</div>
          <div className="status">{gameCardObject.status}</div>
        </div>
      </div>
    )
  }

  const renderFinishReservationButton = (large: boolean): React.ReactNode => {
    if (reservation.occupations.length > 0) 
      return (
        <div className={large ? "" : "mt25 flex-row center"}>
          <Button className="finish-button" type="primary" size={large ? "large" : "middle"} onClick={() => NavigationService.navigate(ReservationsRoutes.RESERVATION_REVIEW.fullPath)} >{i18n.translate("reservations.finishReservation").toUpperCase()}</Button>
        </div>
      )
    else return null;
  }

  const renderDrawer = (): React.ReactNode => {
    return (
      <Drawer
          title={i18n.translate("game.chooseTeamAndTank").toUpperCase()}
          placement="right"
          closable={true}
          onClose={() => removeSelectedGame()}
          visible={!!selectedGame}
          getContainer={false}
          style={{ position: 'absolute' }}
          width={window.innerWidth > 992 ? 800 : window.innerWidth}
          mask={false}
          className='game-drawer'
          keyboard={true}
        >
          {renderGame()}
      </Drawer>
    )
  }

  const renderGame = (): React.ReactNode => {
    if (selectedGame)
    return (
      <div className="game">
        <div className="time-title">
          {`${moment(selectedGame.from).format(TimeNoSecFormat)} h`}
        </div>
        <div className="tanks-grid">            
          {renderTanks(TeamConstant().AXIS.id)}
          {renderTanks(TeamConstant().ALLIES.id)}
        </div>
        <Divider />
        {renderSummary()}
        {renderFinishReservationButton(false)}
      </div>
    )
  }

  const renderTanks = (teamId: number): React.ReactNode => {
    if (selectedGame)
    return (
      <div>
        <Title level={3} className="heading__h3 heading--no-margin heading--primary bold">{getTeamLabelById(teamId)?.toUpperCase()}</Title>
        <div className="tanks">
          {selectedGame.tanks
            .filter((tank: GameTankInterface) => tank.tankType.team.id === teamId)
            .sort((t1: GameTankInterface, t2: GameTankInterface) => {
              return t1.position > t2.position ? 1 : -1;
            })
            .map((tank: GameTankInterface) => {
              return renderTank(tank);
            })
          }
        </div>
      </div>
    )
  }

  const renderTank = (tank: GameTankInterface): React.ReactNode => {
    
    const getTankAction = (): React.ReactNode => {
      if (!!reservation.occupations.find((occupation: OccupationInterface) => occupation.gameTank && occupation.gameTank.gameTankId === tank.gameTankId)) {
        return (
          <Button type="primary" block onClick={() => {deleteOccupationClick(tank)}} >{i18n.translate("game.iWantThisTank")}</Button>
        )
      } else {
        if (tank.occupied) {
          return (
            <div>
              {i18n.translate("game.reserved").toUpperCase()}
            </div>
          )
        } else {
          return (
            <Button className={tank.status ? "" : "tank-disabled"}  ghost block onClick={() => {createOccupationClick(tank)}}>
              {tank.status ? i18n.translate("game.iWantThisTank") : i18n.translate("game.unavailable")}
            </Button>
          )
        }
      } 
      
    }

    let tankLogo = tank.occupied ? TankLogoDisabled : TankLogo;

    return (
      <div className={`item ${tank.occupied ? "disabled" : ""}`} key={tank.gameTankId}>
        <div className="number">{`#${tank.position}`}</div>
        <div className="name">{tank.tankName}</div>
        <div className="icon"><img src={tankLogo} alt="tank" /></div>
        <div className="action">{getTankAction()}</div>
      </div>
    )
  }

  const renderSummary = (): React.ReactNode => {
    return (
      <div className="summary">
        <Title level={3} className="heading__h3 heading--primary bold text-center">{i18n.translate("summary.myGames").toUpperCase()}</Title>
        {reservation.occupations.length === 0 && 
          <div>{i18n.translate("summary.noGames").toUpperCase()}</div>
        }
        {reservation.occupations.map((occupation: OccupationInterface) => {
          return (
            <div className="item" key={occupation.occupationId}>
              <div className="date">{moment(occupation.gameTank?.game?.from).format(DisplayDateFormat)}</div>
              <div className="time">{moment(occupation.gameTank?.game?.from).format(TimeNoSecFormat)}</div>
              <div className="tank">{`${i18n.translate("tank.tank").toUpperCase()} #${occupation.gameTank?.position} / ${occupation.gameTank?.tankName}`}</div>
            </div>
          )
        })}
      </div>
    )
  }

  const renderSignInModal = (): React.ReactNode => {       
    return (
      <SignInModalComponent visible={isModalVisible} closeHandler={() => hideModal()}></SignInModalComponent>
    )    
  }

  return (
    <React.Fragment>
      <ReservationsIntroComponent></ReservationsIntroComponent>
      <div className="reservationsPage">
        <div className="drawer-container">
          <div className="site-container"> 
            {renderDatePicker()}
            {renderGamesGrid()}
            {renderFinishReservationButton(true)}
          </div>
          {renderDrawer()}
          {renderSignInModal()} 
          {renderReservationErrorModal()}
          {renderMaxReservationModal()}
        </div>      
      </div>
    </React.Fragment>
  );

}

export default ReservationsPageComponent;
