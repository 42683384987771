import { Badge } from "antd";
import React from "react";
import { i18n } from "../services";
import { ColorConstant } from "./color.constant";

export const GameTankStatusConstant = () => { return {
  NOT_ACTIVE: { id: 0, name: "not active", label: i18n.translate("admin.game.inactive").toUpperCase(), color: ColorConstant.RED.hash},
  ACTIVE: { id: 1, name: "active", label: i18n.translate("admin.game.active").toUpperCase(), color: 'transparent'},
  DISABLED: { id: 2, name: "disabled", label: i18n.translate("admin.game.disabled").toUpperCase(),  color: ColorConstant.RED.hash},
}};

export function getGameTankStatusById(id: number) {
  let element = Object.entries(GameTankStatusConstant()).find((e: any) => { return e[1].id === id });
  return element;
}

export function getGameTankStatusLabel(id: number) {
  let element = getGameTankStatusById(id);
  return element ? element[1].label : null;
}
  
export function getGameTankStatusBadge(id: number) {
  let element = getGameTankStatusById(id);
  return element ? <Badge className="statusBadge" color={element[1].color} /> : null;
}
  
export function getGameTankStatusBadgeAndLabel(id: number) {
  return <div>{getGameTankStatusBadge(id)} {getGameTankStatusLabel(id)}</div>
}