import { Dispatch } from 'redux';
import { notification } from "antd";
import { UserService } from '../../api';
import { UsersResponseInterface, UserResponseInterface, UserStatsInterface, UserInterface, ReservationsResponseInterface, GamesPlayedResponseInterface } from '../../interfaces';
import { UserConstants } from './user.constants';
import { i18n } from '../../services';
import { logoutAction } from '../auth';

export function getUsersAction(nicknamePart?: string) {
  return (dispatch: Dispatch) => {
    return UserService.getUsers(nicknamePart).subscribe(
      (response: UsersResponseInterface) => {
        if(!nicknamePart && response.pageInfo.total > 1) {
          alert("Nije moguće prikazati sve podatke.");
        }
        dispatch({
          type: UserConstants.GET_USERS_SUCCESS,
          payload: response.data
        });
      },
      (error: Error) => {
        dispatch({
          type: UserConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function getUserAction(userId: number) {
  return (dispatch: Dispatch) => {
    return UserService.getUser(userId).subscribe(
      (response: UserResponseInterface) => {
        dispatch({
          type: UserConstants.GET_USER_SUCCESS,
          payload: response.data
        });
      },
      (error: Error) => {
        dispatch({
          type: UserConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function updateUserAction(user: UserInterface) {
  return (dispatch: Dispatch) => {
    return UserService.updateUser(user).subscribe(
      (response: UserInterface) => {
        notification["success"]({ message: i18n.translate("messages.saveSuccess") });
        dispatch({
          type: UserConstants.UPDATE_USER_SUCCESS,
          payload: response
        });
      },
      (error: Error) => {
        notification["error"]({ message: i18n.translate("messages.saveError") });
        dispatch({
          type: UserConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function updateUserByAdminAction(user: UserInterface) {
  return (dispatch: Dispatch) => {
    return UserService.updateUser(user).subscribe(
      (response: UserInterface) => {
        notification["success"]({ message: i18n.translate("messages.saveSuccess") });
      },
      (error: Error) => {
        notification["error"]({ message: i18n.translate("messages.saveError") });
        dispatch({
          type: UserConstants.ERROR,
          payload: error
        });
      }
    );
  };
}



export function changeUserStatusAction(userId: number) {
  return (dispatch: Dispatch) => {
    return UserService.changeUserStatus(userId).subscribe(
      (response: any) => {
        dispatch(logoutAction() as any);
        notification["success"]({ message: i18n.translate("deactivateAccount.success") });
      },
      (error: Error) => {
        notification["error"]({ message: i18n.translate("errorCodes.400") });
        dispatch({
          type: UserConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function changeUserPasswordByAdminAction(userId: number, newPassword: string) {
  return (dispatch: Dispatch) => {
    return UserService.changeUserPasswordByAdmin(userId, newPassword).subscribe(
      (response: any) => {
        notification["success"]({ message: i18n.translate("changePin.successMsg") });
      },
      (error: Error) => {
        notification["error"]({ message: i18n.translate("changePin.errorMsg") });
        dispatch({
          type: UserConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function changeUserPasswordAction(userId: number, payload: {oldPassword:string, newPassword:string}) {
  return (dispatch: Dispatch) => {
    return UserService.changeUserPassword(userId, payload).subscribe(
      (response: any) => {
        notification["success"]({ message: i18n.translate("changePin.successMsg") });
        // dispatch({
        //   type: UserConstants.CHANGE_USER_PASSWORD_SUCCESS,
        //   payload: response
        // });
      },
      (error: Error) => {
        notification["error"]({ message: i18n.translate("changePin.errorMsg") });
        dispatch({
          type: UserConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function getUsersByRankAction(size: number) {
  return (dispatch: Dispatch) => {
    return UserService.getUsersByRank(size).subscribe(
      (response: UsersResponseInterface) => {
        dispatch({
          type: UserConstants.GET_USERS_BY_RANK_SUCCESS,
          payload: response.data
        });
      },
      (error: Error) => {
        dispatch({
          type: UserConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function getUserStatsAction(userId: number) {
  return (dispatch: Dispatch) => {
    return UserService.getUserStats(userId).subscribe(
      (response: UserStatsInterface) => {
        dispatch({
          type: UserConstants.GET_USER_STATS_SUCCESS,
          payload: response
        });
      },
      (error: Error) => {
        dispatch({
          type: UserConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function getUserReservationsCurrentAction(userId: number) {
  return (dispatch: Dispatch) => {
    return UserService.getUserReservationsCurrent(userId).subscribe(
      (response: ReservationsResponseInterface) => {
        dispatch({
          type: UserConstants.GET_USER_RESERVATIONS_CURRENT_SUCCESS,
          payload: response.data
        });
      },
      (error: Error) => {
        dispatch({
          type: UserConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function getUserReservationsPlayedAction(userId: number) {
  return (dispatch: Dispatch) => {
    return UserService.getUserReservationsPlayed(userId).subscribe(
      (response: ReservationsResponseInterface) => {
        dispatch({
          type: UserConstants.GET_USER_RESERVATIONS_PLAYED_SUCCESS,
          payload: response.data
        });
      },
      (error: Error) => {
        dispatch({
          type: UserConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

export function getUserGamesPlayedAction(userId: number) {
  return (dispatch: Dispatch) => {
    return UserService.getUserGamesPlayed(userId).subscribe(
      (response: GamesPlayedResponseInterface) => {
        dispatch({
          type: UserConstants.GET_USER_GAMES_PLAYED_SUCCESS,
          payload: response.data
        });
      },
      (error: Error) => {
        dispatch({
          type: UserConstants.ERROR,
          payload: error
        });
      }
    );
  };
}

