import React from "react";
import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout } from "antd";

import { ContentComponent, SecondaryHeaderComponent, SiderComponent, i18n, UserRoleEnum, StoreStateInterface } from "../../../common";

import { AdminRoutes } from "./admin.routes";
import { AuthRoutes } from "../../auth";

import GamesPageComponent from "../games/gamesPage/gamesPage.component";
import GamePageComponent from "../games/gamePage/gamePage.component";
import UsersPageComponent from "../users/usersPage/usersPage.component";
import UserPageComponent from "../users/userPage/userPage.component";
import SystemPageComponent from "../system/systemPage.component";
import LogPageComponent from "../log/logPage.component";
import TanksPageComponent from "../tanks/tanksPage.component";
import TestLogicPageComponent from "../testLogic/testLogicPage.component";

function AdminRouter() {

  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const { path } = useRouteMatch();

  const isB4b = process.env.REACT_APP_B4B == 'true';
  const isWSExists = process.env.REACT_APP_WS_URL != '';

  let routes = [
    {
      path: AdminRoutes.GAMES.path,
      component: GamesPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.GAMES.path + '/:gameId',
      component: GamePageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: false,
    },
    {
      path: AdminRoutes.USERS.path,
      component: UsersPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.USERS.path + '/:userId',
      component: UserPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: false,
    },
    {
      path: AdminRoutes.SYSTEM.path,
      component: SystemPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.LOG.path,
      component: LogPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.TANKS.path,
      component: TanksPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.TEST_LOGIC.path,
      component: TestLogicPageComponent,
      roles: [UserRoleEnum.B4B],
      exact: true,
    }
  ];

  let sidebarComponentLinks: Array<{ title: string, href: string }> = [
    {
      title: i18n.translate("admin.menu.games"),
      href: AdminRoutes.GAMES.fullPath
    },
    {
      title: i18n.translate("admin.menu.users"),
      href: AdminRoutes.USERS.fullPath
    },
    {
      title: i18n.translate("admin.menu.tanks"),
      href: AdminRoutes.TANKS.fullPath
    },
    {
      title: i18n.translate("admin.menu.log"),
      href: AdminRoutes.LOG.fullPath
    },
  ];

  if (isWSExists) {
    sidebarComponentLinks.push({
      title: i18n.translate("admin.menu.system"),
      href: AdminRoutes.SYSTEM.fullPath
    });
  }

  if (isB4b) {
    sidebarComponentLinks.push({
      title: i18n.translate("admin.menu.testLogic"),
      href: AdminRoutes.TEST_LOGIC.fullPath
    });
  }

  const allowedRoutes = routes.filter(r => {
    if (r.roles.length === 0) return true;
    if (!userAuth.isAuthenticated || !userAuth.user) return false;
    if (r.roles.includes(userAuth!.user!.groups![0].id)) return true;
    if (isB4b && r.roles.includes(UserRoleEnum.B4B)) return true;

    return false;
  });

  if (allowedRoutes.length === 0) {
    return <Redirect to={AuthRoutes.LOGIN.fullPath} />;
  }

  return (
    <Layout className="ant-layout-has-sider-override" hasSider>
      <SiderComponent links={sidebarComponentLinks}></SiderComponent>
      <Layout>
        <SecondaryHeaderComponent></SecondaryHeaderComponent>
        <ContentComponent className='has-sider'>
          <Switch>
            {allowedRoutes.map((route, index) => (<Route exact={route.exact} path={`${path}/${route.path}`} key={index}> <route.component /> </Route>))}
            <Redirect to={AdminRoutes.GAMES.fullPath} />
          </Switch>
        </ContentComponent>
      </Layout>
    </Layout>
  );
}

export default AdminRouter;
