import React from "react";
import classnames from 'classnames';
import HeadingComponent from "../../heading/heading.component";

export default function FooterLinksComponent(props:any) {
  const {
      componentClass = "footer-links",
      menuFooter = "menu-footer",
      selectorClass = "",
      links,
      title
  } = props;

  const listItems = links.map((link:any) => 
    <li key={link.title} className={`${menuFooter}__item`}>
       <a 
          href={link.href} 
          className={`${menuFooter}__link`}
        >
          <span className={`${menuFooter}__link-text`}>{link.icon ? link.icon : link.title}</span>
        </a>
    </li>
  );

  const menuClass = classnames([
      componentClass,
      selectorClass ? selectorClass + '__' + componentClass : ''
  ]);

  return (
    <div className={menuClass}>
        <HeadingComponent title={title} headingLevel='h2' selectorClass="footer-links" size="textlarge" />
        <ul className={menuFooter}>
            {listItems}
        </ul>
    </div>   
  );
}