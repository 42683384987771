import { Badge } from "antd";
import React from "react";
import { i18n } from "../services";
import { ColorConstant } from "./color.constant";

export const TankStatusConstant = () => { return {
  NOT_ACTIVE: { id: 0, name: "NOT_ACTIVE", label: i18n.translate("admin.tank.inactive").toUpperCase(), color: ColorConstant.RED.hash},
  ACTIVE: { id: 1, name: "ACTIVE", label: i18n.translate("admin.tank.active").toUpperCase(), color: 'transparent'},
  DISABLED: { id: 2, name: "DISABLED", label: i18n.translate("admin.tank.disabled").toUpperCase(),  color: ColorConstant.RED.hash},
}};

export function getTankStatusById(id: number) {
  let element = Object.entries(TankStatusConstant()).find((e: any) => { return e[1].id === id });
  return element;
}

export function getTankStatusLabel(id: number) {
  let element = getTankStatusById(id);
  return element ? element[1].label : null;
}
  
export function getTankStatusBadge(id: number) {
  let element = getTankStatusById(id);
  return element ? <Badge className="statusBadge" color={element[1].color} /> : null;
}
  
export function getTankStatusBadgeAndLabel(id: number) {
  return <div>{getTankStatusBadge(id)} {getTankStatusLabel(id)}</div>
}