import React from "react";
import classnames from 'classnames';


export default function HeaderMenuComponent(props:any) {
  const {
      componentClass = "header-menu",
      selectorClass = "",
      links,
  } = props;

  const listItems = links.map((link:any) => 
    <li key={link.title} className={`${componentClass}__item`}>
       <a 
          href={link.href} 
          className={`${componentClass}__link`}
        >
          {link.icon ? link.icon : <span className={`${componentClass}__link-text`}>{link.title}</span>}
        </a>
    </li>
  );

  const menuClass = classnames([
      componentClass,
      selectorClass ? selectorClass + '__' + componentClass : ''
  ]);

  return (
    <div className={menuClass}>
      <ul className={`${componentClass}__items`}>
        {listItems}
      </ul>
    </div>   
  );
}