import React, { useEffect } from "react";

import { GameStatusStateEnum } from "../../../common/interfaces/tv";

import InfoPageComponent from "../infoPage";
import FieldPreparationPageComponent from '../fieldPreparationPage';
import MatchGamePageComponent from '../matchGamePage';
import BetweenRoundsPageComponent from "../betweenRoundsPage";
import SummmaryPageeComponent from "../summmaryPage";
import { StoreStateInterface, WebsocketService } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import { getTvSettings } from "../../../common/redux/tv";

function TvPageComponent() {

  const currentSystemState = useSelector((state: StoreStateInterface) => state.system.currentSystemState);
  const me = useSelector((state: StoreStateInterface) => state.system.me);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log('=== useEffect ON');
    WebsocketService.connect();
    return () => {
      console.log('=== useEffect OFF');
      WebsocketService.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(getTvSettings());
  }, []);

  let TvView: any = InfoPageComponent;

  if (currentSystemState && currentSystemState.gameStatus && currentSystemState.gameStatus.state) {
    switch (currentSystemState.gameStatus.state) {

      case GameStatusStateEnum.InitialState:
        TvView = InfoPageComponent; break;

      case GameStatusStateEnum.StartedState:
        if (currentSystemState && currentSystemState.previousGame && currentSystemState.previousGame.currentRound && currentSystemState.previousGame.currentRound.number) TvView = SummmaryPageeComponent;
        else TvView = InfoPageComponent;
        break;

      case GameStatusStateEnum.ErrorState:
        TvView = InfoPageComponent; break;

      case GameStatusStateEnum.PreparedGameState:
      case GameStatusStateEnum.PreparingTanksForRoundState:
      case GameStatusStateEnum.PrepareTanksState:
        if (!currentSystemState.currentGame || currentSystemState.currentGame.roundResults.length === 0) TvView = FieldPreparationPageComponent;
        else TvView = BetweenRoundsPageComponent;
        break;

      case GameStatusStateEnum.StartRoundState:
      case GameStatusStateEnum.RoundInProgressState:
        TvView = MatchGamePageComponent; break;

      case GameStatusStateEnum.RoundTimeOutState:
        TvView = MatchGamePageComponent; break;

      case GameStatusStateEnum.EndGameState:
        TvView = SummmaryPageeComponent; break;

      default: console.log(`[ERROR] No screen defined for type${currentSystemState.gameStatus.state}`);
    }
  }

  return (
    <div className="tv-page">
      <TvView />
      <div className="debug">{me?.name} - {me?.type} - {window.innerWidth}/{window.innerHeight}</div>
    </div>
  );

}

export default TvPageComponent;
