import React from "react";
import { Layout } from "antd";

import { ContentComponent, DrawerComponent, FooterComponent, i18n, PageOverlayComponent, PrimaryHeaderComponent } from "../../common";
import Title from "antd/lib/typography/Title";


function UnavailablePageComponent() {

  return (
    <Layout className="layout">
      <PrimaryHeaderComponent />
      <DrawerComponent />
      <PageOverlayComponent />
      <ContentComponent>
        <div className="center-center w100-h100">
            <Title level={1} className="heading__h1">{i18n.translate("errorCodes.503")}</Title>
        </div>
      </ContentComponent>
      <FooterComponent />
    </Layout>
  );
}

export default UnavailablePageComponent;
