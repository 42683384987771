import { ReservationConstants } from "./reservation.constants";
import { OccupationInterface, ReducerActionInterface, ReservationInterface } from "../../interfaces";
import moment from "moment";

// ============ INTERFACE ============

export interface ReservationStateInterface {
  occupations: Array<OccupationInterface>;
  reservationId?: number;
  reservationSummary?: ReservationInterface;
  adminReservation?: ReservationInterface;
  TANK_LIMIT_PER_TERM: number;
}

// ============ INIT STATE ============

const initialState: ReservationStateInterface = {
  occupations: [],
  TANK_LIMIT_PER_TERM: 6
};

// ============ REDUCERS ============

const createOccupation = (state: ReservationStateInterface, payload: OccupationInterface): ReservationStateInterface => {
  return {
    ...state,
    occupations: [...state.occupations, payload],
    reservationId: payload.webReservationId
  };
};

const deleteOccupation = (state: ReservationStateInterface, payload: number): ReservationStateInterface => {
  return {
    ...state,
    occupations: [...state.occupations.filter((occ: OccupationInterface) => occ.occupationId !== payload)]
  };
};

const checkOccupationValidation = (state: ReservationStateInterface): ReservationStateInterface => {
  let occupations = [...state.occupations.filter((occ: OccupationInterface) => moment(occ.validTo).isAfter(moment()))];
  let reservationId: number | undefined;
  if (occupations.length > 0) reservationId = state.reservationId
  else reservationId = undefined
  return {
    ...state,
    occupations: occupations,
    reservationId: reservationId
  };
};

const finishReservation = (state: ReservationStateInterface, payload: ReservationInterface): ReservationStateInterface => {
  return {
    ...state,
    reservationSummary: payload,
    occupations: [],
    reservationId: undefined
  };
};

const getReservation = (state: ReservationStateInterface, payload: ReservationInterface): ReservationStateInterface => {
  return {
    ...state,
    adminReservation: payload
  };
};

const removeAdminReservation = (state: ReservationStateInterface): ReservationStateInterface => {
  return {
    ...state,
    adminReservation: undefined
  };
};

const error = (state: ReservationStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};


// ============ EXPORTS ============

export const ReservationReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case ReservationConstants.CREATE_OCCUPATION: return createOccupation(state, action.payload);
    case ReservationConstants.DELETE_OCCUPATION: return deleteOccupation(state, action.payload);
    case ReservationConstants.CHECK_OCCUPATION_VALIDATION: return checkOccupationValidation(state);
    case ReservationConstants.FINISH_RESERVATION: return finishReservation(state, action.payload);
    case ReservationConstants.GET_RESERVATION: return getReservation(state, action.payload);
    case ReservationConstants.REMOVE_ADMIN_RESERVATION: return removeAdminReservation(state);
    case ReservationConstants.ERROR: return error(state, action.payload);
    default:
      return state;
  }
};
