import React,{useLayoutEffect} from "react";
import { i18n } from "../../services";

import HeaderMenuComponent from "../header/menu/headerMenu.component";
import LanguageSwitcherComponent from "../languageSwitcher/languageSwitcher.component";


export default function DrawerComponent() {
    
    useLayoutEffect(() => {
        const drawerSelector = '.js-drawer';
	    const drawerElem = document.querySelector(drawerSelector);
        if (drawerElem) {
            import('./assets/drawer').then(({ Drawer }) => {
                const drawer = new Drawer();
                drawer.drawerInit();
            });
        }
    });
    

	
    return (
        <div
	        className="drawer js-drawer drawer__position--behind"
	        data-trigger="js-hamburger"
	        data-overlay="js-page-overlay"
        >
	        <HeaderMenuComponent
                componentClass="header-drawer-menu"
                links={[
                {
                    title: i18n.translate("header.rules"),
                    href: `${process.env.REACT_APP_WP_URL}/pravila-igre/`
                },
                {
                    title: i18n.translate("footer.catalog"),
                    href: `${process.env.REACT_APP_WP_URL}/cjenik/`

                },
                {
                    title: i18n.translate("drawer.tanks"),
                    href: `${process.env.REACT_APP_WP_URL}/tenkovi/`
                },
                {
                    title: i18n.translate("drawer.arena"),
                    href: `${process.env.REACT_APP_WP_URL}/ironbull-arena-zagreb/`
                },
                {
                    title: i18n.translate("header.shop"),
                    href: `${process.env.REACT_APP_WP_URL}/proizvod/`
                },
                {
                    title: i18n.translate("header.blog"),
                    href: `${process.env.REACT_APP_WP_URL}/blog/`
                },
                {
                    title: i18n.translate("footer.contact"),
                    href: `${process.env.REACT_APP_WP_URL}/kontakt/`
                },
                ]}
            />
            <LanguageSwitcherComponent selectorClass="drawer" />
        </div>
    );
}