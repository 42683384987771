import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Avatar, Typography, Select, Space, Button, Popconfirm } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import './profilePersonalDataPage.style.scss';
import { UserInterface, i18n, StoreStateInterface, DisplayDateFormat, ChangePinModalComponent, ChangeAvatarModalComponent, ChangePasswordModalComponent } from "../../../common";
import { changeUserStatusAction, getUserAction, updateUserAction } from "../../../common/redux";

function ProfilePersonalDataPageComponent() {

  const { Title, Text } = Typography;

  const { Option } = Select;

  const [editAvatar, setEditAvatar] = useState<boolean>(false);

  const [editPin, setEditPin] = useState<boolean>(false);

  const [editLanguage, setEditLanguage] = useState<boolean>(false);

  const [editNewsletter, setEditNewsletter] = useState<boolean>(false);
  
  const userRole = useSelector((state: StoreStateInterface) => state.auth.user!.groups![0].id);

  const userId = useSelector((state: StoreStateInterface) => state.auth.user!.id);

  const user = useSelector((state: StoreStateInterface) => state.user.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if(userId) dispatch(getUserAction(userId));
  }, [userId]);

  function onLanguageSelect(value: string): void {
    console.log(value);
    setEditLanguage(false)
  }

  function onNewsletterSelect(value: number): void {
    let model: UserInterface = {
      id: user!.id,
      name: user!.name,
      email: user!.email,
      birthday: user!.birthday,
      nickname: user!.nickname, 
      avatarJson: user!.avatarJson,
      countryCode: user!.country?.code,
      newsletter: value ? true : false     
    };
    dispatch(updateUserAction(model));
    setEditNewsletter(false)
  }

  function deleteAccount(): void {
    dispatch(changeUserStatusAction(userId));
  }

  const renderFirstColumn = (): React.ReactNode => {
    let initials = user!.avatarJson ? user!.avatarJson.initials ? user!.avatarJson.initials : user!.nickname.substr(0,2) : user!.nickname.substr(0,2);
    let background = user!.avatarJson ? user!.avatarJson.background ? user!.avatarJson.background : '#39402B' : '#39402B';
    let color = user!.avatarJson ? user!.avatarJson.color ? user!.avatarJson.color : '#f56a00' : '#f56a00';
    return (
      <div className="flex-row">
        <div className="item">
          <Avatar size={100} style={{ fontSize: '50px', color: color, backgroundColor: background }}>{initials}</Avatar>
          <Button type="default" shape="circle" size="small" icon={<EditOutlined />} onClick={() => setEditAvatar(true)} />
        </div>
        <div>
          <Title level={5} type="warning">{user!.nickname}</Title>
          <div>{user!.name}</div>
        </div>        
      </div>      
    ) 
  }

  const renderSecondColumn = (): React.ReactNode => {
    return (
      <div className="gridTable">
        <Text strong>{i18n.translate("user.fullname")}</Text>
        <Text>{user!.name}</Text>

        <Text strong>{i18n.translate("user.mail")}</Text>
        <Text>{user!.email}</Text>

        <Text strong>{i18n.translate("user.dateOfBirth")}</Text>
        <Text>{moment(user!.birthday).format(DisplayDateFormat)}</Text>

        <Text strong>{i18n.translate("user.country")}</Text>
        <Text>{user!.country ? user!.country.name : '-'}</Text>

        <Text strong>{i18n.translate("user.password")}</Text>
        {renderPinField()}

        <Text strong>{i18n.translate("user.language")}</Text>        
        {renderLanguageField()}       

        <Text strong>{i18n.translate("user.newsletter")}</Text>
        {renderNewsletterField()}

        <div></div>
        <div></div>

        <div></div>
        <Popconfirm placement="topLeft" title={i18n.translate("messages.confirm")} okText={i18n.translate("buttons.yes")} cancelText={i18n.translate("buttons.no")} onConfirm={() => deleteAccount()}>
          <a href="# ">{i18n.translate("user.delete")}</a>
        </Popconfirm>        

      </div>
    ) 
  }

  const renderPinField = (): React.ReactNode => {    
    return (
      <Space>
        <Text>****</Text>
        <EditOutlined onClick={() => setEditPin(true)}/>
      </Space>     
    ) 
  }

  const renderLanguageField = (): React.ReactNode => {
    if(editLanguage) return (
      <Select size="small" defaultValue="HR" onSelect={(value: string) => onLanguageSelect(value)} style={{ width: 200 }}>
          <Option value="HR" key="HR">{i18n.translate("languages.hr")}</Option>
          {/* <Option value="EN" key="EN">{i18n.translate("languages.en")}</Option> */}
      </Select>
    )
    return (
      <Space>
        <Text>{i18n.translate("languages.hr")}</Text>
        <EditOutlined onClick={() => setEditLanguage(true)}/>
      </Space>     
    ) 
  }

  const renderNewsletterField = (): React.ReactNode => {
    if(editNewsletter) return (
      <Select size="small" defaultValue={user!.newsletter ? 1 : 0} onSelect={(value: number) => onNewsletterSelect(value)} style={{ width: 200 }}>
          <Option value={1} key="1">{i18n.translate("user.newsletterAccept")}</Option>
          <Option value={0} key="0">{i18n.translate("user.newsletterDecline")}</Option>
      </Select>
    )
    return (
      <Space>
        <Text>{user!.newsletter ? i18n.translate("user.newsletterAccept") : i18n.translate("user.newsletterDecline")}</Text> 
        <EditOutlined onClick={() => setEditNewsletter(true)}/>
      </Space>     
    ) 
  }

  const renderAvatarModal = (): React.ReactNode => {       
    return (
      <ChangeAvatarModalComponent visible={editAvatar} closeHandler={() => setEditAvatar(false)}></ChangeAvatarModalComponent> 
    )    
  }

  const renderPinModal = (): React.ReactNode => {       
    if(userRole === 2) {
      return (
        <ChangePasswordModalComponent visible={editPin} closeHandler={() => setEditPin(false)}></ChangePasswordModalComponent>  
      )
    }
    return <ChangePinModalComponent visible={editPin} closeHandler={() => setEditPin(false)}></ChangePinModalComponent>       
  }

  if(!user) return null;
  return (
    <div className="profilePersonalDataPage">
      <div className="gridMain">
        {renderFirstColumn()}
        {renderSecondColumn()}
      </div>
      {renderAvatarModal()} 
      {renderPinModal()} 
    </div>
  );
}

export default ProfilePersonalDataPageComponent;
