import { ReducerActionInterface, GamesSystemPropertiesInterface } from "../../interfaces";
import { TvConstants } from "./tv.constants";

// ============ INTERFACE ============

export interface TvStateInterface {
  tvSettings: {
    waitBeforePrepareTanks: number;
    waitAfterTanksPrepared: number;
    'gameService.minAllowedGameDurationMinutes': number;
    'endGameState.secondsBeforeNextStarts': number;
    maxRoundDuration: number;
    waitAfterErrorInSeconds: number;
    'debug.fakeGame.rounds': number;
    'debug.fakeGame.startsInSeconds': number;
    minAvailableTimeForRound: number;
  };
}

// ============ INIT STATE ============

const initialState: TvStateInterface = {
  tvSettings: {
    waitBeforePrepareTanks: 0,
    waitAfterTanksPrepared: 0,
    'gameService.minAllowedGameDurationMinutes': 0,
    'endGameState.secondsBeforeNextStarts': 0,
    maxRoundDuration: 0,
    waitAfterErrorInSeconds: 0,
    'debug.fakeGame.rounds': 0,
    'debug.fakeGame.startsInSeconds': 0,
    minAvailableTimeForRound: 0,
  }
};

const getTVSettingsReducer = (state: TvStateInterface, payload: Array<GamesSystemPropertiesInterface>): TvStateInterface => {
  let newState = { ...state, tvSettings: { ...state.tvSettings } };
  payload.forEach(item => {
    newState.tvSettings[item.key] = parseInt(item.value);
  });
  return newState;
};

// ============ EXPORTS ============

export const TvReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case TvConstants.GET_TV_SETTINGS: return getTVSettingsReducer(state, action.payload);
    default:
      return state;
  }
};
