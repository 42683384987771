export const ROOT_PATH_PROFILE = 'profile';

export const ProfileRoutes: any = {
    STATISTICS: { path: 'statistics', fullPath: `/${ROOT_PATH_PROFILE}/statistics` },
    RESERVATIONS: { path: 'reservations', fullPath: `/${ROOT_PATH_PROFILE}/reservations` },
    FRIENDS: { path: 'friends', fullPath: `/${ROOT_PATH_PROFILE}/friends` },
    LAST_GAMES: { path: 'last-games', fullPath: `/${ROOT_PATH_PROFILE}/last-games` },
    RANKING: { path: 'ranking', fullPath: `/${ROOT_PATH_PROFILE}/ranking` },
    PERSONAL_DATA: { path: 'personal-data', fullPath: `/${ROOT_PATH_PROFILE}/personal-data` },
    NOTIFICATIONS: { path: 'notifications', fullPath: `/${ROOT_PATH_PROFILE}/notifications` },
};
