import { ReducerActionInterface, TankInterface } from "../../interfaces";
import { TankConstants } from "./tank.constants";

// ============ INTERFACE ============

export interface TankStateInterface {
    tanks: Array<TankInterface>;
}

// ============ INIT STATE ============

const initialState: TankStateInterface = {
    tanks: []
};

// ============ REDUCERS ============

const getTanksSuccess = (state: TankStateInterface, payload: Array<TankInterface>): TankStateInterface => {
    return {
        ...state,
        tanks: payload
    };
};

const refreshTank = (state: TankStateInterface, payload: TankInterface): TankStateInterface => {
    // let tank = state.tanks.find((tank: TankInterface) => tank.id === payload.id);
    // tank = payload;
    let tanks = state.tanks.map((tank: TankInterface) => {
        if (tank.id === payload.id) return payload;
        else return tank;
    })
    return {
        ...state,
        tanks: tanks
    };
};

const error = (state: TankStateInterface, payload: string) => {
    return {
        ...state,
        error: payload,
    };
};

// ============ EXPORTS ============

export const TankReducer = (state = initialState, action: ReducerActionInterface) => {
    switch (action.type) {
        case TankConstants.GET_TANKS_SUCCESS: return getTanksSuccess(state, action.payload);
        case TankConstants.REFRESH_TANK: return refreshTank(state, action.payload);
        case TankConstants.ERROR: return error(state, action.payload);
        default:
            return state;
    }
};