import { EventConstants } from "./event.constants";
import { ReducerActionInterface } from "../../interfaces";

// ============ INTERFACE ============

export interface EventStateInterface {
  events: Array<any>;
}

// ============ INIT STATE ============

const initialState: EventStateInterface = {
  events: [],
};

// ============ REDUCERS ============

const getLastEvents = (state: EventStateInterface, payload: Array<any>): EventStateInterface => {
  return {
    ...state,
    events: payload
  };
};

const error = (state: EventStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};


// ============ EXPORTS ============

export const EventReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case EventConstants.GET_LAST_EVENTS: return getLastEvents(state, action.payload);
    case EventConstants.ERROR: return error(state, action.payload);
    default:
      return state;
  }
};
