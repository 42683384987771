import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import HttpFactory, { HttpParamsTypes } from "./abstract/abstract.api.service";
import { RegisterInterface, RegisterResponseInterface, LoginInterface, LoginResponseInterface, BaseResponseInterface, UsersResponseInterface, UserResponseInterface, UserStatsInterface, UserInterface, ReservationsResponseInterface, GamesPlayedResponseInterface } from "../interfaces";
import { apiRoutes } from "./routes";

export class UserService {
    static register(data: RegisterInterface): Observable<RegisterResponseInterface> {
        return HttpFactory.POST(apiRoutes.register, data);
    }
    
    static login(data: LoginInterface): Observable<LoginResponseInterface> {
        return HttpFactory.POST(apiRoutes.login, data);
    }

    static logout(): Observable<BaseResponseInterface> {
        return HttpFactory.GET(apiRoutes.logout);
    }

    static getUsers(nicknamePart?: string): Observable<UsersResponseInterface> { 
        if(nicknamePart) return HttpFactory.GET([apiRoutes.users, 'byNicknamePart', nicknamePart].join('/'));
        return HttpFactory.GET([apiRoutes.users, 'size=9999'].join('?'));
    }

    static getUser(userId: number): Observable<UserResponseInterface> { 
        return HttpFactory.GET([apiRoutes.user, userId].join('/')).pipe(map(UserConverter.deserializeResponse));
    }

    static updateUser(user: UserInterface): Observable<any> { 
        let url = apiRoutes.userUpdate.replace('{userId}', user.id.toString());
        let payload = UserConverter.serialize(user);
        return HttpFactory.POST(url, payload).pipe(map(UserConverter.deserialize));
    }

    static changeUserStatus(userId: number): Observable<void> {
        return HttpFactory.POST(apiRoutes.userStatus.replace('{userId}', userId.toString()), {status: "0"});
    }

    static changeUserPassword(userId: number, payload: {oldPassword:string, newPassword:string}): Observable<any> { 
        let url = apiRoutes.changePassword.replace('{userId}', userId.toString());
        return HttpFactory.POST(url, payload);
    }

    static changeUserPasswordByAdmin(userId: number, newPassword: string): Observable<any>{
        let url = apiRoutes.adminChangePassword.replace('{userId}', userId.toString());
        return HttpFactory.POST(url, {password: newPassword});
    }

    static getUsersByRank(size: number): Observable<UsersResponseInterface> {         
        let params: HttpParamsTypes = { 
            size: size.toString()
        };
        return HttpFactory.GET(apiRoutes.usersRank, params);
    }

    static getUserStats(userId: number): Observable<UserStatsInterface> { 
        let url = apiRoutes.userStats.replace('{userId}', userId.toString());
        return HttpFactory.GET(url);
    }

    static getUserReservationsCurrent(userId: number): Observable<ReservationsResponseInterface> { 
        let url = apiRoutes.userReservationsCurrent.replace('{userId}', userId.toString());
        return HttpFactory.GET(url);
    }

    static getUserReservationsPlayed(userId: number): Observable<ReservationsResponseInterface> { 
        let url = apiRoutes.userReservationsPlayed.replace('{userId}', userId.toString());
        return HttpFactory.GET(url);
    }

    static getUserGamesPlayed(userId: number): Observable<GamesPlayedResponseInterface> { 
        let url = apiRoutes.userGamesPlayed.replace('{userId}', userId.toString());
        let params: HttpParamsTypes = { 
            sort: "game.from,desc"
        };
        return HttpFactory.GET(url, params);
    }
}

class UserConverter {

    static deserializeResponse(response: UserResponseInterface): UserResponseInterface {  
      response.data = UserConverter.deserialize(response.data);
      return response;
    }
    
    static deserialize(user: UserInterface): UserInterface {
      if (user.avatarJson) {
        let avatarJsonData;
        if (typeof user.avatarJson === 'string' || user.avatarJson instanceof String) avatarJsonData = JSON.parse(user.avatarJson as any);
        else avatarJsonData = user.avatarJson;
        user.avatarJson = avatarJsonData;
      };
      return user;
    }
  
    static serialize(user: UserInterface): UserInterface {      
      if (user.avatarJson) {
        let avatarJsonData = JSON.stringify(user.avatarJson);
        user.avatarJson = avatarJsonData as any;
      }  
      return user;
    }
  
  }
