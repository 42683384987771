import { Button, Divider } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteOccupationAction, DisplayDateFormat, finishReservationAction, i18n, NavigationService, OccupationInterface, StoreStateInterface, TimeNoSecFormat } from "../../../common";
import './reservationReviewPage.style.scss';
import TankLogo from "../../../assets/images/logo_tank.svg";
import { MdDelete } from "react-icons/md";
import { ReservationsRoutes } from "../_router/reservations.routes";


function ReservationReviewPageComponent() {

    const reservation = useSelector((state: StoreStateInterface) => state.reservation);

    const dispatch = useDispatch();

    function deleteOccupationClick(occupationId: number) {
        dispatch(deleteOccupationAction(occupationId));
    }

    function finishReservationClick() {
        reservation.reservationId && dispatch(finishReservationAction(reservation.reservationId));
    }

    return (
    <div className="site-container"> 
        <h1 className="heading__h1 heading--margin-50 text-center mt50 mb50">{i18n.translate("summary.selectedGames").toUpperCase()}</h1>
        <Divider />
        <div className="reservationSummary">
            {reservation.occupations.length === 0 && 
            <div>{i18n.translate("summary.noGames").toUpperCase()}</div>
            }
            {reservation.occupations.map((occupation: OccupationInterface) => {
            return (
                <div className="item" key={occupation.occupationId}>
                    <div className="date">{moment(occupation.gameTank?.game?.from).format(DisplayDateFormat)}</div>
                    <div className="time">{moment(occupation.gameTank?.game?.from).format(TimeNoSecFormat)}</div>
                    <div className="tank"><img src={TankLogo} alt="tank" />{` ${i18n.translate("tank.tank").toUpperCase()} #${occupation.gameTank?.position} / ${occupation.gameTank?.tankName}`}</div>
                    <div className="price">{occupation.gameTank!.game!.price},00 KN</div>
                    <div className="delete" onClick={() => deleteOccupationClick(occupation.occupationId)}><MdDelete /></div>
                </div>
            )
            })}
        </div>
        <Divider />
        <div className="actions">
            <Button ghost size={window.innerWidth > 480 ? "large" : "middle"} onClick={() => NavigationService.navigate(ReservationsRoutes.RESERVATIONS.fullPath)} >{i18n.translate("reservations.editReservation").toUpperCase()}</Button>
            <Button type="primary" size={window.innerWidth > 480 ? "large" : "middle"} onClick={() => (reservation.occupations.length > 0) && finishReservationClick()} >{i18n.translate("reservations.finishReservation").toUpperCase()}</Button>
        </div>
    </div>
    );

}

export default ReservationReviewPageComponent;
