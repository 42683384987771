import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Avatar from 'react-avatar';
import { Avatar } from 'antd';

import { i18n, StoreStateInterface } from "../../../common";
import { getUserAction } from "../../../common/redux";
import HeadingComponent from '../heading/heading.component';
import UserRankComponent from '../userRank/userRank.component';

export default function HeroComponent() {

    const userId = useSelector((state: StoreStateInterface) => state.auth.user?.id);

    const user = useSelector((state: StoreStateInterface) => state.user.user);

    const userStats = useSelector((state: StoreStateInterface) => state.user.userStats);

    const dispatch = useDispatch();

    useEffect(() => {
        if(userId) dispatch(getUserAction(userId));
    }, [userId]);

    let initials = user?.avatarJson ? user?.avatarJson.initials ? user?.avatarJson.initials : user?.nickname.substr(0,2) : user?.nickname.substr(0,2);
    let background = user?.avatarJson ? user?.avatarJson.background ? user?.avatarJson.background : '#39402B' : '#39402B';
    let color = user?.avatarJson ? user?.avatarJson.color ? user?.avatarJson.color : '#f56a00' : '#f56a00';
    return (
        <div className="hero" style={{
            backgroundImage: `url(${(process.env.REACT_APP_URL || '.')}/static/images/profile.png)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
        
        }}>
            <div className="hero__wrapper">
                <div className="hero__column hero__column--left">
                    <Avatar size={150} style={{ fontSize: '75px', color: color, backgroundColor: background }}>{initials}</Avatar>
                </div>
                <div className="hero__column hero__column--center">
                    <HeadingComponent title={user?.name}  />
                </div>
                <div className="hero__column hero__column--right">
                    <UserRankComponent ranking={`${i18n.translate('profileStatistics.currently')} ${userStats?.rank}.`} />
                </div>
            </div>
        </div>
    );
}