import { ReducerActionInterface, ClientInterface, CurrentGameStatusInterface, GamesSystemPropertiesInterface } from "../../interfaces";
import { SystemConstants } from "./system.constants";

// ============ INTERFACE ============

export interface SystemStateInterface {
  currentSystemState?: CurrentGameStatusInterface;
  me: ClientInterface | undefined;
  clients: Array<ClientInterface>;
  lastSettingsChangeTime: number | undefined; // TODO - this is a hack so thath we can refresh WS. Move WS to redux actions to fix this.
  systemProperties: {
    waitBeforePrepareTanks: number;
    waitAfterTanksPrepared: number;
    'gameService.minAllowedGameDurationMinutes': number;
    'endGameState.secondsBeforeNextStarts': number;
    maxRoundDuration: number;
    waitAfterErrorInSeconds: number;
    'debug.fakeGame.rounds': number;
    'debug.fakeGame.startsInSeconds': number;
    minAvailableTimeForRound: number;
  };
  script: string;
}

// ============ INIT STATE ============

const initialState: SystemStateInterface = {
  me: undefined,
  clients: [],
  lastSettingsChangeTime: undefined,
  systemProperties: {
    waitBeforePrepareTanks: 0,
    waitAfterTanksPrepared: 0,
    'gameService.minAllowedGameDurationMinutes': 0,
    'endGameState.secondsBeforeNextStarts': 0,
    maxRoundDuration: 0,
    waitAfterErrorInSeconds: 0,
    'debug.fakeGame.rounds': 0,
    'debug.fakeGame.startsInSeconds': 0,
    minAvailableTimeForRound: 0,
  },
  script: ''
};

// ============ REDUCERS ============

const setCurrrentGameReducer = (state: SystemStateInterface, payload: CurrentGameStatusInterface): SystemStateInterface => {
  return {
    ...state,
    currentSystemState: payload
  };
};

const setMeReducer = (state: SystemStateInterface, payload: ClientInterface): SystemStateInterface => {
  return {
    ...state,
    me: payload
  };
};

const setClientsReducer = (state: SystemStateInterface, payload: Array<ClientInterface>): SystemStateInterface => {
  return {
    ...state,
    clients: payload
  };
};

const setLastSettingsChangeTimeReducer = (state: SystemStateInterface): SystemStateInterface => {
  return {
    ...state,
    lastSettingsChangeTime: new Date().valueOf()
  };
};

const setGamesSystemPropertiesReducer = (state: SystemStateInterface, payload: Array<GamesSystemPropertiesInterface>): SystemStateInterface => {
  let newState = { ...state, systemProperties: { ...state.systemProperties } };
  payload.forEach(item => {
    newState.systemProperties[item.key] = parseInt(item.value);
  });
  return newState;
};

const setScriptReducer = (state: SystemStateInterface, payload: string): SystemStateInterface => {
  return {
    ...state,
    script: payload
  };
};

// ============ EXPORTS ============

export const SystemReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case SystemConstants.SYSTEM_GAMES_SET_CURRENT_GAME: return setCurrrentGameReducer(state, action.payload);
    case SystemConstants.SYSTEM_GAMES_SET_ME: return setMeReducer(state, action.payload);
    case SystemConstants.SYSTEM_GAMES_SET_CLIENTS: return setClientsReducer(state, action.payload);
    case SystemConstants.SYSTEM_GAMES_SET_SETTINGS_CHANGE_TIME: return setLastSettingsChangeTimeReducer(state);
    case SystemConstants.SYSTEM_GAMES_SET_SYSTEM_PROPERTIES: return setGamesSystemPropertiesReducer(state, action.payload);
    case SystemConstants.SYSTEM_GAMES_SET_SCRIPT: return setScriptReducer(state, action.payload);
    default:
      return state;
  }
};
