export const ROOT_PATH_ADMIN = 'admin';

export const AdminRoutes = {
    GAMES: {path: 'games', fullPath: `/${ROOT_PATH_ADMIN}/games`},
    USERS: {path: 'users', fullPath: `/${ROOT_PATH_ADMIN}/users`},
    SYSTEM: {path: 'system', fullPath: `/${ROOT_PATH_ADMIN}/system`},
    LOG: {path: 'log', fullPath: `/${ROOT_PATH_ADMIN}/log`},
    TANKS: {path: 'tanks', fullPath: `/${ROOT_PATH_ADMIN}/tanks`},
    TEST_LOGIC: {path: 'test-logic', fullPath: `/${ROOT_PATH_ADMIN}/test-logic`}
}
