import { Badge, Button, Modal, Table } from "antd";
import React, { useState } from "react";
import { TankInterface } from "../../interfaces";
import { i18n } from "../../services";
import TankLogo from "../../../assets/images/logo_tank.svg";
import { ColorConstant } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { setTankStatusAction, changeTankPositionAction, StoreStateInterface, changeRemoteStateAction } from "../../redux";
import { getTankStatusLabel, TankStatusConstant } from "../../constants/tankStatus.constant";

export default function EditTanksModalComponent(props: { visible: boolean, closeHandler: any }) {

    const tanks = useSelector((state: StoreStateInterface) => state.tank.tanks);
    
    const [isSwitchable, setIsSwitchable] = useState<boolean>(false);

    const [isDisableable, setIsDisableable] = useState<boolean>(false);

    const [isRemote, setIsRemote] = useState<boolean>(false);

    const [tankFromId, setTankFromId] = useState<number>();

    const dispatch = useDispatch();

    function cancelActions() {
        setIsSwitchable(false);
        setIsDisableable(false);
        setIsRemote(false);
        setTankFromId(undefined);
    }

    function changeTankPositionClick(tankId: number) {
        dispatch(changeTankPositionAction(tankFromId!, tankId));
        setIsSwitchable(false);
        setTankFromId(undefined);
    }

    function exitModalClick() {
        cancelActions();
        props.closeHandler();
    }

    function enableAllRemotes() {
        tanks.forEach((tank) => dispatch(changeRemoteStateAction(tank.id, "enable_remote")));
    }

    function disableAllRemotes() {
        tanks.forEach((tank) => dispatch(changeRemoteStateAction(tank.id, "disable_remote")));
    }

    const getTableColumns: any = [
        {
            title: '',
            key: 'tank.id',
            dataIndex: 'id',
            render: (dataIndex: string, tank: TankInterface) => renderButton(tank)
        },
        {
            title: i18n.translate("admin.tank.position"),
            key: 'tank.position',
            dataIndex: 'position',
            render: (dataIndex: string, tank: TankInterface) => tank.position && `#${tank.position}`
        },
        {
            title: <img src={TankLogo} alt="tank" />,
            key: 'tank.name',
            dataIndex: 'name',
            render: (dataIndex: string, tank: TankInterface) => `${tank.name}`
        },
        {
            title: i18n.translate("admin.tank.battery"),
            key: 'tank.battery',
            dataIndex: 'battery',
            render: (dataIndex: string, tank: TankInterface) => calculateBatteryPercentage(tank.battery) + ` | ${tank.battery}v`
        },
        {
            title: i18n.translate("admin.tank.state"),
            key: 'tank.isConnected',
            dataIndex: 'isConnected',
            render: (dataIndex: string, tank: TankInterface) => tank.isConnected ? <Badge className="statusBadge" color={ColorConstant.GREEN.hash} /> : <Badge className="statusBadge" color={ColorConstant.RED.hash} />
        },
        {
            title: i18n.translate("admin.tank.status"),
            key: 'tank.status',
            dataIndex: 'status',
            render: (dataIndex: string, tank: TankInterface) => `${tank.status === TankStatusConstant().ACTIVE.name ? getTankStatusLabel(1) : getTankStatusLabel(0)}`
        }
    ];

    const renderButton = (tank: TankInterface): React.ReactNode => {
        if(isSwitchable) {
            if(tankFromId) { 
                return <Button type="link" onClick={() => changeTankPositionClick(tank.id)}>{i18n.translate("admin.tank.switchTanks")}</Button>;
            } else {
                return <Button type="link" onClick={() => setTankFromId(tank.id)}>{i18n.translate("admin.tank.choose")}</Button>;
            }
        }
        if(isDisableable) {
            if(tank.status === TankStatusConstant().ACTIVE.name) {
                return <Button type="link" onClick={() => dispatch(setTankStatusAction(tank.id, TankStatusConstant().NOT_ACTIVE.name))}>{i18n.translate("admin.tank.disableTank")}</Button>;
            }
            if(tank.status === TankStatusConstant().NOT_ACTIVE.name) {
                return <Button type="link" onClick={() => dispatch(setTankStatusAction(tank.id, TankStatusConstant().ACTIVE.name))}>{i18n.translate("admin.tank.enableTank")}</Button>;
            }
        }
        if(isRemote) {
            return (
                <div className="flex-row">
                    <Button type="link" className="item" onClick={() => dispatch(changeRemoteStateAction(tank.id, "enable_remote"))}>{i18n.translate("admin.tank.enableRemote")}</Button>
                    <Button type="link" className="item" onClick={() => dispatch(changeRemoteStateAction(tank.id, "disable_remote"))}>{i18n.translate("admin.tank.disableRemote")}</Button>
                </div>
            ) 
        }
    }

    function calculateBatteryPercentage(tankBattery: number): String {
        const emptyBattery = 22.4;
        const fullBattery = 24.8;
        let currentBattery = (((tankBattery - emptyBattery) / (fullBattery - emptyBattery)) * 100);
        if(currentBattery > 100) {
            return "100.00%";
        } else if(currentBattery < 0) {
            return "0.00%";
        } else {
            return `${currentBattery.toFixed(2)}%`;
        }
    }

    const renderActions = (): React.ReactNode => {
        return (
            <div className="flex-row mb25">
                {!isSwitchable && !isDisableable && !isRemote &&
                    <>
                        <Button type="primary" className="item" onClick={() => setIsRemote(true)}>{i18n.translate("admin.tank.remote")}</Button>
                        <Button type="primary" className="item" onClick={() => setIsDisableable(true)}>{i18n.translate("admin.tank.changeStatus")}</Button>
                        <Button type="primary" className="item" onClick={() => setIsSwitchable(true)}>{i18n.translate("admin.tank.switchTanks")}</Button>
                    </>
                }
                {(isSwitchable || isDisableable || isRemote) && <Button danger type="primary" className="item" onClick={() => cancelActions()}>{i18n.translate("admin.tank.cancel")}</Button>}
                {isRemote && 
                    <>
                        <Button type="primary" className="item" onClick={() => enableAllRemotes()}>{i18n.translate("admin.tank.enableAllRemotes")}</Button>
                        <Button type="primary" className="item" onClick={() => disableAllRemotes()}>{i18n.translate("admin.tank.disableAllRemotes")}</Button>
                    </>
                }
            </div>
        )
    }

    const renderTankTable = (): React.ReactNode => {
        let tableTanks = tanks.sort((t1: TankInterface, t2: TankInterface) => {
            return (t1.position === null ? 1 : 0) - (t2.position === null ? 1 : 0) || +(t1.position > t2.position) || -(t1.position < t2.position);
        })
        return <Table rowKey={(tank) => tank.position} columns={getTableColumns} dataSource={tableTanks} pagination={false} scroll={{ x: 240 }} />
    }

    const renderExitButton = (): React.ReactNode => {
        return <Button type="primary" className="mt16" onClick={exitModalClick}>{i18n.translate("admin.tank.exit")}</Button>
    }

    return (
        <Modal
            title=" "
            onCancel={exitModalClick}
            visible={props.visible}
            footer={null}
            width="80%"
        >
            {renderActions()}
            {renderTankTable()}
            {renderExitButton()}
        </Modal>
    );
}