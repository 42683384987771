export enum ReservationConstants {
    CREATE_OCCUPATION = "CREATE_OCCUPATION",
    DELETE_OCCUPATION = "DELETE_OCCUPATION",
    CHECK_OCCUPATION_VALIDATION = "CHECK_OCCUPATION_VALIDATION",
    FINISH_RESERVATION = "FINISH_RESERVATION",
    GET_RESERVATION = "GET_RESERVATION",
    REMOVE_ADMIN_RESERVATION = "REMOVE_ADMIN_RESERVATION",
    ERROR = "ERROR",
};
  
  