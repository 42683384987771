import React from "react";


export default function SocialLinksComponent(props:any) {

    const icons = {
        "facebook": "<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>",
		"twitter": "<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill='none' d='M23 3.00005C22.0424 3.67552 20.9821 4.19216 19.86 4.53005C19.2577 3.83756 18.4573 3.34674 17.567 3.12397C16.6767 2.90121 15.7395 2.95724 14.8821 3.2845C14.0247 3.61176 13.2884 4.19445 12.773 4.95376C12.2575 5.71308 11.9877 6.61238 12 7.53005V8.53005C10.2426 8.57561 8.50127 8.18586 6.93101 7.39549C5.36074 6.60513 4.01032 5.43868 3 4.00005C3 4.00005 -1 13 8 17C5.94053 18.398 3.48716 19.099 1 19C10 24 21 19 21 7.50005C20.9991 7.2215 20.9723 6.94364 20.92 6.67005C21.9406 5.66354 22.6608 4.39276 23 3.00005V3.00005Z' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>",
		"linkedin": "<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8V8Z' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /><path d='M6 9H2V21H6V9Z' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /><path d='M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /></svg>",
		"youtube": "<svg width='31' height='31' fill='none' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill='none' d='M29.1123 8.29258C28.9589 7.67957 28.6464 7.1179 28.2064 6.66431C27.7664 6.21073 27.2145 5.88128 26.6065 5.70925C24.3848 5.16675 15.4981 5.16675 15.4981 5.16675C15.4981 5.16675 6.61147 5.16675 4.3898 5.76091C3.78174 5.93294 3.22985 6.26239 2.78987 6.71598C2.34989 7.16957 2.0374 7.73124 1.88397 8.34425C1.47737 10.5989 1.27848 12.8861 1.2898 15.1772C1.27531 17.4854 1.47421 19.7901 1.88397 22.0617C2.05312 22.6557 2.37261 23.196 2.81157 23.6304C3.25053 24.0649 3.79411 24.3788 4.3898 24.5417C6.61147 25.1359 15.4981 25.1359 15.4981 25.1359C15.4981 25.1359 24.3848 25.1359 26.6065 24.5417C27.2145 24.3697 27.7664 24.0403 28.2064 23.5867C28.6464 23.1331 28.9589 22.5714 29.1123 21.9584C29.5158 19.7207 29.7146 17.4509 29.7065 15.1772C29.721 12.8689 29.5221 10.5642 29.1123 8.29258V8.29258Z' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M12.5938 19.4009L20.0208 15.1771L12.5938 10.9534V19.4009Z' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>",
		"instagram": "<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill='none' d='M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M15.9997 11.3701C16.1231 12.2023 15.981 13.0523 15.5935 13.7991C15.206 14.5459 14.5929 15.1515 13.8413 15.5297C13.0898 15.908 12.2382 16.0397 11.4075 15.906C10.5768 15.7723 9.80947 15.3801 9.21455 14.7852C8.61962 14.1903 8.22744 13.4229 8.09377 12.5923C7.96011 11.7616 8.09177 10.91 8.47003 10.1584C8.84829 9.40691 9.45389 8.7938 10.2007 8.4063C10.9475 8.0188 11.7975 7.87665 12.6297 8.00006C13.4786 8.12594 14.2646 8.52152 14.8714 9.12836C15.4782 9.73521 15.8738 10.5211 15.9997 11.3701Z' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M17.5 6.5H17.51' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>"
    }

    return (
        <ul className="social-links footer-social-icons__social-links">
            <li className="social-links__item">
                <a 
                    href="https://www.facebook.com/Ironbulltanks/" 
                    className="social-links__link"
                    title="Facebook"
                    target="_blank" 
                    rel="nofollow noopener noreferrer"
                    dangerouslySetInnerHTML={{ __html: icons['facebook'] }}
                >   
                </a>
            </li>
            <li className="social-links__item">
                <a 
                    href="https://twitter.com/iron_bull_tanks" 
                    className="social-links__link"
                    title="Twitter"
                    target="_blank" 
                    rel="nofollow noopener noreferrer"
                    dangerouslySetInnerHTML={{ __html: icons['twitter'] }}
                >   
                </a>
            </li>
            <li className="social-links__item">
                <a 
                    href="https://www.linkedin.com/company/ironbull-tanks/" 
                    className="social-links__link"
                    title="LinkedIn"
                    target="_blank" 
                    rel="nofollow noopener noreferrer"
                    dangerouslySetInnerHTML={{ __html: icons['linkedin'] }}
                >   
                </a>
            </li>
            <li className="social-links__item">
                <a 
                    href="https://www.youtube.com/channel/UC3OKYjhagIn2J9rpNSQZFuQ" 
                    className="social-links__link"
                    title="Youtube"
                    target="_blank" 
                    rel="nofollow noopener noreferrer"
                    dangerouslySetInnerHTML={{ __html: icons['youtube'] }}
                >   
                </a>
            </li>
            <li className="social-links__item">
                <a 
                    href="https://www.instagram.com/ironbulltanks/" 
                    className="social-links__link"
                    title="Instagram"
                    target="_blank" 
                    rel="nofollow noopener noreferrer"
                    dangerouslySetInnerHTML={{ __html: icons['instagram'] }}
                >   
                </a>
            </li>
        </ul>
    );
}