import React from "react";
import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";
import { Layout } from "antd";
import { Content, Footer } from "antd/lib/layout/layout";

import { PrimaryHeaderComponent } from "../../../common";

import { AuthRoutes } from "./auth.routes";
import LoginPageComponent from "../login/loginPage.component";
import RegisterPageComponent from "../register/registerPage.component";
import AdminLoginPageComponent from "../admin-login/adminLoginPage.component";


function AuthRouter() {

  const { path } = useRouteMatch();

  let routes = [
    {
      path: AuthRoutes.LOGIN.path,
      component: LoginPageComponent,
      roles: [],
      exact: true,
    },
    {
      path: AuthRoutes.REGISTER.path,
      component: RegisterPageComponent,
      roles: [],
      exact: true,
    },
    {
      path: AuthRoutes.ADMIN_LOGIN.path,
      component: AdminLoginPageComponent,
      roles: [],
      exact: true,
    },
  ];

  return (
    <Layout className="layout">
      <PrimaryHeaderComponent></PrimaryHeaderComponent>
      <Content className="content site-container w100-h100">
        <Switch>
          {routes.map((route, index) => (<Route exact={route.exact} path={`${path}/${route.path}`} key={index}> <route.component /> </Route>))}
          <Redirect to={AuthRoutes.LOGIN.fullPath} />
        </Switch>
      </Content>
      <Footer></Footer>
    </Layout>
  );
}

export default AuthRouter;
