export * from './base/base.interface';
export * from './base/baseUpdate.interface';
export * from './base/reducerAction.interface';

export * from './auth/register.interface';
export * from './auth/login.interface';
export * from './user/user.interface';
export * from './user/userStats.interface';

export * from './game/game.interface';
export * from './gameTank/gameTank.interface';
export * from './tankType/tankType.interface';
export * from './tank/tank.interface';
export * from './team/team.interface';

export * from './occupation/occupation.interface';
export * from './reservation/reservation.interface';

export * from './tv';
export * from './system';

export * from './country/country.interface';

export * from './pageInfo/pageInfo.interface';
