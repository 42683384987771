import React, { useEffect, useState } from "react";
import moment from "moment";

function InfoPageComponent() {

  // const currentSystemState = useSelector((state: StoreStateInterface) => state.system.currentSystemState);
  // const me = useSelector((state: StoreStateInterface) => state.system.me);
  const [displayTimer, setDisplayTimer] = useState<string>('--:--');
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      let delta = 0;
      let nowTimestamp = moment().valueOf();
      let newtStateTime = moment().startOf('day').add(parseInt(moment().format('HH')) + 1, 'hours').valueOf();
      delta = (newtStateTime - nowTimestamp) / 1000;
      if (delta <= 0) delta = 0;

      setDisplayTimer(moment().startOf('day').add(delta, 'seconds').format('mm:ss'));

      if (delta > 0) setCounter(counter + 1);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [counter]);

  return (
    <div className="info-page">
      <img
        src={`${process.env.REACT_APP_WP_URL}/wp-content/themes/b4b/public/logo.svg`}
        alt={'IronBull'}
        title={'IronBull'}
      />
      <div className="timer">
        <div className="timer__label">SLJEDEĆA IGRA</div>
        <div className="timer__value">{displayTimer}</div>
      </div>
      {/* {me?.name} <br />
      {currentSystemState?.gameStatus?.state} */}
    </div>
  );
}

export default InfoPageComponent;
