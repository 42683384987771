import i18n from "../services/translate.service";

export const TeamConstant = () => { return {
    AXIS: { id: 1, name: "axis", label: i18n.translate("team.axis")},
    ALLIES: { id: 2, name: "allies", label: i18n.translate("team.allies")},
}};

export function getTeamById(id: number) {
    let element = Object.entries(TeamConstant()).find((e: any) => { return e[1].id === id });
    return element;
}

export function getTeamLabelById(id: number) {
    let element = getTeamById(id);
    return element ? element[1].label : null;
}