import { Badge, Button, notification, Select, Table } from "antd";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import TankLogo from "../../../../assets/images/logo_tank.svg";
import { GameInterface, GameTankInterface, i18n, StoreStateInterface, addUserToOccupationAction, getGameByIdAction, setGameStatusAction, getGameStatusTextById, getReservationByNumberAction, ReservationModalComponent, removeAdminReservationAction, NavigationService, DisplayDateFormat, TimeNoSecFormat, getOccupationStatusBadgeAndLabel, OccupationStatusConstant, ColorConstant, GameStatusConstant, ReservationStatusConstant, getUsersAction, EditPositionsModalComponent, removeUserFromOccupationAction, createAdminOccupationAction } from "../../../../common";
import { useParams } from "react-router-dom";
import Title from "antd/lib/typography/Title";
import { AdminRoutes } from "../../_router/admin.routes";
import moment from "moment";
import { FaPen } from "react-icons/fa";
import { Subject, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { GameTankStatusConstant, getGameTankStatusBadgeAndLabel } from "../../../../common/constants/gameTankStatus.constant";

function GamePageComponent() {

    const params = useParams<{gameId?: string}>();
    const gameId = parseInt(params.gameId!);

    const game = useSelector((state: StoreStateInterface) => state.game.games.find((game: GameInterface) => game.gameId === gameId));
    
    const users = useSelector((state: StoreStateInterface) => state.user.users);

    const adminReservation = useSelector((state: StoreStateInterface) => state.reservation.adminReservation);

    const dispatch = useDispatch();

    const [isGameFinished, setIsGameFinished] = useState<boolean>(false);

    const [isGamePlayed, setIsGamePlayed] = useState<boolean>(false);

    const [isEditPositionsModalVisible, setIsEditPositionsModalVisible] = useState<boolean>(false);
    
    const debouncer: Subject<string> = new Subject<string>();

    useEffect(() => {
        const debounced = debouncer.pipe(debounce(() => interval(500)));
        const subscription = debounced.subscribe((value) => getUsersByNickname(value));
        return () => subscription.unsubscribe();
    }, [debouncer]); 
        
    useEffect(() => {
        if (!game) dispatch(getGameByIdAction(gameId));
    }, [gameId]);

    useEffect(() => {
        if (game && game.status === GameStatusConstant().FINISHED.id) setIsGamePlayed(true);
        if (game && moment(game.from).isBefore(moment())) setIsGameFinished(true);
    }, [game])

    const getTableColumns: any = [
        {
            title: i18n.translate("admin.reservation.position"),
            key: 'tank.position',
            dataIndex: 'position',
            render: (dataIndex: string, tank: GameTankInterface) => `#${tank.position}`
        },
        {
            title: <img src={TankLogo} alt="tank" />,
            key: 'tankType',
            dataIndex: 'tankName',
            render: (dataIndex: string, tank: GameTankInterface) => `${tank.tankName}`
        },
        {
            title: i18n.translate("admin.reservation.playerOne"),
            key: 'playerOneName',
            dataIndex: 'playerOneName',
            render: (dataIndex: string, tank: GameTankInterface) => renderPlayerOne(tank)
        },
        {
            title: i18n.translate("admin.reservation.playerTwo"),
            key: 'playerTwoName',
            dataIndex: 'playerTwoName',
            render: (dataIndex: string, tank: GameTankInterface) => renderPlayerTwo(tank)
        },
        {
            title: i18n.translate("admin.reservation.reservationNumber"),
            key: 'reservationNumber',
            dataIndex: 'occupation.webReservationId',
            render: (dataIndex: string, tank: GameTankInterface) => tank.occupation && tank.occupation.webReservation && <Button type="link" onClick={() => dispatch(getReservationByNumberAction(tank.occupation.webReservation!.number))}>{tank.occupation.webReservation!.number}</Button>
        },
        {
            title: i18n.translate("admin.reservation.status"),
            key: 'status',
            dataIndex: 'tank.occupation.status',
            render: (dataIndex: string, tank: GameTankInterface) => renderOccupationStatus(tank)
        },

    ];

    function createOccupationClick(gameTank: GameTankInterface) {
        dispatch(createAdminOccupationAction(gameTank, game!));
    }

    function setGameStatusClick(status: number) {
        dispatch(setGameStatusAction(status, game!.gameId));
    }

    function onSelectInputChanged(value: string): void {
        debouncer.next(value);
    }

    function getUsersByNickname(nicknamePart: string) {
        if(nicknamePart.length > 0) dispatch(getUsersAction(nicknamePart));
    }

    function removeUserFromOccupationClick(occupationId: number, playerNumber: number) {
        dispatch(removeUserFromOccupationAction(occupationId, playerNumber, gameId));
    }

    function renderOccupationStatus(tank: GameTankInterface): React.ReactNode {
        if(tank.occupation) {
            if(tank.occupation.status === OccupationStatusConstant().RESERVED.id && tank.occupation.webReservation!.status === ReservationStatusConstant().IN_PROGRESS.id) {
                return <div><Badge className="statusBadge" color={ColorConstant.BLUE.hash} /> {i18n.translate("admin.game.inProgress")}</div>;
            } 
            return <div>{tank.occupation.status && getOccupationStatusBadgeAndLabel(tank.occupation.status)}</div>;
        }
        if(tank.status === GameTankStatusConstant().DISABLED.id) {
            return <div>{getGameTankStatusBadgeAndLabel(tank.status)}</div>;
        }
        if (isGameFinished) return null;
        return (
            <Button danger={!!!tank.status} type="link" onClick={() => createOccupationClick(tank)}>
                {tank.status ? i18n.translate("admin.game.occupy") : i18n.translate("admin.game.unavailable")}
            </Button>
        );
    }

    const renderTitleAndActions = (): React.ReactNode => {
        const renderTitle = (): React.ReactNode => {
            return (
                <div className="flex-row">
                    <Button type="link" onClick={() => NavigationService.navigate(`${AdminRoutes.GAMES.fullPath}`)}><Title level={2} className="heading__h2 heading--no-margin mr8">{i18n.translate("admin.game.allGames")}</Title></Button> 
                    <Title level={2} className="heading__h2 heading--no-margin"> /</Title>
                    <Button type="link" onClick={() => NavigationService.navigate(`${AdminRoutes.GAMES.fullPath}?date=${game!.from}`)}><Title level={2} className="heading__h2 heading--no-margin ml8 mr8">{moment(game!.from).format(DisplayDateFormat)}</Title></Button> 
                    <Title level={2} className="heading__h2 heading--no-margin"> /</Title>
                    <Button type="link"><Title level={2} className="heading__h2 heading--no-margin ml8">{moment(game!.from).format(TimeNoSecFormat)}</Title></Button>
                    <Title level={2} className="heading__h2 heading--no-margin ml16">{getGameStatusTextById(game!.status)}</Title>
                </div>
            )
        }

        const renderActions = (): React.ReactNode => {
            if (isGameFinished) return null;
            return (
                <div className="flex-row">
                    {game!.status !== GameStatusConstant().NOT_ACTIVE.id && <Button type="primary" className="item" onClick={() => setIsEditPositionsModalVisible(true)}>{i18n.translate("admin.game.editPositions")}</Button>}
                    {game!.status !== GameStatusConstant().NOT_ACTIVE.id && <Button type="primary" className="item" onClick={() => setGameStatusClick(0)}>{i18n.translate("admin.game.disableGame")}</Button>}
                    {game!.status === GameStatusConstant().NOT_ACTIVE.id && <Button type="primary" className="item" onClick={() => setGameStatusClick(1)}>{i18n.translate("admin.game.enableGame")}</Button>}
                    {game!.status !== GameStatusConstant().NOT_ACTIVE.id && game!.status !== GameStatusConstant().RESERVED_BY_ADMIN.id && <Button type="primary" className="item" onClick={() => setGameStatusClick(2)}>{i18n.translate("admin.game.reserveGame")}</Button>}
                    {game!.status === GameStatusConstant().RESERVED_BY_ADMIN.id && <Button type="primary" className="item" onClick={() => setGameStatusClick(1)}>{i18n.translate("admin.game.removeReservation")}</Button>}
                </div>
            )
        }

        return (
            <div className="flex-row space-between mb25">
                {renderTitle()}
                {renderActions()}
            </div>
        )
    }

    const renderGameTable = (): React.ReactNode => {
        let tanks = game!.tanks.sort((t1: GameTankInterface, t2: GameTankInterface) => {
            return t1.position > t2.position ? 1 : -1;
        })
        return <Table rowKey={(tank) => tank.position} columns={getTableColumns} dataSource={tanks} pagination={false}/>
    }

    const { Option } = Select;

    const players = users.map((user) => {
        return <Option value={user.email} key={user.id}>{user.nickname}</Option>
    });

    function addUserToOccupation(playerName: string, occupationId: number, email: string, playerNumber: number) {
        if(game!.tanks.find((tank: GameTankInterface) => tank.playerOneName === playerName || tank.playerTwoName === playerName )) {
            notification["error"]({ message: i18n.translate("admin.reservation.userExists") });
        } else { 
            dispatch(addUserToOccupationAction(occupationId, email, playerNumber, gameId));
        }
    }

    const renderPlayerOne = (tank: GameTankInterface): React.ReactNode => {
        if (tank.occupied && tank.occupation) {
            if (tank.occupation.playerOneName) {
                return <>{tank.occupation.playerOneName} {<FaPen style={{ cursor: 'pointer' }} className="ml8" size={'12px'} color={'#acacac'} onClick={() => removeUserFromOccupationClick(tank.occupation.occupationId, 1)} />}</>; 
            } else {
                return (
                <Select 
                    showSearch
                    style={{ width: 200 }}
                    placeholder={i18n.translate("occupation.playerOnePlaceholder")}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value: string, option: any) => addUserToOccupation(option.children, tank.occupation.occupationId, value, 1)}
                    onSearch={(value: string) => onSelectInputChanged(value)}	
                >
                    {players}
                </Select>
              )
            }
        } else {
            return '-';
        }
    }

    const renderPlayerTwo = (tank: GameTankInterface): React.ReactNode => {
        if (tank.occupied && tank.occupation) {
            if (tank.occupation.playerTwoName) {
                return <>{tank.occupation.playerTwoName} {<FaPen style={{ cursor: 'pointer' }} className="ml8" size={'12px'} color={'#acacac'} onClick={() => removeUserFromOccupationClick(tank.occupation.occupationId, 2)} />}</>; 
            } else {
                return (
                <Select 
                    showSearch
                    style={{ width: 200 }}
                    placeholder={i18n.translate("occupation.playerTwoPlaceholder")}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value: string, option: any) => addUserToOccupation(option.children, tank.occupation.occupationId, value, 2)}
                    onSearch={(value: string) => onSelectInputChanged(value)}	
                >
                    {players}
                </Select>
                )
            }
        } else {
            return '-';
        }
    }

    const renderReservationModal = (): React.ReactNode => {       
        return (
          <ReservationModalComponent visible={!!adminReservation} closeHandler={() => dispatch(removeAdminReservationAction())}></ReservationModalComponent>
        )    
    }

    const renderEditPositionsModal = (): React.ReactNode => {       
        return (
            <EditPositionsModalComponent gameId={gameId} visible={isEditPositionsModalVisible} closeHandler={() => setIsEditPositionsModalVisible(false)}></EditPositionsModalComponent>
        )    
    }

    if(!game) return null;
    return (
        <React.Fragment>
            <div className="gamesPage">
                {renderTitleAndActions()}
                {renderGameTable()}
                {renderReservationModal()}
                {renderEditPositionsModal()}
            </div>
        </React.Fragment>
    );

}

export default GamePageComponent;