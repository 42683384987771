import React from "react";
import { AdminLoginComponent } from "../../../common";

function AdminLoginPageComponent() {

  return (
    <div className="center-center w100-h100">
        <AdminLoginComponent></AdminLoginComponent>
    </div>
  );


}

export default AdminLoginPageComponent;
