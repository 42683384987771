import { Button, Select } from "antd";
import React, { useEffect, useState } from "react";

import { TeamIdEnum, TankResultInterface, i18n } from "../../../common";

function TestLogicPageComponent() {

// v3.0 2021-11-10

const tankConfig: any = {
  1: {
      "modelName": "Tiger",
      "tankInertia": 400,
      "maneuverInertia": 40,
      "rollingResistance": 20,
      "turretInertia": 25,
      "turretFriction": 10,
      "reloadSpeed": 3,
      "ammoCount": 20,
      "cannonDamage": 20,
      "hp": 1000,
      "roundPenetration": 8,
      "roundPower": 280,
      "panelStrength": {
          "ufp": 6,
          "lfp": 6,
          "hs": 4,
          "m": 7,
          "tf": 7,
          "ts": 5,
          "ss": 0,
      },
      "panels": ['hs', 'hs', 'ufp', 'ufp', 'lfp', 'hs', 'hs', '', 'ts', 'ts', 'ts', 'ss', 'ts', 'm']
  },
  2: {
      "modelName": "King Tiger",
      "tankInertia": 400,
      "maneuverInertia": 40,
      "rollingResistance": 20,
      "turretInertia": 25,
      "turretFriction": 10,
      "reloadSpeed": 3,
      "ammoCount": 20,
      "cannonDamage": 20,
      "hp": 1100,
      "roundPenetration": 9,
      "roundPower": 280,
      "panelStrength": {
          "ufp": 10,
          "lfp": 9,
          "hs": 5,
          "m": 10,
          "tf": 9,
          "ts": 6,
          "ss": 0,
      },
      "panels": ['hs', 'hs', 'hs', 'hs', 'lfp', 'ufp', 'hs', 'hs', '', 'tf', 'ts', 'ts', 'ss', 'ts', 'ts', 'hs']
  },
  3: {
      "modelName": "Panther",
      "tankInertia": 400,
      "maneuverInertia": 40,
      "rollingResistance": 20,
      "turretInertia": 25,
      "turretFriction": 10,
      "reloadSpeed": 3,
      "ammoCount": 20,
      "cannonDamage": 20,
      "hp": 1000,
      "roundPenetration": 8,
      "roundPower": 250,
      "panelStrength": {
          "ufp": 8,
          "lfp": 7,
          "hs": 3,
          "m": 6,
          "tf": 6,
          "ts": 4,
          "ss": 0,
      },
      "panels": ['ufp', 'lfp', 'hs', 'hs', 'hs', 'hs', 'hs', '', '', 'tf', 'ts', 'ts', 'ss', 'ts', 'ts', 'm']
  },
  4: {
      "modelName": "Panzer IV",
      "tankInertia": 400,
      "maneuverInertia": 40,
      "rollingResistance": 20,
      "turretInertia": 25,
      "turretFriction": 10,
      "reloadSpeed": 3,
      "ammoCount": 20,
      "cannonDamage": 20,
      "hp": 900,
      "roundPenetration": 7,
      "roundPower": 220,
      "panelStrength": {
          "ufp": 5,
          "lfp": 4,
          "hs": 4,
          "m": 5,
          "tf": 5,
          "ts": 4,
          "ss": 0,
      },
      "panels": ['ufp', 'lfp', 'hs', 'hs', 'hs', 'hs', 'hs', '', '', 'tf', 'ts', 'ts', 'ts', 'ts', 'ts', 'm']
  },
  5: {
      "modelName": "Jumbo",
      "tankInertia": 500,
      "maneuverInertia": 50,
      "rollingResistance": 20,
      "turretInertia": 15,
      "turretFriction": 10,
      "reloadSpeed": 5,
      "ammoCount": 20,
      "cannonDamage": 20,
      "hp": 1000,
      "roundPenetration": 7,
      "roundPower": 230,
      "panelStrength": {
          "ufp": 9,
          "lfp": 8,
          "hs": 6,
          "m": 8,
          "tf": 7,
          "ts": 6,
          "ss": 0,
      },
      "panels": ['', 'hs', 'hs', 'hs', 'hs', 'lfp', 'ufp', 'hs', '', '', 'ss', 'ts', 'ts', 'ts', 'ts', 'm']
  },
  6: {
      "modelName": "Easy Eight",
      "tankInertia": 500,
      "maneuverInertia": 50,
      "rollingResistance": 20,
      "turretInertia": 15,
      "turretFriction": 10,
      "reloadSpeed": 5,
      "ammoCount": 20,
      "cannonDamage": 20,
      "hp": 1000,
      "roundPenetration": 7,
      "roundPower": 230,
      "panelStrength": {
          "ufp": 7,
          "lfp": 6,
          "hs": 3,
          "m": 7,
          "tf": 6,
          "ts": 4,
          "ss": 0,
      },
      "panels": ['', 'hs', 'hs', 'hs', 'hs', 'lfp', 'ufp', 'hs', '', '', 'ss', 'ts', 'ts', 'ts', 'ts', 'm']
  },
  7: {
      "modelName": "Sherman",
      "tankInertia": 500,
      "maneuverInertia": 50,
      "rollingResistance": 20,
      "turretInertia": 15,
      "turretFriction": 10,
      "reloadSpeed": 5,
      "ammoCount": 20,
      "cannonDamage": 20,
      "hp": 900,
      "roundPenetration": 6,
      "roundPower": 220,
      "panelStrength": {
          "ufp": 6,
          "lfp": 6,
          "hs": 3,
          "m": 7,
          "tf": 6,
          "ts": 4,
          "ss": 0,
      },
      "panels": ['hs', 'hs', 'hs', 'hs', 'hs', 'ufp', 'hs', 'lfp', 'hs', 'tf', 'ts', 'hs', 'hs', 'ts', 'ts', 'm']
  },
  8: {
      "modelName": "Pershing",
      "tankInertia": 500,
      "maneuverInertia": 50,
      "rollingResistance": 20,
      "turretInertia": 15,
      "turretFriction": 10,
      "reloadSpeed": 5,
      "ammoCount": 20,
      "cannonDamage": 20,
      "hp": 1100,
      "roundPenetration": 9,
      "roundPower": 250,
      "panelStrength": {
          "ufp": 9,
          "lfp": 8,
          "hs": 6,
          "m": 8,
          "tf": 7,
          "ts": 6,
          "ss": 0,
      },
      "panels": ['hs', 'hs', 'hs', 'hs', 'hs', 'ufp', 'hs', 'lfp', 'hs', 'ts', 'ss', 'hs', 'hs', 'ts', 'ts', 'm']
  },
}

const tanksInit = [{"position":1,"tankType":1,"name":"Tiger 1","teamId":1,"teamName":"AXIS","playerOne":"tablet-user","playerTwo":"u1"},{"position":2,"tankType":2,"name":"Tiger 2","teamId":1,"teamName":"AXIS","playerOne":"u2","playerTwo":"u3"},{"position":3,"tankType":3,"name":"Panther","teamId":1,"teamName":"AXIS","playerOne":"u4","playerTwo":"u5"},{"position":4,"tankType":3,"name":"Panther","teamId":1,"teamName":"AXIS","playerOne":"u6","playerTwo":"u7"},{"position":5,"tankType":4,"name":"Panzer J4","teamId":1,"teamName":"AXIS","playerOne":"u8","playerTwo":"u9"},{"position":6,"tankType":4,"name":"Panzer J4","teamId":1,"teamName":"AXIS","playerOne":"u10","playerTwo":"u11"},{"position":7,"tankType":5,"name":"Sherman M4A3E2 Jumbo","teamId":2,"teamName":"ALLIES","playerOne":"u12","playerTwo":"u13"},{"position":8,"tankType":5,"name":"Sherman M4A3E2 Jumbo","teamId":2,"teamName":"ALLIES","playerOne":"u14","playerTwo":"u15"},{"position":9,"tankType":6,"name":"Sherman M4A3E8","teamId":2,"teamName":"ALLIES","playerOne":"u16","playerTwo":"u17"},{"position":10,"tankType":7,"name":"M4A1","teamId":2,"teamName":"ALLIES","playerOne":"u18","playerTwo":"u19"},{"position":11,"tankType":8,"name":"M26 Pershing","teamId":2,"teamName":"ALLIES","playerOne":"u20","playerTwo":"u21"},{"position":12,"tankType":8,"name":"M26 Pershing","teamId":2,"teamName":"ALLIES","playerOne":"u22","playerTwo":"u23"}]
const teams = [{"id":2,"name":"ALLIES"},{"id":1,"name":"AXIS"}]

  const [context, setContext] = useState<any>(null);

  const [events, setEvents] = useState<any[]>([]);

  const [tank1, setTank1] = useState<any>(1);
  const [tank2, setTank2] = useState<any>(7);
  const [panel, setPanel] = useState<any>(1);

  useEffect(() => {
    simulateGameLogicInit();
  }, []);

  const coeffConfig = {
    kills: 10,
    sec: 0.1,
    damageReceived: -0.01,
    damageDone: 0.03,
    shotsFired: 0.3,
    stayedAlive: 10,
    victoryRound: 20,
    victoryGame: 80,
}

const simulateGameLogicOnEventShot = () => {
  const event = {
    type: "shot",
    fired: {type: "fired", tank: tank1.toString(), timeMs: 56025},
    hit: {type: "hit", tank: tank2.toString(), panel: panel, timeMs: 56513}
  }

  console.log("before onEvent event: ", event);

  let ev: any[] = events;
  ev.push(event);
  setEvents(ev);

  let response = gameLogicOnEvent(context, event);
  setContext({...response.context});
  console.log("after onEvent tanks ", response.context.tanks);
  console.log("after onEvent context ", response.context);
  console.log("after onEvent events ", response.events);

  setEvents([...events, response.events]);

}

const simulateGameLogicOnEventRoundStarted = () => {
    const event = {
      type: "roundStarted"
    }

    console.log("before onEvent event: ", event);
  
    let ev: any[] = events;
    ev.push(event);
    setEvents(ev);
  
    let response = gameLogicOnEvent(context, event);
    setContext({...response.context});
    console.log("after onEvent tanks ", response.context.tanks);
    console.log("after onEvent context ", response.context);
    console.log("after onEvent events ", response.events);
  
    setEvents([...events, response.events]);
}

const simulateGameLogicOnEventRoundFinished = () => {
    const event = {
      type: "roundFinished"
    }

    console.log("before onEvent event: ", event);
  
    let ev: any[] = events;
    ev.push(event);
    setEvents(ev);
  
    let response = gameLogicOnEvent(context, event);
    setContext({...response.context});
    console.log("roundFinished after onEvent tanks ", response.context.tanks);
    console.log("roundFinished after onEvent context ", response.context);
    console.log("roundFinished after onEvent events ", response.events);
  
    setEvents([...events, response.events]);
}

const simulateGameLogicOnEventGameFinished = () => {
    const event = {
      type: "gameFinished"
    }

    console.log("before onEvent event: ", event);
  
    let ev: any[] = events;
    ev.push(event);
    setEvents(ev);
  
    let response = gameLogicOnEvent(context, event);
    setContext({...response.context});
    console.log("gameFinished after onEvent tanks ", response.context.tanks);
    console.log("gameFinished after onEvent context ", response.context);
    console.log("gameFinished after onEvent events ", response.events);
  
    setEvents([...events, response.events]);
}

const simulateGameLogicInit = () => {
  let response = gameLogicInit(context, tanksInit, teams);
  setContext(response);
  console.log(response);
}

const gameLogicInit = (context: any, tanksInit: any, teams: any) => {
    //console.log("gameLogicInit " + JSON.stringify(tanksInit));
    //console.log("gameLogicInit teams" + JSON.stringify(teams));
    let tanks = Object.fromEntries(
        eval(JSON.stringify(tanksInit)).map((tank: any) => {
            //console.log("tank with position " + tank.position);
            return [
                tank.position,
                {
                    id: tank.position,
                    name: tank.name,
                    points: 0,
                    kills: 0,
                    deaths: 0,
                    shotsFired: 0,
                    damageDone: 0,
                    damageReceived: 0,
                    hp: tankConfig[tank.tankType].hp,
                    ammo: tankConfig[tank.tankType].ammoCount,
                    secondsAlive: 0,
                    teamId: tank.teamId,
                    playerOne: tank.playerOne,
                    playerTwo: tank.playerTwo,
                    roundPenetration: tankConfig[tank.tankType].roundPenetration,
                    roundPower: tankConfig[tank.tankType].roundPower,
                    panels: tankConfig[tank.tankType].panels.map((panel: any) => tankConfig[tank.tankType].panelStrength[panel]),
                    config: {
                        ...tankConfig[tank.tankType]
                    }
                }
            ]
        }));

    // ukoliko kontekst ne postoji, postavi inicijalni
    if (context == null) {
        //određivanje tko igra prvi    
        let firstRoundAttackerTeamId = 1;
        if (Object.values(tanks).filter((tank: any) => tank.teamId == firstRoundAttackerTeamId).length < Object.values(tanks).filter((tank: any) => tank.teamId != firstRoundAttackerTeamId).length) {
            firstRoundAttackerTeamId = 2;
        }

        //postavljanje inicijalnog konteksta game logike
        context = {
            currentRound: { number: 0 },
            roundResults: [],
            gameResult: {},
            firstRoundAttackerTeamId: firstRoundAttackerTeamId
        }
    }

    // postavljanje trenutne runde i njenog konteksta
    let currentRoundNumber = context.currentRound.number + 1;

    context = {
        ...context,
        teams: teams,
        tanks: tanks,
        currentRound: {
            number: currentRoundNumber,
            attackerTeamId: (currentRoundNumber + context.firstRoundAttackerTeamId) % 2 == 0 ? 1 : 2,
            startTimestamp: 0,
        }
    };

    return context;
}

  const gameLogicOnEvent = (context: any, event: any) => {
    //console.log("handling game logic event with context: " + JSON.stringify(context) + " and event: " + event);
    let events = [];
    //console.log("Checking event type, is: " + event.type);
    switch (event.type) {
        case "fired":
        	let tank = context.tanks[event.tank];
        	tank.ammo --;
        	tank.shotsFired ++;

            tank.points = Math.ceil((tank.kills ? Math.exp(tank.kills) * coeffConfig.kills : 0) + tank.damageDone * coeffConfig.damageDone + tank.damageReceived * coeffConfig.damageReceived + tank.shotsFired * coeffConfig.shotsFired);

            break;
        case "shot":
            //console.log("shot event: fired " + event.fired + " -> hit: " + event.hit);
            //console.log("shot event: fired " + event.fired.type + " -> hit: " + event.hit.type);

            let srcTank = context.tanks[event.fired.tank];
            let destTank = context.tanks[event.hit.tank];
            //console.log("event: src " + JSON.stringify(srcTank) + " dest: " + JSON.stringify(destTank));

            if (destTank.deaths > 0) {
                break;
            }

            let kill = false;

            // izračun damage-a
            let damage = 0;

            if (srcTank.roundPenetration > destTank.panels[event.hit.panel]) {
              damage = destTank.panels[event.hit.panel] === 0 ? srcTank.roundPower * 3 : srcTank.roundPenetration - destTank.panels[event.hit.panel] > 2 ? srcTank.roundPower * 1.5 : srcTank.roundPower;
            } else if (srcTank.roundPenetration < destTank.panels[event.hit.panel]) {
              damage = 0;
            } else {
              damage = event.fired.timeMs % 2 == 0 ? 0 : srcTank.roundPower;
            }

            if (damage >= destTank.hp) {
                damage = destTank.hp;
                kill = true;
            }

            let friendlyFire = srcTank.teamId === destTank.teamId ? true : false;
        
            // tenk koji je primio štetu
            destTank.hp -= damage;
            destTank.damageReceived += damage;

            let hpPercentage = Math.round((destTank.hp / destTank.config.hp) * 100);
            events.push({type: "remoteDisplay", op: "setInfo", tankId: destTank.id, value: hpPercentage})

            // tenk koji je nanio štetu - ako je friendlyFire ne zbraja mu se damage
            if (!friendlyFire) {
                srcTank.damageDone += damage;
            }

            // ako je pogodak kill
            if (kill) {
                if (!friendlyFire) {
                    srcTank.kills ++;
                }
                destTank.deaths ++;

                // koliko je sekundi bio živ
                destTank.secondsAlive = Math.ceil(((new Date().getTime()) - context.currentRound.startTimestamp)/1000);

				events.push( {type: "liveUpdate", subtype: "destroy", source: {id: srcTank.id}, destination: {id: destTank.id} } );

                events.push({ type: "tankControl", tankId: destTank.id, op: "disableRemote" });
                events.push({ type: "tankControl", tankId: destTank.id, op: "lightsOff" });

                // ima li još koji živi igrač u ekipi ubijenog
                let foundAliveInTeam = Object.values(context.tanks).filter((tank: any) => tank.teamId == destTank.teamId).find((tank: any) => tank.hp > 0);

                if (!foundAliveInTeam) {
                    events.push( { type: "roundFinished" } );
                }
            } else {
                events.push( {type: "liveUpdate", subtype: "hit", source: {id: srcTank.id}, destination: {id: destTank.id} } );
            }

            //izračun bodova 
            srcTank.points = Math.ceil((srcTank.kills ? Math.exp(srcTank.kills) * coeffConfig.kills : 0) + srcTank.damageDone * coeffConfig.damageDone + srcTank.damageReceived * coeffConfig.damageReceived + srcTank.shotsFired * coeffConfig.shotsFired);
            destTank.points = Math.ceil((destTank.kills ? Math.exp(destTank.kills) * coeffConfig.kills : 0) + destTank.damageDone * coeffConfig.damageDone + destTank.damageReceived * coeffConfig.damageReceived + destTank.shotsFired * coeffConfig.shotsFired);		
				
            break;

        case "timer":
            var n = new Date().getTime();
            console.log("now: " + n + " context.lastTimer " + context.lastTimer);
            if (context.lastTimer == null || (n - context.lastTimer) > 60000) {
                console.log("setting timer");
        	    context.lastTimer = n;

				// 0th tank fires every 60 seconds!
        	   	//events.push( { type: "tankControl", op: "fire", tankId: context.tanks[Object.keys(context.tanks)[0]].id } );

                //events.push( {type: "liveUpdate", subtype: "custom", message: "Tank fired caused by timer!"} );

        	} else {
        	    console.log("NOT setting timer");
        	}
        	break;
        
        case "roundStarted":
            Object.keys(context.tanks).forEach((key: any) => {
                events.push({ type: "tankControl", tankId: key, op: "enableRemote" });
                events.push({ type: "remoteDisplay", op: "setInfo", tankId: key, value: 100 })
                events.push({ type: "tankControl", tankId: key, op: "lightsOn" });
            });

            context.currentRound.startTimestamp = new Date().getTime();

            break;
            
            
        case "roundFinished":

            events.push( {type: "liveUpdate", subtype: "custom", message: "KRAJ RUNDE"} );

        	let roundResult: any = {
        		round: context.currentRound.number,
        		tankResults: {},
        		teamResults: {}
        	};

        	// collect tank results
        	Object.entries(context.tanks).forEach((item: any) => {
                const tankId = item[0];
                const tank = item[1];
                const teamId = tank.teamId;
                const tankRole = context.currentRound.attackerTeamId == teamId ? 'ATTACKER' : 'DEFENDER';


                // određivanje pobjednika
                let deathsInMyTeam = Object.values(context.tanks).filter((t: any) => t.teamId == teamId && t.deaths > 0).length;
                let deathsInOpositeTeam = Object.values(context.tanks).filter((t: any) => t.teamId != teamId && t.deaths > 0).length;

                let victory: any = deathsInMyTeam < deathsInOpositeTeam || (deathsInMyTeam == deathsInOpositeTeam && tankRole == 'DEFENDER');

                // koliko su sekundi preživjeli oni koji su ostali živi na kraju runde
                if (tank.deaths == 0 && tank.secondsAlive == 0) {
                    tank.secondsAlive = Math.ceil(((new Date().getTime()) - context.currentRound.startTimestamp)/1000);
                }

                // finalno zbrajanje bodova na kraju runde
                tank.points = Math.ceil((tank.kills ? Math.exp(tank.kills) * coeffConfig.kills : 0) + tank.damageDone * coeffConfig.damageDone + tank.damageReceived * coeffConfig.damageReceived + tank.shotsFired * coeffConfig.shotsFired + tank.secondsAlive * coeffConfig.sec + (tank.deaths ^ 1) * coeffConfig.stayedAlive + victory * coeffConfig.victoryRound);

                console.log("kills: ", (tank.kills ? Math.exp(tank.kills) * coeffConfig.kills : 0), " damageDone ", tank.damageDone * coeffConfig.damageDone, " damageRec ", tank.damageReceived * coeffConfig.damageReceived, " sec: ", tank.secondsAlive * coeffConfig.sec, " stayedAlive: ", (tank.deaths ^ 1) * coeffConfig.stayedAlive, " victory ", victory * coeffConfig.victoryRound, " points: ", tank.points);

                roundResult.tankResults[tankId] = tank;

                if (!(teamId in roundResult.teamResults)) {
                    roundResult.teamResults[teamId] = {
                        role: tankRole,
                        victory: victory,
                    };
                }

                events.push({ type: "tankControl", tankId: tank.id, op: "disableRemote" });
                events.push({ type: "tankControl", tankId: tank.id, op: "lightsOff" });
                events.push({ type: "remoteDisplay", op: "setInfo", tankId: tank.id, value: 0 })
			});

        	context.roundResults.push(roundResult);
        	break;

        case "gameFinished":

            events.push( {type: "liveUpdate", subtype: "custom", message: "KRAJ IGRE"} );

            let gameResult: any = {
        		tankResults: {},
                teamResults: {},
        	};

            let roundScore: any = {
                1: { wins: 0, points: 0, deaths: 0},
                2: { wins: 0, points: 0, deaths: 0},
            }

            let gameWinnerTeam: any;

            // određivanje pobjednika
            context.roundResults.forEach((round: any) => {
                let roundWinner = parseInt(Object.entries(round.teamResults).find((team: any) => team[1].victory == true)![0]);
                roundScore[roundWinner].wins ++;

                Object.values(round.tankResults).forEach((tank: any) => {
                    roundScore[tank.teamId].points += tank.points;
                    roundScore[tank.teamId].deaths += tank.deaths;
                })
            });

            if (roundScore[1].wins != roundScore[2].wins) {
                gameWinnerTeam = roundScore[1].wins > roundScore[2].wins ? 1 : 2;
            } else {
                if (roundScore[1].points != roundScore[2].points) {
                    gameWinnerTeam = roundScore[1].points > roundScore[2].points ? 1 : 2;
                } else {
                    gameWinnerTeam = roundScore[1].deaths <= roundScore[2].deaths ? 1 : 2;
                }
            }

            gameResult.teamResults = {
                1: { 
                    victory: gameWinnerTeam == 1,
                    roundWins: roundScore[1].wins,
                    points: roundScore[1].points,
                    deaths: roundScore[1].deaths,
                },
                2: {
                    victory: gameWinnerTeam == 2,
                    roundWins: roundScore[2].wins,
                    points: roundScore[2].points,
                    deaths: roundScore[2].deaths,
                }
            }

            // zbrajanje bodova, kill-ova i death-ova po tenkovima
            Object.entries(context.tanks).forEach((item: any) => {
                const tankId = item[0];
                const tank = item[1];
                const teamId = tank.teamId;

                let roundPoints = 0;
                let kills = 0;
                let deaths = 0;

                context.roundResults.forEach((round: any) => {
                    roundPoints += round.tankResults[tankId].points;
                    kills += round.tankResults[tankId].kills;
                    deaths += round.tankResults[tankId].deaths;
                })

                let victory: any = teamId == gameWinnerTeam ? true : false;
                let additionalPoints = victory * coeffConfig.victoryGame;
                let totalPoints = roundPoints + additionalPoints;

                gameResult.tankResults[tankId] = {
                    id: tankId,
                    roundPoints,
                    additionalPoints,
                    totalPoints,
                    kills,
                    deaths,
                    victory,
                    teamId,
                    name: tank.name,
                    playerOne: tank.playerOne,
                    playerTwo: tank.playerTwo,
                };
            })

            context.gameResult = gameResult;

            console.log(context);
            break;
 
    }

    return {context, events};
}

const renderTest = () : React.ReactNode => {
    return (
      <div>
        <div style={{ marginBottom: 15 }}>
            <Button style={{ marginRight: 15 }} type="primary" onClick={() => simulateGameLogicInit()}>gameLogicInit</Button>
            <Button type="primary" onClick={() => simulateGameLogicOnEventRoundStarted()}>gameLogicOnEvent roundStarted</Button>
        </div>
        <div style={{ marginBottom: 15}}>
          <Select style={{ marginRight: 15 }} defaultValue={tank1} onChange={(value: string) => setTank1(value)}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((tank: any) => {return <Select.Option key={tank} value={tank}>{tank}</Select.Option>})}
          </Select>
          <Select style={{ marginRight: 15 }} defaultValue={tank2} onChange={(value: string) => setTank2(value)}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((tank: any) => {return <Select.Option key={tank} value={tank}>{tank}</Select.Option>})}
          </Select>
          <Select style={{ marginRight: 15 }} defaultValue={panel} onChange={(value: string) => setPanel(value)}>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((panel: any) => {return <Select.Option key={panel} value={panel}>{panel}</Select.Option>})}
          </Select>
          <Button type="primary" onClick={() => simulateGameLogicOnEventShot()}>gameLogicOnEvent shot</Button>
        </div>
        <div style={{ marginBottom: 15 }}>
            <Button style={{ marginRight: 15 }} type="primary" onClick={() => simulateGameLogicOnEventRoundFinished()}>gameLogicOnEvent roundFinished</Button>
            <Button type="primary" onClick={() => simulateGameLogicOnEventGameFinished()}>gameLogicOnEvent gameFinished</Button>
        </div>
      </div>
    )
  }

  const renderGameStatus = (): React.ReactNode => {
    if (!context) return null;

    let getStatusText = (type: TeamIdEnum): string => {
      return context.currentRound.attackerTeamId === type ? 'ATTACKER' : 'DEFENDER';
    };

    let currentRound = context.currentRound.number;

    // === TIMELINE ===
    let timeline: Array<{ roundNumber: number, result?: string; timer?: string; }> = [];
    context.rounds?.forEach((round: any, index: any) => {
      let timelineItem: any = {
        roundNumber: index + 1,
        result: undefined,
        timer: undefined,
      };

      switch (round.status) {
        case 'PREPARED':
          timelineItem.timer = '11:00';
          break;
        case 'IN PROGRESS':
          
          break;
        case 'ENDED':
          let gameResults = context.results[index];
          let result: [number, number] = [0, 0];
          if (gameResults) {
            Object.keys(gameResults.tankResults).forEach((tankKey: any) => {
              let tankResult: TankResultInterface = gameResults.tankResults[tankKey];
              let tank = context.tanks[tankKey];
              if (tank.teamId === TeamIdEnum.AXIS) {
                result[0] = result[0] + tankResult.kills;
              } else {
                result[1] = result[1] + tankResult.kills;
              }
            });
          }
          timelineItem.result = `${result[0]}:${result[1]}`;
          
          break;
      }

      timeline.push(timelineItem);
    });

    // === GAME TABLE ===
    let result: [number, number] = [0, 0];
    let axisTableData: Array<TankResultInterface> = [];
    let alliesTableData: Array<TankResultInterface> = [];

    Object.values(context.tanks).forEach((tank: any) => {
      if (tank.teamId === TeamIdEnum.AXIS) {
        axisTableData.push(tank);
        result[0] = result[0] + tank.kills;
      } else {
        alliesTableData.push(tank);
        result[1] = result[1] + tank.kills;
      }
    });

    return (
      <div className="game-status">

        <div className="game-status-top">
          <div className="game-status-top__left title-subtitle">
            <div className="s40 g">Runda {currentRound}</div>
          </div>
          <div className="game-status-top__right">
            {timeline.map((tl, i) => (
              <div key={i} className={`box title-subtitle ${tl.timer ? 'blur' : ''}`}>
                <div className="s20 g">Runda {tl.roundNumber}</div>
                {tl.result && (<div className="s40 o">{tl.result}</div>)}
                {tl.timer && (<div className="s40 o">{tl.timer} <span className="min">min</span></div>)}
                {!tl.result && !tl.timer && (<div className="s40 o"><img src={'./static/images/logo_tank_yellow.svg'} alt="" /></div>)}
              </div>
            ))}
          </div>
        </div>

        <div className="game-status-bottom">
          <div className="spliter spliter--end">
            <div className={`title-box`}>
              <span>{getStatusText(TeamIdEnum.AXIS)}</span>
              <div><img src={'./static/images/logo_axis.svg'} alt="" /> {i18n.translate("team.axis")}</div>
            </div>
            <div className="title-subtitle">
              <div className="s24 g">REZULTAT</div>
              <div className="s60 o">{`${result[0]}:${result[1]}`}</div>
            </div>
            <div className={`title-box`}>
              <span>{getStatusText(TeamIdEnum.ALLIES)}</span>
              <div><img src={'./static/images/logo_allies.svg'} alt="" /> {i18n.translate("team.allies")}</div>
            </div>
          </div>
          <div className="spliter">
            {renderTable(TeamIdEnum.AXIS, axisTableData)}
            {renderTable(TeamIdEnum.ALLIES, alliesTableData)}
          </div>
        </div>
      </div>
    );
  }

  const renderTable = (type: TeamIdEnum, data: Array<TankResultInterface>): React.ReactNode => {

    return (
      <div className={`statistic-table ${type === TeamIdEnum.AXIS ? 'left' : 'right'}`}>
        <div className="statistic-table__row statistic-table__row--header">
          <div className="statistic-table__cell"><img src={'./static/images/logo_tank.svg'} alt="" /></div>
          <div className="statistic-table__cell"><img src={'./static/images/logo_user.svg'} alt="" /></div>
          {<div className="statistic-table__cell statistic-table__cell--80">HP</div>}
          {<div className="statistic-table__cell statistic-table__cell--80">Points</div>}
          {<div className="statistic-table__cell statistic-table__cell--80">Damage Done</div>}
          {<div className="statistic-table__cell statistic-table__cell--80">Damage Received</div>}
          <div className="statistic-table__cell statistic-table__cell--80">KILLS</div>
          <div className="statistic-table__cell statistic-table__cell--80">Deaths</div>
        </div>
        {data.map((li, i) => (
          <div key={i} className="statistic-table__row">
            <div className="statistic-table__cell">{li.name}</div>
            <div className="statistic-table__cell">
              <div>{li.playerOne}</div>
              <div>{li.playerTwo}</div>
            </div>
            {<div className="statistic-table__cell statistic-table__cell--80">{li.hp}</div>}
            {<div className="statistic-table__cell statistic-table__cell--80">{li.points}</div>}
            {<div className="statistic-table__cell statistic-table__cell--80">{li.damageDone}</div>}
            {<div className="statistic-table__cell statistic-table__cell--80">{li.damageReceived}</div>}
            <div className="statistic-table__cell statistic-table__cell--80">{li.kills}</div>
            <div className="statistic-table__cell statistic-table__cell--80">{li.deaths}</div>
          </div>
        ))}
      </div>
    );
  }

  const renderEvents = (): React.ReactNode => {
      return (
          <div>
              {events.map((event: any, index: any) => {return (<div key={index}>{JSON.stringify(event)}</div>)})}
          </div>
      )
  }

  return (
    <React.Fragment>
      <div className="match-game-page" style={{ display: 'block' }}>
        <div>{renderTest()}</div>
        <div>{renderGameStatus()}</div>
        <div>{renderEvents()}</div>
      </div>
    </React.Fragment>
  );

}

export default TestLogicPageComponent;
