import { Dispatch } from "redux";
import { TanksResponseInterface } from "../../interfaces";
import { TankService } from "../../api/tank.service";
import { TankConstants } from "./tank.constants";
import { notification } from "antd";
import { i18n } from "../../services";


export function getTanksAction() {
    return (dispatch: Dispatch) => {
        return TankService.getTanks().subscribe(
            (response: TanksResponseInterface) => {
                dispatch({
                    type: TankConstants.GET_TANKS_SUCCESS,
                    payload: response.data
                });
            },
            (error) => {
                dispatch({
                    type: TankConstants.ERROR,
                    payload: error
                });
            }
        );
    };
}

export function setTankStatusAction(tankId: number, status: string) {
    return (dispatch: Dispatch) => {
        return TankService.setTankStatus(tankId, status).subscribe(
            (response: void) => {
                dispatch(getTanksAction() as any);
             },
            (error) => {
                if(error.status === 400) {
                    notification["error"]({ message: i18n.translate("errorCodes.400-tank") });
                  }
                dispatch({
                    type: TankConstants.ERROR,
                    payload: error
                });
            }
        );
    };
}

export function changeTankPositionAction(tankFromId: number, tankToId: number) {
    return (dispatch: Dispatch) => {
        return TankService.changeTankPosition(tankFromId, tankToId).subscribe(
            (response: void) => {
                dispatch(getTanksAction() as any);
            },
            (error) => {
                dispatch({
                    type: TankConstants.ERROR,
                    payload: error
                });
            }
        );
    };
}

export function changeRemoteStateAction(tankId: number, remoteState: string) {
    return (dispatch: Dispatch) => {
        return TankService.changeRemoteState(tankId, remoteState).subscribe(
            (response: void) => {},
            (error) => {
                dispatch({
                    type: TankConstants.ERROR,
                    payload: error
                });
            }
        );
    };
}

export function syncTanksAction() {
    return (dispatch: Dispatch) => {
        return TankService.syncTanks().subscribe(
            (response: void) => {},
            (error) => {
                dispatch({
                    type: TankConstants.ERROR,
                    payload: error
                });
            }
        );
    };
}

export function refreshTank(tankId: number) {
    return (dispatch: Dispatch) => {
        return TankService.getTankById(tankId).subscribe(
            (response: TanksResponseInterface) => {
                dispatch({
                    type: TankConstants.REFRESH_TANK,
                    payload: response.data
                });
            },
            (error) => {
                dispatch({
                    type: TankConstants.ERROR,
                    payload: error
                });
            }
        );
    };
}