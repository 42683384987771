import { Observable } from "rxjs";

import HttpFactory from "./abstract/abstract.api.service";
import { GameStatusInterface, GamesSystemPropertiesInterface } from "../interfaces";
import { apiRoutes } from "./routes";

export type GMState = 'START' | 'STOP' | 'END_GAME' | 'STOP_PREPARING_START_ROUND';

export class SystemService {
    static getGamesSystemProperties(): Observable<{ data: Array<GamesSystemPropertiesInterface> }> {
        return HttpFactory.GET(apiRoutes.gamesSystemProperties);
    }

    static setGamesSystemProperties(type: string, value: number): Observable<{ data: Array<GamesSystemPropertiesInterface> }> {
        let url = apiRoutes.gamesSystemPropertiesChange.replace('{type}', type);
        let payload = { value };
        return HttpFactory.POST(url, payload);
    }

    static getGamesManagerState(): Observable<GameStatusInterface> {
        return HttpFactory.GET(apiRoutes.gamesManagerState);
    }

    static changeGamesManagerState(newState: GMState): Observable<GameStatusInterface> {
        return HttpFactory.GET(apiRoutes.gamesManagerState.replace('state', newState));
    }

    static getGamesScript(): Observable<{ data: { script: string; } }> {
        return HttpFactory.GET(apiRoutes.gamesScript);
    }

    static setGamesScript(script: string): Observable<void> {
        var formData = new FormData();
        var blob = new Blob([script], { type: 'plain/text' });
        formData.append('file', blob, 'script.txt');
        return HttpFactory.POST(apiRoutes.gamesScriptChange, formData);
    }

    static startTestGame(): Observable<void> {
        return HttpFactory.POST(apiRoutes.testGame, {});
    }

    static getVersion(): Observable<any> {
        return HttpFactory.GET(apiRoutes.version);
    }

    static getTvSettings(): Observable<{ data: Array<GamesSystemPropertiesInterface> }> {
        return HttpFactory.GET(apiRoutes.tvSettings);
    }
}

