import { Button, Table, Typography, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import './profileLastGamesPage.style.scss';
import { i18n, StoreStateInterface, GamePlayedInterface, DisplayDateFormat, TimeNoSecFormat } from "../../../common";
import { getUserGamesPlayedAction } from "../../../common/redux";

function ProfileLastGamesPageComponent() {

  const { Title, Text } = Typography;

  const [openAll, setOpenAll] = useState<boolean>(false);

  const userId = useSelector((state: StoreStateInterface) => state.auth.user?.id);

  const playedGames = useSelector((state: StoreStateInterface) => state.user.userGamesPlayed);

  const dispatch = useDispatch();

  useEffect(() => {
    if(userId) {
      dispatch(getUserGamesPlayedAction(userId));
    } 
  }, [userId]);

  const getTableColumns: any = [
    {
      title: i18n.translate("game.game"),
      key: 'from',
      render: (game: GamePlayedInterface) => <Text strong>{moment(game.from).format(DisplayDateFormat)} {moment(game.from).format(TimeNoSecFormat)}</Text>
    },
    {
      title: i18n.translate("game.buddy"),
      key: 'buddy',
      render: (game: GamePlayedInterface) => <Text>{game.buddy.nickname}</Text>
    },    
    {
      title: i18n.translate("game.tank"),
      key: 'tank',
      render: (game: GamePlayedInterface) => <Text>{game.tank.name}</Text>
    },
    {
      title: i18n.translate("game.kd"),
      key: 'kd',
      render: (game: GamePlayedInterface) => <Text>{game.kills}/{game.deaths}</Text>
    },
    {
      title: i18n.translate("game.result"),
      key: 'result',
      render: (game: GamePlayedInterface) => <Text strong>{game.victory ? i18n.translate("game.win") : i18n.translate("game.lose")} {game.victories}:{game.defeats}</Text>
    },
    {
      title: i18n.translate("game.rank"),
      key: 'rank',
      render: (game: GamePlayedInterface) => <Text strong>{game.rank}</Text>
    },
    {
      title: i18n.translate("game.points"),
      key: 'points',
      render: (game: GamePlayedInterface) => <Text>{game.points}</Text>
    },
    // {
    //   title: '',
    //   key: 'action',
    //   render: (game: any) => <Button type="link"><RightOutlined /></Button>
    // }
  ];

  const renderGamesTable = (): React.ReactNode => {
    let displayedGames = openAll ? playedGames : playedGames.slice(0, 5);
    return <Table columns={getTableColumns} dataSource={displayedGames} pagination={false} scroll={{ x: 240 }} />
  }  

  return (
    <React.Fragment>
      <div className="rankPage">
        <Title level={2}>{i18n.translate("game.lastGames")}</Title>
        {renderGamesTable()}
        <Row justify="end">
          {
            openAll && <Button type="link" onClick={() => setOpenAll(false)}>{i18n.translate("game.seeLess")}</Button>
          }
          {
            !openAll && <Button type="link" onClick={() => setOpenAll(true)}>{i18n.translate("game.seeAll")}</Button>
          }
        </Row>        
      </div>
    </React.Fragment>
  );

}

export default ProfileLastGamesPageComponent;
