import { Dispatch } from 'redux';
import { SystemService, GMState } from '../../api';
import { SystemConstants } from './system.constants';

// Do we need API refres or we can us only websocket
// export function getCurrentSystemStatusAction() {
//   return (dispatch: Dispatch) => {
//     return SystemService.getGamesManagerState().subscribe(
//       (response: GameStatusInterface) => {
//         console.log(response);
//         dispatch({
//           type: SystemConstants.SYSTEM_GAMES_MANAGER_STATE,
//           payload: response
//         });
//       },
//       (error: Error) => {
//         console.log(error);
//       }
//     );
//   };
// }

export function startTestGameAction() {
  return (dispatch: Dispatch) => {
    return SystemService.startTestGame().subscribe(
      () => {},
      (error: Error) => {
        console.log(error);
      }
    );
  };
}

export function changeSystemStateAction(newState: GMState) {
  return (dispatch: Dispatch) => {
    return SystemService.changeGamesManagerState(newState).subscribe(
      () => {
        setTimeout(() => {
          dispatch({ type: SystemConstants.SYSTEM_GAMES_SET_SETTINGS_CHANGE_TIME });
        }, 1500); // Give a backend time to change a state
      },
      (error: Error) => {
        console.log(error);
      }
    );
  };
}

export function getGamesSystemProperties() {
  return (dispatch: Dispatch) => {
    return SystemService.getGamesSystemProperties().subscribe(
      resp => {
        dispatch({ type: SystemConstants.SYSTEM_GAMES_SET_SYSTEM_PROPERTIES, payload: resp.data });
      },
      (error: Error) => {
        console.log(error);
      }
    );
  };
}

export function setGamesSystemProperties(type: string, value: number) {
  return (dispatch: Dispatch) => {
    return SystemService.setGamesSystemProperties(type, value).subscribe(
      () => {
        dispatch(getGamesSystemProperties() as any);
      },
      (error: Error) => {
        console.log(error);
      }
    );
  };
}

export function getGamesScript() {
  return (dispatch: Dispatch) => {
    return SystemService.getGamesScript().subscribe(
      resp => {
        dispatch({ type: SystemConstants.SYSTEM_GAMES_SET_SCRIPT, payload: resp.data.script });
      },
      (error: Error) => {
        console.log(error);
      }
    );
  };
}

export function setGamesScript(script: string) {
  return (dispatch: Dispatch) => {
    return SystemService.setGamesScript(script).subscribe(
      () => {
        dispatch(getGamesScript() as any);
      },
      (error: Error) => {
        console.log(error);
      }
    );
  };
}
