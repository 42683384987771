import { Button, Divider } from "antd";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { DisplayDateFormat, GameInterface, GameTankInterface, i18n, NavigationService, StoreStateInterface, TimeNoSecFormat } from "../../../common";
import './reservationConfirmationPage.style.scss';
import TankLogo from "../../../assets/images/logo_tank.svg";
import { ReservationsRoutes } from "../_router/reservations.routes";


function ReservationConfirmationPageComponent() {

    const reservation = useSelector((state: StoreStateInterface) => state.reservation);

    function calculateTotalPrice(): number {
        let totalPrice: number = 0;
        reservation.reservationSummary!.game.forEach((game: GameInterface) => {
            totalPrice += game.tanks.length * game.price;
        })
        return totalPrice;
    }

    if (reservation.reservationSummary) 
        return (
            <div className="site-container reservationConfirmation"> 
                <Title level={2} className="heading__h2 heading--margin-50 heading--white text-center mt50 mb50">{i18n.translate("summary.thankYou").toUpperCase()}</Title>
                <Title level={4} className="heading__h4">{i18n.translate("summary.yourReservationNumber")}</Title>
                <Title level={1} className="heading__h1 text-center">{reservation.reservationSummary?.number.toString()}</Title>
                <Text>{i18n.translate("summary.total")} {calculateTotalPrice()},00 kn</Text>
                <Text>{i18n.translate("summary.reservationInfo")}</Text>
                <Text className="mb50">{i18n.translate("summary.reservationDuration")}</Text>
                <div className="">
                    {reservation.reservationSummary.game.length === 0 && 
                    <div>{i18n.translate("summary.noGames").toUpperCase()}</div>
                    }
                    {reservation.reservationSummary.game.map((game: GameInterface) => {
                    return (
                        <div className="item" key={game.gameId}>
                            <div className="date">{moment(game?.from).format(DisplayDateFormat)}</div>
                            <div className="time">{moment(game?.from).format(TimeNoSecFormat)}</div>
                            <div className="tank">
                                {game.tanks.map((tank: GameTankInterface) => {
                                    return (
                                        <div key={tank.gameTankId} className="tank mb8"><img src={TankLogo} alt="tank" />{` ${i18n.translate("tank.tank").toUpperCase()} #${tank.position} / ${tank.tankName}`}</div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                    })}
                </div>
                <Divider />
                <div className="confirmation-actions">
                    <Button ghost size={window.innerWidth > 480 ? "large" : "middle"} onClick={() => NavigationService.navigate(ReservationsRoutes.RESERVATIONS.fullPath)}>Vrati se na početni screen</Button>
                </div>
            </div>
        );
    else return <div></div>

}

export default ReservationConfirmationPageComponent;
