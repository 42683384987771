import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { CurrentGameInterface, GameStatusStateEnum, i18n, StoreStateInterface, TankResultInterface, TeamIdEnum } from "../../../common";

function SummmaryPageeComponent() {

  const [displayTimer, setDisplayTimer] = useState<string>('--:--');
  const [counter, setCounter] = useState(0);
  const [stats, setStats] = useState<StatsInterface>({
    globalResult: [0, 0],
    timeline: [],
    teamWin: '',
    results: []
  });
  const currentSystemState = useSelector((state: StoreStateInterface) => state.system.currentSystemState);

  // This is replaced with new logic - see use efect below
  // gameStatus.next.ts is unstable for now, so we will use next hour as refrence for counting
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     let delta = 0;

  //     if (currentSystemState && currentSystemState.gameStatus) {
  //       let nowTimestamp = moment().valueOf();
  //       let newtStateTime = currentSystemState.gameStatus.next.ts * 1000;
  //       delta = (newtStateTime - nowTimestamp) / 1000;
  //       if (delta <= 0) delta = 0;
  //     }

  //     setDisplayTimer(moment().startOf('day').add(delta, 'seconds').format('mm:ss'));

  //     if (delta > 0) setCounter(counter + 1);
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [counter, currentSystemState]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      let delta = 0;
      let nowTimestamp = moment().valueOf();
      let newtStateTime = moment().startOf('day').add(parseInt(moment().format('HH')) + 1, 'hours').valueOf();
      delta = (newtStateTime - nowTimestamp) / 1000;
      if (delta <= 0) delta = 0;

      setDisplayTimer(moment().startOf('day').add(delta, 'seconds').format('mm:ss'));

      if (delta > 0) setCounter(counter + 1);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [counter]);

  useEffect(() => {
    let newStats: StatsInterface = {
      globalResult: [0, 0],
      timeline: [],
      teamWin: '',
      results: []
    };

    let resultsForGame: CurrentGameInterface | undefined;

    if (currentSystemState && currentSystemState.gameStatus && currentSystemState.gameStatus.state === GameStatusStateEnum.StartedState && currentSystemState.previousGame && currentSystemState.previousGame.currentRound && currentSystemState.previousGame.currentRound) {
      resultsForGame = currentSystemState.previousGame;
    } else if (currentSystemState && currentSystemState.currentGame) {
      resultsForGame = currentSystemState.currentGame;
    }

    if (!resultsForGame) return;

    console.log(currentSystemState);

    resultsForGame.roundResults.forEach(round => {
      let result: [number, number] = [0, 0];

      Object.values(round.tankResults).forEach(tankResult => {
        if (tankResult.teamId === TeamIdEnum.AXIS) {
          result[1] = result[1] + tankResult.deaths;
        } else {
          result[0] = result[0] + tankResult.deaths;
        }

        let existingResultIndex = newStats.results.findIndex(x => x.id === tankResult.id);
        if (existingResultIndex < 0) {
          newStats.results.push({ ...tankResult, rank: 0 });
        } else {
          newStats.results[existingResultIndex].kills = newStats.results[existingResultIndex].kills + tankResult.kills;
          newStats.results[existingResultIndex].points = newStats.results[existingResultIndex].points + tankResult.points;
        }
      });

      if (result[0] > result[1] || (result[0] === result[1] && round.teamResults[TeamIdEnum.AXIS].role === 'DEFENDER')) {
        newStats.globalResult = [newStats.globalResult[0] + 1, newStats.globalResult[1]];
      } else {
        newStats.globalResult = [newStats.globalResult[0], newStats.globalResult[1] + 1];
      }

      newStats.timeline.push(result);
    });

    if (newStats.globalResult[0] > newStats.globalResult[1]) newStats.teamWin = 'left';
    else if (newStats.globalResult[0] < newStats.globalResult[1]) newStats.teamWin = 'right';
    else newStats.teamWin = '';

    newStats.results.sort((x, y) => y.points - x.points);
    newStats.results = newStats.results.map((item, i) => ({ ...item, rank: i + 1 }));

    setStats(newStats);
    console.log(newStats);
  }, [currentSystemState]);

  const renderLeft = (): React.ReactFragment => {
    return (
      <div className="section-left">
        <div className="box">
          <div className="title-subtitle">
            <div className="s32">TOTAL</div>
            <div className="s100">{`${stats.globalResult[0]}:${stats.globalResult[1]}`}</div>
          </div>
          <div className="name">
            <div className={`team ${stats.teamWin === '' ? 'draw' : ''}`}>
              {stats.teamWin === 'left' && (<span><img src={'./static/images/logo_axis_black.svg'} alt="" /> {i18n.translate("team.axis")}</span>)}
              {stats.teamWin === 'right' && (<span><img src={'./static/images/logo_allies_black.svg'} alt="" /> {i18n.translate("team.allies")}</span>)}
              {stats.teamWin === '' && (<span><img src={'./static/images/logo_tank_black.svg'} alt="" /></span>)}
            </div>

            {stats.teamWin !== '' && (<div className="status">POBJEDA</div>)}
            {stats.teamWin === '' && (<div className="status">IZJEDNAČENO</div>)}
          </div>
        </div>

        <div className="stats">
          {renderTableLeft()}
        </div>
      </div>
    );
  }

  const renderTableLeft = (): React.ReactFragment => {
    return (
      <div className={`statistic-table`}>
        <div className="statistic-table__row statistic-table__row--header">
          <div className="statistic-table__cell statistic-table__cell--50"></div>
          <div className="statistic-table__cell">TENK</div>
          <div className="statistic-table__cell statistic-table__cell--80">BOD.</div>
          <div className="statistic-table__cell statistic-table__cell--80">KIL.</div>
          <div className="statistic-table__cell">IGRAČ 1</div>
          <div className="statistic-table__cell">IGRAČ 2</div>
        </div>
        {stats.results.map((li, i) => (
          <div key={i} className={`statistic-table__row ${li.rank === 1 ? 'highlight' : ''}`}>
            <div className="statistic-table__cell statistic-table__cell--50 orange">#{li.rank}</div>
            <div className="statistic-table__cell">
              <div className="statistic-table__cell--one-line">
                <img src={`./static/images/${li.teamId === TeamIdEnum.AXIS ? 'logo_axis.svg' : 'logo_allies.svg'}`} alt="" /> {li.name}
              </div>
            </div>
            <div className="statistic-table__cell statistic-table__cell--80">{li.points}</div>
            <div className="statistic-table__cell statistic-table__cell--80">{li.kills}</div>
            <div className="statistic-table__cell orange">{li.playerOne}</div>
            <div className="statistic-table__cell orange">{li.playerTwo}</div>
          </div>
        ))}

      </div>
    );
  }

  const renderRight = (): React.ReactFragment => {
    return (
      <div className="section-right">
        <div className="section-up">
          <div className="banner">
            {stats.timeline.map((tl, i) => (
              <div key={i} className={`title-subtitle`}>
                <div className="s20 g">RUNDA {i + 1}</div>
                <div className="s40 o">{`${tl[0]}:${tl[1]}`}</div>
              </div>
            ))}
          </div>

          <div className="spliter">
            {renderTable(TeamIdEnum.AXIS, stats.results.filter(x => x.teamId === TeamIdEnum.AXIS))}
            {renderTable(TeamIdEnum.ALLIES, stats.results.filter(x => x.teamId === TeamIdEnum.ALLIES))}
          </div>
        </div>

        <div className="section-down">
          <div className="title">SLJEDEĆI TERMIN ZA:</div>
          <div className="time">{displayTimer} min</div>
        </div>
      </div>
    );
  }

  const renderTable = (type: TeamIdEnum, data: Array<RankingInterface>): React.ReactFragment => {
    return (
      <div className={`statistic-table ${type === TeamIdEnum.AXIS ? 'left' : 'right'}`}>

        <div className="statistic-table__title">
          <img src={'./static/images/' + (type === TeamIdEnum.AXIS ? 'logo_axis.svg' : 'logo_allies.svg')} alt="" />
          {type === TeamIdEnum.AXIS ? i18n.translate("team.axis") : i18n.translate("team.allies")}
        </div>

        <div className="statistic-table__row statistic-table__row--header">
          <div className="statistic-table__cell statistic-table__cell--50"></div>
          <div className="statistic-table__cell"><img src={'./static/images/logo_tank.svg'} alt="" /></div>
          <div className="statistic-table__cell statistic-table__cell--80">BODOVI</div>
          <div className="statistic-table__cell statistic-table__cell--80">KILOVI</div>
        </div>
        {data.map((li, i) => (
          <div key={i} className={`statistic-table__row ${li.rank === 1 ? 'highlight' : ''}`}>
            <div className="statistic-table__cell statistic-table__cell--50 orange">#{li.rank}</div>
            <div className="statistic-table__cell">
              {li.name}
              <div className="players">{li.playerOne} / {li.playerTwo}</div>
            </div>
            <div className="statistic-table__cell statistic-table__cell--80">{li.points}</div>
            <div className="statistic-table__cell statistic-table__cell--80">{li.kills}</div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="summmary-page">
      {renderLeft()}
      {renderRight()}
    </div>
  );
}

export default SummmaryPageeComponent;

// === PRIVATE INTERFACE ===

interface StatsInterface {
  globalResult: [number, number],
  timeline: Array<[number, number]>;
  teamWin: 'left' | 'right' | '',
  results: Array<RankingInterface>
}
interface RankingInterface extends TankResultInterface {
  rank: number;
}
