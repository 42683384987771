import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BlockPicker } from 'react-color';
import { Modal, Button, Form, Input, Avatar } from 'antd';

import './changeAvatarModal.style.scss';
import { i18n } from "../../services";
import { StoreStateInterface } from "../../redux";
import { UserInterface } from "../../../common";
import { getUserAction, updateUserAction } from "../../../common/redux";

export default function ChangeAvatarModalComponent(props: {visible: boolean, closeHandler: any}) {

    const [backgroundPicker, setBackgroundPicker] = useState<boolean>(false);

    const [colorPicker, setColorPicker] = useState<boolean>(false);

    const userId = useSelector((state: StoreStateInterface) => state.auth.user!.id);

    const user = useSelector((state: StoreStateInterface) => state.user.user);

    const [form] = Form.useForm();

    const dispatch = useDispatch();    

    useEffect(() => {
        if(userId) dispatch(getUserAction(userId));
    }, [userId]);

    useEffect(() => {
        if(user){
            fillForm();
        }
    }, [user]);

    function fillForm(){
        form.setFieldsValue({
            initials: user!.avatarJson ? user!.avatarJson.initials : user!.nickname.substr(0,2),
            background: user!.avatarJson ? user!.avatarJson.background : '#39402B',
            color: user!.avatarJson ? user!.avatarJson.color : '#f56a00',
        });
    }

    function handleChangeBackground(color: string){
        form.setFieldsValue({
            background: color
        });
        setBackgroundPicker(false);
    };

    function handleChangeColor(color: string){
        form.setFieldsValue({
            color: color
        });
        setColorPicker(false);
    };

    function onFormFinish(values: any): void {
        let avatarJsonData = {
            initials: values.initials,
            background: values.background,
            color: values.color
        };
        let model: UserInterface = {
            id: user!.id,
            name: user!.name,
            email: user!.email,
            birthday: user!.birthday,
            nickname: user!.nickname, 
            newsletter: user!.newsletter,
            countryCode: user!.country?.code,
            avatarJson: avatarJsonData
        };
        dispatch(updateUserAction(model));
        onClose();
    }

    function onClose(): void {
        fillForm();
        setBackgroundPicker(false);
        setColorPicker(false);        
        props.closeHandler();
    }

    const renderAvatar = (): React.ReactNode => {
        return (
          <div className="center-center">
             <Avatar size={80} style={{ fontSize: '40px', color: form.getFieldValue("color"), backgroundColor: form.getFieldValue("background") }}>{form.getFieldValue("initials")}</Avatar>     
          </div>      
        ) 
      }
    
    return (
        <Modal 
          title={i18n.translate("changeAvatar.title")} 
          onCancel={() => onClose()} 
          visible={props.visible} 
          footer={null} 
          width="120"
          centered
          >            
            <Form
                layout="vertical"
                form={form}
                onFinish={(values: any) => onFormFinish(values)}
                >
                <Form.Item
                    name="avatar"
                >
                    {renderAvatar()}
                </Form.Item>

                <Form.Item
                    label={i18n.translate("changeAvatar.initials")}
                    name="initials"
                    rules={[{ required: true, message: i18n.translate("changeAvatar.validationInitials") }]}
                >
                    <Input maxLength={2} minLength={2} />
                </Form.Item>

                <Form.Item
                    label={i18n.translate("changeAvatar.background")}
                    name="background"
                >
                    <div>
                        <div className="swatch" onClick={() => setBackgroundPicker(true)}>
                            <div className="color" style={{background: form.getFieldValue("background")}} />
                        </div>
                        { backgroundPicker ? 
                            <div className="popover">
                                <div className="cover" onClick={() => setBackgroundPicker(false)}/>
                                <BlockPicker color={form.getFieldValue("background")} onChange={(color) => handleChangeBackground(color.hex)} />
                            </div>  
                        : null }
                    </div>                    
                </Form.Item>

                <Form.Item
                    label={i18n.translate("changeAvatar.color")}
                    name="color"
                >
                    <div>
                        <div className="swatch" onClick={() => setColorPicker(true)}>
                            <div className="color" style={{background: form.getFieldValue("color")}} />
                        </div>
                        { colorPicker ? 
                            <div className="popover">
                                <div className="cover" onClick={() => setColorPicker(false)}/>
                                <BlockPicker color={form.getFieldValue("color")} onChange={(color) => handleChangeColor(color.hex)} />
                            </div>  
                        : null }
                    </div>                    
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {i18n.translate("buttons.save").toUpperCase()}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}
