import { ClientType } from "../tv/client.interface";

export type TeamNameType = 'AXIS' | 'ALLIES';

export enum TeamIdEnum {
    AXIS = 1,
    ALLIES = 2
}

export const TeamIdStringToEnum = (name: TeamNameType | ClientType) => (name === 'AXIS' ? TeamIdEnum.AXIS : TeamIdEnum.ALLIES);

export interface TeamInterface {
    id: number;
    name: TeamNameType;
}