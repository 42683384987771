import { Input } from "antd";
import React, { useEffect, useState } from "react";

export function InputPinComponent(props: { value: string, onChange: (value: string) => void}) {

    interface InputsInterface {
        input1: string;
        input2: string;
        input3: string;
        input4: string;
    }

    const [state, setState] = useState<InputsInterface | any>({ 
        input1: '', 
        input2: '', 
        input3: '', 
        input4: '', 
    });

    const [counter, setCounter] = useState<number>(0);
    
    const inputs : InputsInterface | any = {} ;

    useEffect(() => {
        if(isComplete()) {
            props.onChange(getText());
            setCounter(1);
        } 
        if(counter === 1) {
            props.onChange(getText());
        }
    }, [state]);
      
    function getText() {
        let data: Array<string> = [];
        [1,2,3,4].forEach(item => data.push(state['input'+item].toString()));
        return data.join('');
    }

    function isComplete() {
        return getText().length === 4;
    }

    function onInputChange(inputIndex: number, value: string) {
        setState({ ...state, ['input' + inputIndex]: value });
        if(value) {
            if(inputIndex < 4) inputs['input' + (inputIndex+1)].focus();
            else inputs['input' + (inputIndex)].blur();
        }
        
    }

    return(
        <div>
            {[1,2,3,4].map( x => {
                return (
                    <Input
                        className="input-pin"
                        key={x}
                        maxLength={1}
                        size="large"
                        value={state['input'+x]}
                        ref={(input) => { inputs['input'+x] = input }}
                        onChange={(value) => onInputChange(x, value.target.value)}
                    />
                );
            })}
        </div>  
    );
      
}
