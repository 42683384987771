import { ClientInterface, SocketEventGameStatusInterface, SocketEventInterface, SocketEventRenameInterface, SocketEventStateInterface } from "../interfaces";
import { store, SystemConstants } from "../redux";

let connection: WebSocket;

function connect() {
    console.log('[WS] Connect');
    if (connection) return;

    let params = '';
    let data = getDataFromLc();
    if (data) params = `?id=${data.id}&name=${data.name}&type=${data.type}`;
    let url = (process.env.REACT_APP_WS_URL || '') + params;

    connection = new WebSocket(url);
    connection.onmessage = message => onSocketMessage(JSON.parse(message.data));
    connection.onclose = message => onSocketMessage({ type: 'close', payload: message } as any);
}

function disconnect() {
    console.log('[WS] Disconeect');
    connection && connection.close();
    connection = null as any;
}

function onSocketMessage(response: any): any {
    console.log("=== RECIEVED:");
    console.log(JSON.parse(JSON.stringify(response)));

    if (localStorage.getItem('save')) {
        console.log('=== SAVING SNASPHOT');
        localStorage.setItem('WS_RESPONSE_SNAPSHOT', JSON.stringify(response));
        localStorage.removeItem('save');
    }

    let s = localStorage.getItem('WS_RESPONSE_SNAPSHOT');
    if (s) {
        console.log('=== getting from SNASPHOT');
        response = JSON.parse(s);
    }

    switch (response.type) {
        case 'connected': return requestConnected(response);
        case 'close': return onSocketClose(response);
        case 'rename': return onSocketMessageRename(response);
        case 'ping': return onSocketMessagePing();
        case 'gameStatus': return onSocketGameStatus(response);
        case 'refresh': return requestConnected(response);
        default: console.log(`Unsuported type: ${response.type}`);
    }
}

function requestConnected(event: SocketEventStateInterface) {

    store.dispatch({
        type: SystemConstants.SYSTEM_GAMES_SET_ME,
        payload: event.data.client
    });

    store.dispatch({
        type: SystemConstants.SYSTEM_GAMES_SET_CLIENTS,
        payload: event.data.clients
    });

    store.dispatch({
        type: SystemConstants.SYSTEM_GAMES_SET_CURRENT_GAME,
        payload: event.data.currentGameStatus
    });
}

function onSocketClose(event: SocketEventInterface): any {
    connection = undefined as any;
    console.log('[WS] Closed ', event);
}

function onSocketGameStatus(event: SocketEventGameStatusInterface): any {
    store.dispatch({
        type: SystemConstants.SYSTEM_GAMES_SET_CURRENT_GAME,
        payload: event.data.currentGameStatus
    });
}

function onSocketMessageRename(event: SocketEventRenameInterface): any {
    store.dispatch({
        type: SystemConstants.SYSTEM_GAMES_SET_ME,
        payload: event.data.client
    });
    setDataToLc(event.data.client);
}

function onSocketMessagePing(): any {
    send({ type: 'pong' });
}

// TODO - move send to redux action?
function send(payload: any): void {
    console.log("=== SENT:");
    console.log(payload);
    if (!connection) {
        console.log('[ERROR] No WS connection. Send aborted!');
        return;
    }
    try {
        connection.send(JSON.stringify(payload));
    } catch (error) {
        console.log('[ERROR] WS connection problem. Send aborted!');
    }
}

function ws(): WebSocket {
    return connection;
}

// === LOCAL STORAGE ==== // TODO usePersistor?

function getDataFromLc(): ClientInterface | null {
    const urlParams = new URLSearchParams(window.location.search);
    const screen = urlParams.get('screen') || '';
    const data = localStorage.getItem('_LAST_STATE_' + screen);
    if (data) return JSON.parse(data);
    return null;
}

function setDataToLc(data: ClientInterface): void {
    const urlParams = new URLSearchParams(window.location.search);
    const screen = urlParams.get('screen') || '';
    localStorage.setItem('_LAST_STATE_' + screen, JSON.stringify(data));
}

export default {
    connect,
    disconnect,
    ws,
    send
};
