import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import React from "react";
import { NavigationService } from "../../services";
import LogoComponent from "../logo/logo.component";

export default function SiderComponent(props: {links: Array<{title: string, href: string}>}) {
    const {
        links
    } = props;

    const listItems = links.map((link:any, index: number) =>
        <Menu.Item key={index} onClick={() => NavigationService.navigate(link.href)}>
            {link.title}
        </Menu.Item>
    );

    return (
        <Sider className="sider" width={300}>
            <LogoComponent href="/reservations" logoSrc={`${process.env.REACT_APP_WP_URL}/wp-content/themes/b4b/public/logo.svg`} selectorClass="adminLogo" logoTitle="IronBull" logoAlt="Ironbull" />
            <Menu mode="inline" className="mt50">
                {listItems}
            </Menu>
        </Sider>
    );
}