import { Button, Checkbox, Form, Input, Select, Space } from "antd";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { RegisterInterface } from "../../../interfaces";
import { registerAction } from "../../../redux";
import { i18n, NavigationService } from "../../../services";
import { countries } from "../../../../assets/json/countries.json";
import { DefaultDateFormat } from "../../../constants";
import { AuthRoutes } from "../../../../features/auth/_router/auth.routes";
import { InputPinComponent } from "../inputPin/inputPin.component";

export function RegisterComponent() {
    const [date, setDate] = useState<{
        day?: number;
        month?: number;
        year?: number;
        validateStatus?: ValidateStatus;
        errorMsg?: string | null;
      }>({});
      
    const dispatch = useDispatch();

    const { Option } = Select;

    const onFinish = (values: any) => {
        
        let registerData: RegisterInterface = {
            nickname: values.nickname,
            name: values.fullname,
            email: values.mail,
            countryCode: values.country,
            birthday: moment([parseInt(values.dateOfBirth.year), parseInt(values.dateOfBirth.month) - 1, parseInt(values.dateOfBirth.day)]).format(DefaultDateFormat),
            password: values.password,
            newsletter: false // TODO - give user a choice to selact this options
        }

        dispatch(registerAction(registerData));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const yearsOptions = new Array(100).fill('').map((year, index) => {
        let current: number = moment().year() - index;
        return <Option value={current} key={current}>{current}</Option>
    });

    const monthsOptions = new Array(12).fill('').map((month, index) => {
        if(date.year === moment().year() && moment().month() < index) {
            return "";
        }
        let current: number = index + 1;
        return <Option value={current} key={current}>{current}</Option>
    });

    const daysOptions = new Array(31).fill('').map((day, index) => {
        if(date.year === moment().year() && date.month === moment().month() + 1 && moment().date() - 1 < index) {
            return "";
        }
        let current: number = index + 1;
        return <Option value={current} key={current}>{current}</Option>
    });

    const countriesOptions = countries.map((country) => {
        return <Option value={country.code} key={country.code}>{country.name}</Option>
    });

    type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus'];

    const validateDate = (day: number, month: number, year: number): { validateStatus: ValidateStatus; errorMsg: string | null } => {
        if (year === moment().year() && (month > moment().month() + 1 || (month === moment().month() + 1 && day > moment().date()))) 
            return {
                validateStatus: 'error',
                errorMsg: i18n.translate('validation.futureDate'),
            }
        else if (moment([year, month - 1, day]).isValid()) 
            return {
                validateStatus: 'success',
                errorMsg: null,
            };
        else 
            return {
                validateStatus: 'error',
                errorMsg: i18n.translate('validation.date'),
            };
    }

    const checkInputPin = (_: any, value: string) => {
        if (value === "init") {
            return Promise.reject(new Error(i18n.translate("validation.required.password")));
        }   
        if (value.length !== 4 || isNaN(+value)) {
            return Promise.reject(new Error(i18n.translate("validation.pin")));
        }
        return Promise.resolve();
    };

    const onDateChange = (value: number, name: string) => {
        let currentDate = { ...date, [name]: value};
        if (currentDate.day && currentDate.month && currentDate.year)
            setDate({ ...currentDate, ...validateDate(currentDate.day, currentDate.month, currentDate.year) }); 
        else 
            setDate(date => ({ ...currentDate })); 
      };

    return (
        <div className="register__box">
            <Title level={2} className="heading__h1 heading--no-margin">{i18n.translate('register.title').toUpperCase()}</Title>
            <div className="text mb25">{i18n.translate('register.accountQuestion')} <Button type="link" onClick={() => NavigationService.navigate(AuthRoutes.LOGIN.fullPath)}>{i18n.translate('register.goToLogin')}</Button></div>
            <Form
                name="registerForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                initialValues={{
                    country: 'HR'
                  }}
                >
                <Form.Item
                    label={i18n.translate("user.nickname")}
                    name="nickname"
                    rules={[{ required: true, message: i18n.translate("validation.required.nickname") }]}
                >
                    <Input size="large" style={{ width: 300 }} />
                </Form.Item>
                <Form.Item
                    label={i18n.translate("user.fullname")}
                    name="fullname"
                    rules={[{ required: true, message: i18n.translate("validation.required.fullname") }]}
                >
                    <Input size="large" style={{ width: 300 }}/>
                </Form.Item>
                <Form.Item
                    label={i18n.translate("user.mail")}
                    name="mail"
                    rules={[{ required: true, message: i18n.translate("validation.required.mail") }]}
                >
                    <Input size="large" />
                </Form.Item>
                <Form.Item 
                    label={i18n.translate("user.dateOfBirth")} 
                    validateStatus={date.validateStatus}
                    help={date.errorMsg}
                >
                    <Input.Group size="large">
                        <Space size="middle">
                            <Form.Item
                                name={['dateOfBirth', 'day']}
                                noStyle
                                rules={[{ required: true, message: 'Day is required' }]}
                            >
                                <Select placeholder={i18n.translate("date.day").toLowerCase()} size="large" style={{ width: 100 }} onChange={(x: number) => onDateChange(x, 'day')} >
                                    {daysOptions}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={['dateOfBirth', 'month']}
                                noStyle
                                rules={[{ required: true, message: 'Month is required' }]}
                            >
                                <Select placeholder={i18n.translate("date.month").toLowerCase()} size="large" style={{ width: 100 }} onChange={(x: number) => onDateChange(x, 'month')} >
                                    {monthsOptions}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={['dateOfBirth', 'year']}
                                noStyle
                                rules={[{ required: true, message: 'Year is required' }]}
                            >
                                <Select placeholder={i18n.translate("date.year").toLowerCase()} size="large" style={{ width: 100 }} onChange={(x: number) => onDateChange(x, 'year')} >
                                    {yearsOptions}
                                </Select>
                            </Form.Item>
                        </Space>
                    </Input.Group>
                </Form.Item>
                <Form.Item
                    label={i18n.translate("user.country")}
                    name="country"
                    rules={[{ required: true, message: i18n.translate("validation.required.country") }]}
                >
                    <Select placeholder={i18n.translate("select.country").toLowerCase()} size="large" style={{ width: 300 }} >
                        {countriesOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={i18n.translate("user.password")}
                    name="password"
                    rules={[{ validator: checkInputPin }]}
                    initialValue="init"
                >
                    {/* @ts-ignore */}
                    <InputPinComponent />
                </Form.Item>
                    <Form.Item
                        valuePropName="checked"
                        name="gdpr"
                        rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error(i18n.translate("validation.required.gdpr"))) }]}
                    >
                        <Checkbox>{i18n.translate("user.gdprCheckbox")}</Checkbox>
                    </Form.Item>
                    <Form.Item
                        valuePropName="checked"
                        name="newsletter"
                    >
                        <Checkbox>{i18n.translate("user.newsletterCheckbox")}</Checkbox>
                    </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" block>
                        {i18n.translate("register.button").toUpperCase()}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
