import { Button, Table, Input } from "antd";
import React, { useEffect } from "react";
import { RightOutlined } from '@ant-design/icons';

import './usersPage.style.scss';
import { useDispatch, useSelector } from "react-redux";
import { UserInterface, i18n, NavigationService, StoreStateInterface, getUsersAction, ReservationInterface } from "../../../../common";
import { AdminRoutes } from "../../_router/admin.routes";

function UsersPageComponent() {

  const dispatch = useDispatch();
  const users = useSelector((state: StoreStateInterface) => state.user.users);

  useEffect(() => {
    dispatch(getUsersAction());
  }, []);

  const getTableColumns: any = [
    {
      key: 0,
      title: i18n.translate("user.nickname"),
      render: (user: UserInterface) => <Button type="link" onClick={() => NavigationService.navigate(`${AdminRoutes.USERS.fullPath}/${user.id}`)}>{user.nickname}</Button>
    },
    {
      key: 1,
      title: i18n.translate("user.fullname"),
      dataIndex: 'name'
    },    
    {
      key: 2,
      title: i18n.translate("user.rank"),
      render: (user: UserInterface) => <div>{user.rank ? '#' + user.rank : '-'}</div>
    },
    {
      key: 3,
      title: i18n.translate("user.points"),
      render: (user: UserInterface) => <div>{user.points ? user.points : '-'}</div>
    },
    {
      key: 4,
      title: i18n.translate("reservations.activeReservations"),
      render: (user: UserInterface) => user.reservations && user.reservations.length ? user.reservations.map((reservation: ReservationInterface) => <span key={reservation.number}>{reservation.number} </span>) : '-'
    },
    {
      key: 5,
      title: '',
      render: (user: UserInterface) => <Button type="link" onClick={() => NavigationService.navigate(`${AdminRoutes.USERS.fullPath}/${user.id}`)}><RightOutlined /></Button>
    }
  ];

  function searchUsers(value: string): void {
    dispatch(getUsersAction(value));
  }
  

  const renderSearchField = (): React.ReactNode => {
    return (
      <div className="mb8">
        <Input.Search placeholder={i18n.translate("user.search")} allowClear onSearch={(value) => searchUsers(value)} style={{ width: 200 }} />        
      </div>
    )
  }

  const renderGamesTable = (): React.ReactNode => {
    return <Table columns={getTableColumns} dataSource={users} rowKey={row => row.id} scroll={{ x: 240 }} />
  }  

  return (
    <React.Fragment>
      <div className="usersPage">
        {renderSearchField()}
        {renderGamesTable()}
      </div>
    </React.Fragment>
  );

}

export default UsersPageComponent;
