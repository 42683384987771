import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { AuthReducer } from "./auth";
import { GameReducer } from "./game";
import { ReservationReducer } from "./reservation";
import { UserReducer } from "./user";
import { SystemReducer } from "./system";
import { EventReducer } from "./event";
import { TankReducer } from "./tank";
import { TvReducer } from "./tv";

// ovdje se listaju svi reduceri: npr. auth: AuthReducer
const AllReducers = combineReducers({
  auth: AuthReducer,
  game: GameReducer,
  reservation: ReservationReducer,
  user: UserReducer,
  system: SystemReducer,
  event: EventReducer,
  tank: TankReducer,
  tv: TvReducer,
});

export const AppReducers = (state: any, action: any): StoreStateInterface => {
  return AllReducers(state, action);
}

// za persist u local storage: whitelist: ['auth']
const persistConfig = {
  key: 'ironbull',
  storage,
  whitelist: ['auth', 'reservation']
}

const persistedReducer = persistReducer(persistConfig, AppReducers)

const initialState = {};

const middleware = [thunk];

export const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
  //compose(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);

export type StoreStateInterface = ReturnType<typeof AllReducers>;