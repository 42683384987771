import React, { ReducerWithoutAction, useEffect, useState } from "react";
import moment from "moment";

import { GameStatusStateEnum, i18n, StoreStateInterface, TankResultInterface, TeamIdEnum } from "../../../common";
import { useSelector } from "react-redux";
import { reduceEachLeadingCommentRange } from "typescript";

function FieldPreparationPageComponent() {

  const [leftTable, setLeftTable] = useState<GameDataInterface>({
    title: i18n.translate("team.axis"),
    position: 'left',
    list: [],
    color: 'red',
  });
  const [rightTable, setRightTable] = useState<GameDataInterface>({
    title: i18n.translate("team.allies"),
    position: 'right',
    list: [],
    color: 'blue',
  });
  const [displayTimer, setDisplayTimer] = useState<string>('--:--');
  const [counter, setCounter] = useState(0);
  const currentSystemState = useSelector((state: StoreStateInterface) => state.system.currentSystemState);

  useEffect(() => {
    let leftTableNew: GameDataInterface = {
      ...leftTable,
      list: []
    };

    let rightTableNew: GameDataInterface = {
      ...rightTable,
      list: []
    };

    if (currentSystemState && currentSystemState.currentGame && currentSystemState.currentGame.tanks) {
      Object.values(currentSystemState.currentGame.tanks).forEach((tank: TankResultInterface) => {
        let list = tank.teamId === TeamIdEnum.AXIS ? leftTableNew.list : rightTableNew.list;
        let tableRow = { position: list.length + 1, tankName: tank.name, playerOne: tank.playerOne, playerTwo: tank.playerTwo };
        list.push(tableRow);
      });
    }

    setLeftTable(leftTableNew);
    setRightTable(rightTableNew);

  }, [currentSystemState]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      let delta = 0;

      if (currentSystemState && currentSystemState.gameStatus) {
        let addSeconds = 0;
        if (currentSystemState.gameStatus.next.state === GameStatusStateEnum.PrepareTanksState) addSeconds = -30; // TODO read from settings
        let nowTimestamp = moment().add(addSeconds, 'seconds').valueOf();
        let newtStateTime = currentSystemState.gameStatus.next.ts * 1000;
        delta = (newtStateTime - nowTimestamp) / 1000;
        if (delta <= 0) delta = 0;
      }

      setDisplayTimer(moment().startOf('day').add(delta, 'seconds').format('mm:ss'));

      if (delta > 0) setCounter(counter + 1);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [counter, currentSystemState]);

  const renderBanner = (): React.ReactFragment => {
    return (
      <div className="banner">
        <div className="banner__left">
          <div className="divider-short"></div>
          <div className="title">SLJEDEĆA IGRA:</div>
        </div>

        <div className="banner__right">
          <div className="left">POČETAK IGRE ZA:</div>
          <div className="right">{displayTimer} min</div>
        </div>
      </div>
    );
  }

  const renderTable = (data: GameDataInterface): React.ReactFragment => {
    return (
      <div className={'statistic-box statistic-box--' + data.position}>
        <div className={`statistic-table__title ${data.color}`}>
          <img src={'./static/images/' + (data.position === 'left' ? 'logo_axis_red.svg' : 'logo_allies_blue.svg')} alt="" />
          {data.title}
        </div>
        <div className="statistic-box__table">
          <div className="statistic-table">
            <div className="statistic-table__row statistic-table__row--header">
              <div className="statistic-table__cell statistic-table__cell--50"></div>
              <div className="statistic-table__cell"><img src={'./static/images/logo_tank.svg'} alt="" /></div>
              <div className="statistic-table__cell">Igrač 1</div>
              <div className="statistic-table__cell">Igrač 2</div>
            </div>
            {data.list.map(li => (
              <div key={li.position} className="statistic-table__row">
                <div className="statistic-table__cell statistic-table__cell--50">#{li.position}</div>
                <div className="statistic-table__cell">{li.tankName}</div>
                <div className="statistic-table__cell">{li.playerOne}</div>
                <div className="statistic-table__cell">{li.playerTwo}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="field-preparation-page">
      {renderBanner()}
      <div className="spliter">
        {renderTable(leftTable)}
        {renderTable(rightTable)}
      </div>
    </div>
  );
}

export default FieldPreparationPageComponent;

// === PRIVATE INTERFACE ===

interface GameDataInterface {
  title: string;
  position: 'left' | 'right';
  color: 'red' | 'blue';
  list: Array<{
    position: number;
    tankName: string;
    playerOne: string;
    playerTwo: string;
  }>
}
