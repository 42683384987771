import { ColorConstant } from "./color.constant";

export const GameStatusConstant = () => { return {
    NOT_ACTIVE: { id: 0, name: "not active", label: "inactive", labelColor: ColorConstant.RED.hash, text: "Termin je onemogućen"},
    ACTIVE: { id: 1, name: "active", label: "active", labelColor: 'transparent', text: ""},
    RESERVED_BY_ADMIN: { id: 2, name: "reserved_by_admin", label: "reserved by admin", labelColor: ColorConstant.RED.hash, text: "Rezerviran"},
    IN_PROGRESS: { id: 3, name: "in_progress", label: "in progress", text: "U tijeku"},
    FINISHED: { id: 4, name: "finished", label: "finished", text: "Odigran termin"},
}};

export function getGameStatusById(id: number) {
    let element = Object.entries(GameStatusConstant()).find((e: any) => { return e[1].id === id });
    return element;
}

export function getGameStatusLabelById(id: number) {
    let element = getGameStatusById(id);
    return element ? element[1].label : null;
}

export function getGameStatusTextById(id: number) {
    let element = getGameStatusById(id);
    return element ? element[1].text : null;
}