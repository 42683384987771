import React from "react";
import classnames from 'classnames';

export default function HeadingComponent(props:any) {
    const {
        title,
        headingLevel = 'h1',
        color = 'white',
        size  = 'default',
        selectorClass = ''
    } = props;
    const validHeadingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
    const safeHeading = headingLevel ? headingLevel.toLowerCase() : '';
    const Title = validHeadingLevels.includes(safeHeading) ? safeHeading : 'p';
    const headingClass = classnames([
		'heading',
        color ? 'heading__color--' + color : '',
        size ? 'heading__size--' + size : '',
        selectorClass ? selectorClass  + '__heading' : '',
	]);
    return (
        <Title className={headingClass}>
           {title}
        </Title>
    );
}