import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { GameStatusStateEnum, TankResultInterface } from "../../../common/interfaces/tv";
import { i18n, StoreStateInterface, TeamIdEnum } from "../../../common";

function BetweenRoundsPageComponent() {

  const [displayTimer, setDisplayTimer] = useState<string>('--:--');
  const [counter, setCounter] = useState(0);
  const currentSystemState = useSelector((state: StoreStateInterface) => state.system.currentSystemState);

  useEffect(() => {
    const timeout = setTimeout(() => {
      let delta = 0;

      if (currentSystemState && currentSystemState.gameStatus) {
        let addSeconds = 0;
        if (currentSystemState.gameStatus.next.state === GameStatusStateEnum.PrepareTanksState) addSeconds = -30; // TODO read from settings
        let nowTimestamp = moment().add(addSeconds, 'seconds').valueOf();
        let newtStateTime = currentSystemState.gameStatus.next.ts * 1000;
        delta = (newtStateTime - nowTimestamp) / 1000;
        if (delta <= 0) delta = 0;
      }

      setDisplayTimer(moment().startOf('day').add(delta, 'seconds').format('mm:ss'));

      if (delta > 0) setCounter(counter + 1);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [counter, currentSystemState]);

  // === TIMELINE ===
  const renderHeader = (): React.ReactNode => {
    if (!currentSystemState || !currentSystemState.rounds) return null;

    let currentRoundNumber = currentSystemState!.currentGame!.currentRound.number;
    let timeline: Array<{ roundNumber: number, result?: string; timer?: string; winnerLogo?: string}> = [];

    currentSystemState.rounds.forEach((round, index) => {
      let timelineItem: any = {
        roundNumber: index + 1,
        result: undefined,
        timer: undefined,
        winnerLogo: undefined,
      };

      switch (round.status) {
        case 'PREPARED':
          timelineItem.timer = '11:00'; // TODO read from settings
          break;
        case 'IN PROGRESS':

          break;
        case 'ENDED':
          let gameResults = currentSystemState!.currentGame!.roundResults[index];
          let result: [number, number] = [0, 0];
          if (gameResults) {
            Object.keys(gameResults.tankResults).forEach((tankKey: any) => {
              let tankResult: TankResultInterface = gameResults.tankResults[tankKey];
              let tank = currentSystemState!.currentGame!.tanks[tankKey];
              if (tank.teamId === TeamIdEnum.AXIS) {
                result[1] = result[1] + tankResult.deaths;
              } else {
                result[0] = result[0] + tankResult.deaths;
              }
            });
          }
          timelineItem.result = `${result[0]}:${result[1]}`;
          if (round.victoryTeamId) timelineItem.winnerLogo = round.victoryTeamId === TeamIdEnum.AXIS ? <img src={'./static/images/logo_axis_red.svg'} alt="" /> : <img src={'./static/images/logo_allies_blue.svg'} alt="" />;

          break;
      }

      timeline.push(timelineItem);
    });

    return (
      <div className="banner">
        {timeline.map((tl, i) => (
          <div key={i} className={`box title-subtitle ${tl.timer && (currentRoundNumber !== tl.roundNumber) ? 'blur' : ''}`}>

            {currentRoundNumber === tl.roundNumber && (
              <React.Fragment>
                <div className="s40 g">SLJEDEĆA IGRA ZA</div>
                <div className="s60 o">{displayTimer} min</div>
              </React.Fragment>
            )}

            {currentRoundNumber !== tl.roundNumber && (
              <React.Fragment>
                <div className="s24 g">Runda {tl.roundNumber}</div>
                {tl.result && (<div className="s40 o">{tl.result}{tl.winnerLogo && (<span className="additional">{tl.winnerLogo}</span>)}</div>)}
                {tl.timer && (<div className="s40 o">{tl.timer} <span className="additional">min</span></div>)}
              </React.Fragment>
            )}

          </div>
        ))}
      </div>
    );
  }

  const renderRoundResult = (result: [number, number], winTeam: 'left' | 'right'): React.ReactNode => {
    return (
      <div className="round-result spliter">
        <div className={`win ${winTeam}`}>POBJEDA</div>

        <div className={`title-box red`}>
          <img src={'./static/images/logo_axis_red.svg'} alt="" /> {i18n.translate("team.axis")}
        </div>

        <div>
          <div className="title-subtitle">
            <div className="s24 o">RUNDA {currentSystemState!.currentGame!.currentRound.number - 1}</div>
            <div className="s80 o">{`${result[0]}:${result[1]}`}</div>
          </div>
        </div>

        <div className={`title-box blue`}>
          <img src={'./static/images/logo_allies_blue.svg'} alt="" /> {i18n.translate("team.allies")}
        </div>
      </div>
    );
  }

  const renderTable = (type: TeamIdEnum, data: Array<TankResultInterface>): React.ReactNode => {
    return (
      <div className={`statistic-table ${type === TeamIdEnum.AXIS ? 'left' : 'right'}`}>
        <div className="statistic-table__row statistic-table__row--header">
          <div className="statistic-table__cell statistic-table__cell--50"></div>
          <div className="statistic-table__cell"><img src={'./static/images/logo_tank.svg'} alt="" /></div>
          <div className="statistic-table__cell"><img src={'./static/images/logo_user.svg'} alt="" /></div>
          <div className="statistic-table__cell statistic-table__cell--80">BODOVI</div>
          <div className="statistic-table__cell statistic-table__cell--80">KILOVI</div>
        </div>
        {data.map((li, i) => (
          <div key={i} className="statistic-table__row">
            <div className="statistic-table__cell statistic-table__cell--50 orange">#{i + 1}</div>
            <div className="statistic-table__cell">{li.name}</div>
            <div className="statistic-table__cell">
              <div>{li.playerOne}</div>
              <div>{li.playerTwo}</div>
            </div>
            <div className="statistic-table__cell statistic-table__cell--80">{li.points}</div>
            <div className="statistic-table__cell statistic-table__cell--80">{li.kills}</div>
          </div>
        ))}
      </div>
    );
  }

  if (!currentSystemState || !currentSystemState.currentGame) return null;

  let result: [number, number] = [0, 0];
  let winTeam: 'left' | 'right' = 'left';
  let axisTableData: Array<TankResultInterface> = [];
  let alliesTableData: Array<TankResultInterface> = [];

  let resultForRound = currentSystemState.currentGame.currentRound.number - 1;
  let roundResults = currentSystemState.currentGame.roundResults.find(x => x.round === resultForRound);
  if (roundResults) {
    Object.values(roundResults.tankResults).forEach((tank: TankResultInterface) => {
      if (tank.teamId === TeamIdEnum.AXIS) {
        axisTableData.push(tank);
        result[1] = result[1] + tank.deaths;
      } else {
        alliesTableData.push(tank);
        result[0] = result[0] + tank.deaths;
      }
    });

    if (result[0] > result[1] || (result[0] === result[1] && roundResults.teamResults[TeamIdEnum.AXIS].role === 'DEFENDER')) {
      //axis
      winTeam = 'left';
    } else {
      //allies
      winTeam = 'right';
    }
  }

  return (
    <div className="between-rounds-page">
      {renderHeader()}
      {renderRoundResult(result, winTeam)}
      <div className="spliter">
        {renderTable(TeamIdEnum.AXIS, axisTableData)}
        {renderTable(TeamIdEnum.ALLIES, alliesTableData)}
      </div>
    </div>
  );
}

export default BetweenRoundsPageComponent;
