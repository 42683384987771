import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import './profileStatisticsPage.style.scss';
import { UserStatsInterface, i18n, StoreStateInterface } from "../../../common";
import { getUserStatsAction } from "../../../common/redux";

function UserProfileOneRow(props: { title: string; titleClass: string, userStats?: UserStatsInterface, attackerStats?: UserStatsInterface, defenderStats?: UserStatsInterface }) {

  let { title, titleClass, userStats, attackerStats, defenderStats } = props;  

  return (
    <React.Fragment>
      <div className={titleClass}>
        {title}
      </div>

      <div className="row box mt8">
        <div className="row__item mini-box">
          <div className="mini-box__title mb4">{i18n.translate('profileStatistics.rank')}</div>
          <div className="mini-box__subtitle">{userStats?.rank}{attackerStats?.rankAttacker}{defenderStats?.rankDefender}</div>
        </div>

        <div className="row__item mini-box">
          <div className="mini-box__title mb4">{i18n.translate('profileStatistics.points')}</div>
          <div className="mini-box__subtitle">{userStats?.points}{attackerStats?.attackerPoints}{defenderStats?.defenderPoints}</div>
        </div>

        <div className="row__item mini-box">
          <div className="mini-box__title mb4">{i18n.translate('profileStatistics.kdRatio')}</div>
          <div className="mini-box__subtitle">{userStats?.kdRatio}{attackerStats?.attackerKDRatio}{defenderStats?.defenderKDRatio}</div>
        </div>

        {userStats &&
          <div className="row__item mini-box">
            <div className="mini-box__title mb4">{i18n.translate('profileStatistics.games')}</div>
            <div className="mini-box__subtitle">{userStats?.playedGames}</div>
          </div>
        }

        <div className="row__item mini-box">
          <div className="mini-box__title mb4">{i18n.translate('profileStatistics.kills')}</div>
          <div className="mini-box__subtitle">{userStats?.kills}{attackerStats?.attackerKills}{defenderStats?.defenderKills}</div>
        </div>

        <div className="row__item mini-box">
          <div className="mini-box__title mb4">{i18n.translate('profileStatistics.deaths')}</div>
          <div className="mini-box__subtitle">{userStats?.deaths}{attackerStats?.attackerDeaths}{defenderStats?.defenderDeaths}</div>
        </div>
      </div>

      <div className="row row__wide mt8 ">
        {/* === LEFT === */}
        {userStats &&
          <div className="row__item box mt8">
            <div className="row__item mini-box">
              <div className="mini-box__title mb4">{i18n.translate('profileStatistics.terms')}</div>
              <div className="mini-box__subtitle">{userStats?.gamesWithVictory}</div>
              <div className="mini-box__footer">{i18n.translate('profileStatistics.victories')}</div>
            </div>

            <div className="row__item mini-box">
              <div className="mini-box__title mb4"></div>
              <div className="mini-box__subtitle">{userStats?.gamesWithLoss}</div>
              <div className="mini-box__footer">{i18n.translate('profileStatistics.defeats')}</div>
            </div>

            <div className="row__item mini-box mini-box--border-left">
              <div className="mini-box__title mb4">{i18n.translate('profileStatistics.round')}</div>
              <div className="mini-box__subtitle">{userStats?.roundsWithVictory}</div>
              <div className="mini-box__footer">{i18n.translate('profileStatistics.victories')}</div>
            </div>

            <div className="row__item mini-box">
              <div className="mini-box__title mb4"></div>
              <div className="mini-box__subtitle">{userStats?.roundsWithLoss}</div>
              <div className="mini-box__footer">{i18n.translate('profileStatistics.defeats')}</div>
            </div>
          </div>
        }

        {/* === RIGHT === */}
        <div className="row__item box mt8">
          <div className="row__item mini-box">
            <div className="mini-box__title mb4">{i18n.translate('profileStatistics.damageDone')}</div>
            <div>
              <span className="mini-box__subtitle2">{userStats?.damageDonePerRound}{attackerStats?.attackDamageDonePerRound}{defenderStats?.defenderDamageDonePerRound} hp</span>&nbsp;&nbsp;
              <span className="mini-box__title">{i18n.translate('profileStatistics.perRound')}</span>
            </div>
            <div>
              <span className="mini-box__subtitle2">{userStats?.totalDamageDone}{attackerStats?.attackerDamageDone}{defenderStats?.defenderDamageDone} hp</span>&nbsp;&nbsp;
              <span className="mini-box__title">{i18n.translate('profileStatistics.total')}</span>
            </div>
          </div>

          <div className="row__item mini-box mini-box--border-left">
            <div className="mini-box__title mb4">{i18n.translate('profileStatistics.damageReceived')}</div>
            <div>
              <span className="mini-box__subtitle2">{userStats?.damageReceivedPerRound}{attackerStats?.attackDamageReceivedPerRound}{defenderStats?.defenderDamageReceivedPerRound} hp</span>&nbsp;&nbsp;
              <span className="mini-box__title">{i18n.translate('profileStatistics.perRound')}</span>
            </div>
            <div>
              <span className="mini-box__subtitle2">{userStats?.totalDamageReceived}{attackerStats?.attackerDamageReceived}{defenderStats?.defenderDamageReceived} hp</span>&nbsp;&nbsp;
              <span className="mini-box__title">{i18n.translate('profileStatistics.total')}</span>
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  );
}

export default function ProfileStatisticsPage() {

  const userId = useSelector((state: StoreStateInterface) => state.auth.user?.id);

  const userStats = useSelector((state: StoreStateInterface) => state.user.userStats);

  const dispatch = useDispatch();

  useEffect(() => {
    if(userId) dispatch(getUserStatsAction(userId));
    console.log(userStats);
  }, [userId]);
  
  return (
    <div className="profile-statistics-page">
      <UserProfileOneRow title={i18n.translate('profileStatistics.myStatistics').toUpperCase()} titleClass="title" userStats={userStats} />
      <UserProfileOneRow title={i18n.translate('profileStatistics.attacker')} titleClass="subtitle" attackerStats={userStats}/>
      <UserProfileOneRow title={i18n.translate('profileStatistics.deffender')} titleClass="subtitle" defenderStats={userStats} />
    </div>
  );
}
