import React from "react";
import classnames from 'classnames';


export default function LogoComponent(props:any) {
    const {
        href,
        componentClass = "logo",
        selectorClass = "",
        logoSrc,
        logoTitle,
        logoAlt,
    } = props;

    const logoClass = classnames([
		componentClass,
        selectorClass ? selectorClass + '__' + componentClass : ''
	]);

    const logoImgClass = classnames([
		componentClass + '__img',
        selectorClass ? selectorClass + '__img' + componentClass : ''
	]);

    return (
        <a
            className={logoClass}
            href={href}
        >
        <img
            src={logoSrc}
            alt={logoAlt}
            title={logoTitle}
            className={logoImgClass}
        />
    </a>
    );
}