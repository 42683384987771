import { Input } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { getReservationByNumberAction } from "../../../redux";
import { i18n } from "../../../services";

export function SecondaryHeaderComponent() {

  const dispatch = useDispatch();

  function searchReservations(value: string) {
    dispatch(getReservationByNumberAction(parseInt(value)));
  }

  return (
    <header className="secondary-header">
      <div className="flex-row w100-h100">
        <Input.Search placeholder={i18n.translate("reservations.search")} allowClear onSearch={(value) => searchReservations(value)} style={{ width: 300 }} /> 
      </div>
    </header>
    
   
  );
}