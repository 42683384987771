import { Button, Form, Input } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import { useDispatch } from "react-redux";
import { AuthRoutes } from "../../../../features/auth/_router/auth.routes";
import { LoginInterface } from "../../../interfaces";
import { loginAction } from "../../../redux";
import { i18n, NavigationService } from "../../../services";

export function AdminLoginComponent() {

    const dispatch = useDispatch();

    const onFinish = (values: any) => {
        let loginData: LoginInterface = {
            emailOrNickname: values.username,
            password: values.password,
        }
        dispatch(loginAction(loginData));
    };

    const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    };

    return (
        <div className="admin-login__box">
        <Title level={2} className="heading__h1 heading--no-margin">{i18n.translate('login.title').toUpperCase()}</Title>
        <div className="text mb25">{i18n.translate('login.accountQuestion')} <Button type="link" onClick={() => NavigationService.navigate(AuthRoutes.REGISTER.fullPath)}>{i18n.translate('login.goToRegister')}</Button></div>
        <Form
                name="loginForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                >
                <Form.Item
                    label={i18n.translate("user.nicknameOrMail")}
                    name="username"
                    rules={[{ required: true, message: i18n.translate("validation.required.nicknameOrMail")}]}
                >
                    <Input size="large" />
                </Form.Item>
                <Form.Item
                    label={i18n.translate("user.password")}
                    name="password"
                    rules={[{ required: true, message: i18n.translate("validation.required.password") }]}
                >
                    <Input.Password size="large" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" size="large" block>
                        {i18n.translate("login.button").toUpperCase()}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}