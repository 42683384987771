import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { persistor, store } from "./common";
import MainRouter from "./features/_router/main.router";

export function Main(){
    return(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <MainRouter></MainRouter>
            </PersistGate>
        </Provider>
    );
} 