import Typography from "antd/lib/typography";
import React from "react";
import { i18n } from "../../services";
import TankImg from "../../../assets/images/M26-Pershing.png";

export function ReservationsIntroComponent() {
    const { Title, Text } = Typography;

  return (
    <section className="reservationsIntro">
        <div>
          <Title level={window.innerWidth > 480 ? 1 : 2} className="heading__h1">{i18n.translate("reservations.title")}</Title> 
          <Text>{i18n.translate("reservations.intro")}</Text>
        </div>
        <img src={TankImg} alt="tank" />
    </section>
  );
}
