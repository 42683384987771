import { TeamIdEnum, TeamInterface } from "../team/team.interface";

export enum GameStatusStateEnum {
    InitialState = 'InitialState',
    StartedState = 'StartedState',
    PreparedGameState = 'PreparedGameState',

    PreparingTanksForRoundState = 'PreparingTanksForRoundState', //wait 8 minutes and 30 seconds
    PrepareTanksState = 'PrepareTanksState', //wait 30 seconds
    StartRoundState = 'StartRoundState',
    RoundInProgressState = 'RoundInProgressState',
    RoundTimeOutState = 'RoundTimeOutState',

    EndGameState = 'EndGameState',

    ErrorState = 'ErrorState'
}

export interface CurrentGameStatusInterface {
    uuid?: string;
    currentGameKey?: string;
    currentGame?: CurrentGameInterface;
    previousGame?: CurrentGameInterface;
    gameStatus?: GameStatusInterface;
    rounds?: Array<CurrentRoundsInterface>;
    liveUpdates?: Array<LiveUpdateInterface>;
}

export interface GameStatusInterface {
    state: GameStatusStateEnum;

    next: {
        ts: number;
        display: string;
        state: GameStatusStateEnum;
        in: string;
    };

    game: {
        id: number;
        from: {
            ts: number;
            display: string;
        };
        to: {
            ts: number;
            display: string;
        };
    };

    exceptionMessage?: string;
}

export interface CurrentRoundsInterface {
    status: 'PREPARED' | 'IN PROGRESS' | 'ENDED';
    startTime: number;
    endTime: number;
    victoryTeamId: number;
}


export interface CurrentGameInterface {
    lastTimer: number;
    currentRound: {
        number: number;
        attackerTeamId: number;
        startTimestamp: number;
    };

    roundResults: Array<{
        round: number;
        tankResults: { [index: number]: TankResultInterface };
        teamResults: { [index: number]: TeamResultInterface };
    }>;

    gameResult: {
        tankResults: { [index: number]: TankGameResultInterface };
        teamResults: { [index: number]: TeamGameResultInterface };
    }

    teams: Array<TeamInterface>;

    tanks: { [index: number]: TankResultInterface }
}
export interface TeamResultInterface {
    role: TeamRole;
    victory: boolean;
}

export interface TeamGameResultInterface {
    victory: boolean;
    roundWins: number;
    points: number;
    deaths: number;
}

export type TeamRole = 'ATTACKER' | 'DEFENDER';

export interface TankResultInterface {
    id: number;

    teamId: TeamIdEnum;
    name: string;
    playerOne: string;
    playerTwo: string;

    points: number;
    kills: number;
    deaths: number;
    shotsFired: number;
    damageDone: number;
    damageReceived: number;

    hp: number;
    ammo: number;

    secondsAlive: number;
    roundPenetrarion: number;
    roundPower: number;
    panels: Array<number>;

    config: {
        tankInertia: number;
        maneuverInertia: number;
        rollingResistance: number;
        turretInertia: number;
        turretFriction: number;
        reloadSpeed: number;
        ammoCount: number;
        cannonDamage: number;
        hp: number;
        roundPenetrarion: number;
        roundPower: number;
        panelStrength: Array<any>;
        panels: Array<string>;
    };
}

export interface TankGameResultInterface {
    id: number;

    teamId: TeamIdEnum;
    name: string;
    playerOne: string;
    playerTwo: string;

    roundPoints: number;
    kills: number;
    deaths: number;
    additionalPoints: number;
    totalPoints: number;
    victory: boolean;
}

export interface LiveUpdateInterface {
    type: 'liveUpdate',
    subtype: 'custom' | 'hit' | 'destroy',
    message?: string;

    source: {
        id: number;
    };

    destination: {
        id: number;
    };
}
