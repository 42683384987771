import { Observable } from "rxjs";
import moment from "moment";

import HttpFactory, { HttpParamsTypes } from "./abstract/abstract.api.service";
import { store } from "../redux";
import { GameResponseInterface, GamesResponseInterface, OccupationInterface, ReservationInterface } from "../interfaces";
import { apiRoutes } from "./routes";

export class GameService {
    static getGamesByDay(day: string): Observable<GamesResponseInterface> {
        let startOfWorkDay = moment(day).startOf('day').add(4, 'h').unix().toString();
        let endOfWorkDay = moment(day).add(1, 'd').startOf('day').add(4, 'h').unix().toString();
        let params: HttpParamsTypes = { 
            query: `from=bt=(${startOfWorkDay},${endOfWorkDay})`
        };

        let isAuthenticated: boolean = store.getState().auth.isAuthenticated;
        
        return HttpFactory.GET(isAuthenticated ? apiRoutes.games : apiRoutes.gamesPublic, params);
    }

    static getGameById(gameId: number): Observable<GameResponseInterface> {    
        return HttpFactory.GET([apiRoutes.games, gameId].join('/'));
    }

    static createOccupation(gameTankId: number, isAdmin: boolean, reservationId?: number): Observable<OccupationInterface> {
        if (isAdmin) {
            return HttpFactory.PUT(apiRoutes.occupation, {gameTankId});
        }
        return HttpFactory.PUT(apiRoutes.occupation, {gameTankId, reservationId});
    }

    static deleteOccupation(occupationId: number): Observable<void> {
        return HttpFactory.DELETE([apiRoutes.occupation, occupationId].join('/'));
    }

    static deactivateOccupation(occupationId: number): Observable<void> {
        return HttpFactory.POST([apiRoutes.occupation, occupationId, 'deactivate'].join('/'), {});
    }

    static setGameTankStatus(gameTankId: number, status: string): Observable<void> {
        return HttpFactory.POST([apiRoutes.gameTank, gameTankId].join('/'), {gameTankStatus: status});
    }

    static finishReservation(reservationId: number): Observable<ReservationInterface> {
        return HttpFactory.POST([apiRoutes.reservation, reservationId, 'finishReservation'].join('/'), {});
    }

    static addUserToOccupation(occupationId: number, email: string, playerNumber: number): Observable<any> {
        return HttpFactory.PUT([apiRoutes.occupationLoc, occupationId, playerNumber === 1 ? 'playerOne' : 'playerTwo'].join('/'), {nickname: email});
    }

    static removeUserFromOccupation(occupationId:number, playerNumber: number): Observable<any> {
        return HttpFactory.DELETE([apiRoutes.occupation, occupationId, playerNumber].join('/'));
    }

    static transferUserOccupation(occupationFromId: number, gameTankToId: number) {
        return HttpFactory.PUT([apiRoutes.occupation, occupationFromId, 'transfer', gameTankToId].join('/'), {});
    }

    static deactivateUserOccupation(occupationId: number) {
        return HttpFactory.POST([apiRoutes.occupation, occupationId, 'deactivate'].join('/'), {});
    }

    static getReservationByNumber(reservationNumber: number): Observable<ReservationInterface> {    
        return HttpFactory.GET([apiRoutes.reservationByWebNumber, reservationNumber].join('/'));
    }

    // TODO
    static setGameStatus(status: number, gameId: number): Observable<any> {    
        return HttpFactory.PATCH([apiRoutes.gamesLocal, gameId].join('/'), {status: status.toString()});
    }

    static markOccupationAsBilled(occupationId: number): Observable<any> {    
        return HttpFactory.POST([apiRoutes.occupationLoc, occupationId, 'markAsBilled'].join('/'), {});
    }
}